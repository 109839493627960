import React from 'react';
import AmebaError from '../../blocks/AmebaError';

/**
 * Not Found Description
 */
const NotFound: React.FC = () => {
  return <AmebaError statusCode={404} />;
};

export default NotFound;

import MineServiceIds from '../../../../../constants/serviceIds';
import { getDokushoLoginRedirectUrl } from '../../../../../lib/UrlUtils';
import { SpriteTypes } from '../../../../atoms/Sprite';

export const SECTION_ID = 'pc-header';

const TAP_ID_BLOG = 'ameblo';
const TAP_ID_SERVICE = 'mini-service';
const TAP_ID_SERVICE_MORE = 'mini-service-more';
const TAP_ID_GAMES_MORE = 'game-more';

export interface MenuItem {
  id: string;
  serviceId: string;
  title: string;
  tapId: string;
  linkUrl?: string;
  target?: string;
  categories?: MenuCategory[][];
}

export interface MenuCategory {
  title?: string;
  apps: MenuApp[];
}

export interface MenuApp {
  serviceId: string;
  linkUrl: string;
  title: string;
  subTitle: string;
  iconClass: string;
  tapId: string;
}

export const MENU_BLOG: MenuItem = {
  id: 'blog',
  serviceId: MineServiceIds.Blog,
  title: 'ブログ管理',
  linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/top.do`,
  tapId: TAP_ID_BLOG + '-management',
};

export const MENU_BLOG_GENRE: MenuItem = {
  id: 'genre',
  serviceId: MineServiceIds.Blog,
  title: 'ブログジャンル',
  linkUrl: process.env.DOMAIN_BLOGGER_URL,
  tapId: TAP_ID_BLOG + '-genre',
};

export const MENU_BLOG_TALENT: MenuItem = {
  id: 'talent',
  serviceId: MineServiceIds.Blog,
  title: '芸能人・有名人',
  linkUrl: process.env.DOMAIN_OFFICIAL_URL,
  tapId: TAP_ID_BLOG + '-official',
};

export const MENU_AMEBA_NEWS: MenuItem = {
  id: 'amebanews',
  serviceId: MineServiceIds.AmebaNews,
  title: 'ニュース',
  linkUrl: process.env.DOMAIN_AMEBA_NEWS_URL,
  tapId: 'amebanews',
};

export const MENU_PIGG: MenuItem = {
  id: 'pigg',
  serviceId: MineServiceIds.Pigg,
  title: 'ピグ',
  tapId: TAP_ID_SERVICE,
  categories: [
    [
      {
        apps: [
          {
            serviceId: MineServiceIds.Pigg,
            linkUrl: 'https://s.pigg.ameba.jp',
            title: 'アメーバピグ',
            subTitle: '自分そっくりキャラで楽しもう',
            iconClass: SpriteTypes.Service_Pigg,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.PiggParty,
            linkUrl: 'https://lp.pigg-party.com/redirect_store',
            title: 'ピグパーティ',
            subTitle: '可愛いアバターをきせかえよう♪',
            iconClass: SpriteTypes.Service_PiggParty,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.PiggLifeApp,
            linkUrl: 'https://rd.amca.jp/m5JB2q2Up88gDddFggmn',
            title: 'アプリ版ピグライフ',
            subTitle: 'スマホで簡単お庭づくり',
            iconClass: SpriteTypes.Service_PiggLifeApp,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.Mogg,
            linkUrl: 'https://s.mogg.ameba.jp/session/index?_=168304ace5e',
            title: 'モグ',
            subTitle: 'ピグでお料理ゲーム',
            iconClass: SpriteTypes.Service_Mogg,
            tapId: TAP_ID_GAMES_MORE,
          },
        ],
      },
    ],
  ],
};

export const MENU_DOKUSHO: MenuItem = {
  id: 'dokusho',
  serviceId: MineServiceIds.Dokusho,
  title: 'マンガ',
  linkUrl: process.env.DOMAIN_DOKUSHO_URL,
  target: '_blank',
  tapId: TAP_ID_SERVICE,
};

export const MENU_DOKUSHO_AUTHENTICATED: MenuItem = {
  id: 'dokusho',
  serviceId: MineServiceIds.Dokusho,
  title: 'マンガ',
  linkUrl: getDokushoLoginRedirectUrl(process.env.DOMAIN_DOKUSHO_URL),
  target: '_blank',
  tapId: TAP_ID_SERVICE,
};

export const MENU_CATEGORY: MenuItem = {
  id: 'category',
  serviceId: 'pc-header-category',
  title: 'その他',
  tapId: TAP_ID_SERVICE_MORE,
  categories: [
    [
      {
        title: 'ゲーム',
        apps: [
          {
            serviceId: MineServiceIds.Gf,
            linkUrl: 'http://vcard.ameba.jp/pc/?',
            title: 'ガールフレンド（仮）',
            subTitle: '耳で萌える学園恋愛ゲーム',
            iconClass: SpriteTypes.Service_Gf,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.PlatinumGirl,
            linkUrl: 'http://ameba.plgl.syapp.jp/',
            title: 'プラチナガール',
            subTitle: 'トップモデルを目指そう',
            iconClass: SpriteTypes.Service_Platina,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.Garusho,
            linkUrl: 'http://ameba.glcl.syapp.jp/',
            title: 'ガルショ',
            subTitle: 'おしゃれガールに大人気',
            iconClass: SpriteTypes.Service_Galsho,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.Hitsuji,
            linkUrl: 'https://api.rakuen-hitsuji.jp/pc-ameba/',
            title: '楽園生活 ひつじ村',
            subTitle: 'ほのぼの牧場シミュレーション',
            iconClass: SpriteTypes.Service_Hitsuji,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.Bkrs2,
            linkUrl: 'https://ameba.bkrs2.syapp.jp/',
            title: 'ぼくのレストラン２',
            subTitle: '目指せ一流レストラン♪',
            iconClass: SpriteTypes.Service_Bkrs2,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.Chocofarm,
            linkUrl: 'https://npr-ameba.sg.drecom.jp/',
            title: 'ちょこっとファーム',
            subTitle: '自分だけのステキな農園を作ろう',
            iconClass: SpriteTypes.Service_Chocofarm,
            tapId: TAP_ID_GAMES_MORE,
          },
          {
            serviceId: MineServiceIds.Vampire,
            linkUrl: 'https://y-game.v-blood-red.games/am_vampires/',
            title: 'ヴァンパイア†ブラッド',
            subTitle: '妖艶なゴシックカードバトル！',
            iconClass: SpriteTypes.Service_Vampire,
            tapId: TAP_ID_GAMES_MORE,
          },
        ],
      },
    ],
    [
      {
        title: 'メディア',
        apps: [
          {
            serviceId: MineServiceIds.Abema,
            linkUrl:
              'https://abema.tv?utm_source=www_ameba&utm_medium=ameba&utm_content=global_nav&utm_campaign=global_nav',
            title: 'ABEMA',
            subTitle: '新しい未来のテレビ',
            iconClass: SpriteTypes.Service_Abema,
            tapId: TAP_ID_SERVICE,
          },
          {
            serviceId: MineServiceIds.Satori,
            linkUrl: 'https://amb-uranai.ameba.jp/',
            title: '占い館SATORI',
            subTitle: '今日の運勢から恋の行方も占える',
            iconClass: SpriteTypes.Service_Satori,
            tapId: TAP_ID_SERVICE_MORE,
          },
          {
            serviceId: MineServiceIds.R25,
            linkUrl: 'https://r25.jp/',
            title: '新R25',
            subTitle: '20代ビジネスマンのバイブル',
            iconClass: SpriteTypes.Service_R25,
            tapId: TAP_ID_SERVICE_MORE,
          },
          {
            serviceId: MineServiceIds.Terakoyaplus,
            linkUrl: 'https://terakoya.ameba.jp/',
            title: 'Ameba塾探し',
            subTitle: '塾や習い事を探すなら',
            iconClass: SpriteTypes.Service_Terakoya,
            tapId: TAP_ID_SERVICE_MORE,
          },
          {
            serviceId: MineServiceIds.Datsumo,
            linkUrl: 'https://datsumo.ameba.jp/',
            title: 'LessMo（レスモ）',
            subTitle: '業界最大級の脱毛口コミサイト',
            iconClass: SpriteTypes.Service_LessMo,
            tapId: TAP_ID_SERVICE_MORE,
          },
          {
            serviceId: MineServiceIds.Manekai,
            linkUrl: 'https://manekai.ameba.jp/',
            title: 'マネ会',
            subTitle: '知って得するお金のメディア',
            iconClass: SpriteTypes.Service_Manekai,
            tapId: TAP_ID_SERVICE_MORE,
          },
          {
            serviceId: MineServiceIds.Awa,
            linkUrl: 'https://awa.fm/',
            title: 'AWA',
            subTitle: '音楽配信アプリ',
            iconClass: SpriteTypes.Service_AWA,
            tapId: TAP_ID_SERVICE_MORE,
          },
          {
            serviceId: MineServiceIds.Ownd,
            linkUrl:
              'https://www.amebaownd.com/?utm_source=ameba-jp&utm_medium=ameba&utm_campaign=mypage-navigation',
            title: 'Ameba Ownd',
            subTitle: '無料でかんたんホームページ作成',
            iconClass: SpriteTypes.Service_Ownd,
            tapId: TAP_ID_SERVICE_MORE,
          },
          {
            serviceId: MineServiceIds.Winticket,
            linkUrl: 'https://www.winticket.jp/',
            title: 'WINTICKET',
            subTitle: '公営競技の投票サービス',
            iconClass: SpriteTypes.Service_Winticket,
            tapId: TAP_ID_SERVICE_MORE,
          },
          {
            serviceId: MineServiceIds.AmebaChoice,
            linkUrl: 'https://choice.ameba.jp/',
            title: 'Amebaチョイス',
            subTitle: 'ライフスタイル比較メディア',
            iconClass: SpriteTypes.Service_AmebaChoice,
            tapId: TAP_ID_SERVICE_MORE,
          },
        ],
      },
    ],
  ],
};

import { produce } from 'immer';
import get from 'lodash/get';
import {
  readNotifications,
  readNotificationsUpdate,
} from '../../fetchr/services/Notifications/Notifications/NotificationsShared';

import { NotiReceiverItem } from '../../services/platform/response/PlatformApiResponse';

import { handleActions, ThunkActionCreator } from '../../lib/ReduxUtils';

interface State {
  notifications: NotiReceiverItem[];
  isLoading: boolean;
}

interface Payload {
  notifications: NotiReceiverItem[];
}

const initialState: State = {
  notifications: [],
  isLoading: true,
};

// TODO: 更新のACTIONを作成
export const FETCH_NOTIFICATIONS_REQUEST =
  'Notifications/FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS =
  'Notifications/FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE =
  'Notifications/FETCH_NOTIFICATIONS_FAILURE';
export const UPDATE_NOTIFICATIONS_SUCCESS =
  'Notifications/UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_FAILURE =
  'Notifications/UPDATE_NOTIFICATIONS_FAILURE';

export const fetchNotifications: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });
    try {
      const { data } = await readNotifications(req);
      dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_NOTIFICATIONS_FAILURE });
    }
  };
};

export const updateNotifications: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    try {
      const { data } = await readNotificationsUpdate(req);
      dispatch({ type: UPDATE_NOTIFICATIONS_SUCCESS, payload: data });
    } catch {
      dispatch({ type: UPDATE_NOTIFICATIONS_FAILURE });
    }
  };
};

export const notifications = handleActions<State, Payload>(
  {
    [FETCH_NOTIFICATIONS_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_NOTIFICATIONS_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const notifications = get(payload, 'notifications');
        draft.notifications = notifications;
        draft.isLoading = false;
      }),
    [FETCH_NOTIFICATIONS_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
  },
  initialState,
);

import { getAsyncComponent } from '../../../lib/ComponentUtils';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';
import { setTitle } from '../../../contexts/AppShell/SpHeader';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('ブログを始めてみませんか？', 'other'));
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./NonamebaidDesktop'))),
);

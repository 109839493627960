import { createAction, handleActions } from '../../../lib/ReduxUtils';
import { getInitialData } from '../../../fetchr/services/TopDesktop/Initial/InitialShared';
import { getLazyData } from '../../../fetchr/services/TopDesktop/Lazy/LazyShared';

const initialState = {};

export const FETCH_INITIAL_DATA = 'contexts/TopDesktop/Top/FETCH_INITIAL_DATA';
export const FETCH_LAZY_DATA = 'contexts/TopDesktop/Top/FETCH_LAZY_DATA';

export const fetchInitialData = createAction(
  FETCH_INITIAL_DATA,
  async (req) => {
    const { data } = await getInitialData(req);
    return data;
  },
);

export const fetchLazyData = createAction(FETCH_LAZY_DATA, async (req) => {
  const { data } = await getLazyData(req);
  return data;
});

export const top = handleActions(
  {
    [FETCH_INITIAL_DATA](state, { payload }) {
      return payload;
    },
    [FETCH_LAZY_DATA](state, { payload }) {
      return Object.assign({}, state, payload);
    },
  },
  initialState,
);

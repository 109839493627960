import React from 'react';
import { Helmet } from 'react-helmet-async';
import { composeRoute } from '../../../lib/ReactRouterUtils';
import { LoadableFrame } from '../../atoms/LoadableFrame';

function getPathnameByHash(hash: string) {
  if (!hash) {
    return;
  }

  const value = hash.charAt(0) === '#' ? hash.slice(1) : hash;
  return value.charAt(0) === '/' ? value.slice(1) : value;
}

/**
 * キャンペーン互換用のURL
 * @description
 * mypage/campaign/#/stamp/:id (302)
 * -> www/loginCheck?state=campaign/#/stamp/:id (302)
 * -> www/?state=campaign/#/stamp/:id (302)
 * -> www/campaign/#/stamp/:id (200)
 * -> www/campaign/stamp/:id (200)
 */
export default composeRoute({
  async clientLoader(_, match) {
    const pathname = getPathnameByHash(match.hash);
    if (pathname) {
      window.location.replace(`/campaign/${pathname}`);
    } else {
      window.location.replace('/');
    }
  },
})(
  class CampaignDesktop extends React.Component {
    render() {
      return (
        <>
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>
          <LoadableFrame moduleType="page" />
        </>
      );
    }
  },
);

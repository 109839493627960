export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

const JP_WEEKDAYS = ['日', '月', '火', '水', '木', '金', '土'];

export const FORMAT_TEMPLATE = {
  YMD: '{YYYY}年{M}月{D}日',
  YMDhm: '{YYYY}年{M}月{D}日 {hh}:{mm}',
  MD: '{M}月{D}日',
};

export function getJapaneseWeekDay(date: Date) {
  return JP_WEEKDAYS[date.getDay()];
}

/**
 * X分前 や X時間前 など返す
 */
export function getTimeago(date: number): string {
  const tar: Date = new Date(date);
  const diff = getDiffTime(date);
  const hour = Math.floor(diff / HOUR);
  const min = Math.floor(diff / MINUTE);
  const sec = Math.floor(diff / SECOND);
  switch (true) {
    case hour > 24:
      return `${tar.getMonth() + 1}月${tar.getDate()}日`;
    case min > 60:
      return `${hour}時間前`;
    case sec > 60:
      return `${min}分前`;
    default:
      return `${sec}秒前`;
  }
}

/**
 * もらった時間と現在の差分をmsで返す
 */
export function getDiffTime(date: number): number {
  const now: Date = new Date();
  const tar: Date = new Date(date);
  return now.getTime() - tar.getTime();
}

/**
 * もらった時間と現在の差分を日数で返す
 */
export function getDateDifference(date: number): number {
  const difference = Math.floor(getDiffTime(date) / DAY);
  return Math.abs(difference);
}

/**
 * 今日かどうか
 */
export function isToday(time: Date) {
  const now = new Date();
  return (
    now.getFullYear() === time.getFullYear() &&
    now.getMonth() === time.getMonth() &&
    now.getDate() === time.getDate()
  );
}

/**
 * ISO-8601形式のDateStringを取得
 * @param {Date} date
 * @returns {string}
 */
export function getISOString(date: Date) {
  const o = date.getTimezoneOffset();
  return (
    date.getFullYear() +
    '-' +
    padZero(date.getMonth() + 1, 2) +
    '-' +
    padZero(date.getDate(), 2) +
    'T' +
    padZero(date.getHours(), 2) +
    ':' +
    padZero(date.getMinutes(), 2) +
    ':' +
    padZero(date.getSeconds(), 2) +
    '.' +
    padZero(date.getMilliseconds(), 3) +
    (o > 0 ? '-' : '+') +
    padZero(Math.floor(Math.abs(o) / 60), 2) +
    ':' +
    padZero(Math.abs(o) % 60, 2)
  );
}

/**
 * DateStringの形式変換．デフォルトは「YYYY年MM月DD日 HH:MM」
 * @param {Data} date
 * @param {String} format
 * @returns {string}
 */
export function getFormattedDateTime(
  date: Date,
  format: string = FORMAT_TEMPLATE.YMDhm,
) {
  const dateFragments = [
    ['{YYYY}', date.getFullYear()],
    ['{M}', date.getMonth() + 1],
    ['{D}', date.getDate()],
    ['{h}', date.getHours()],
    ['{MM}', padZero(date.getMonth() + 1, 2)],
    ['{DD}', padZero(date.getDate(), 2)],
    ['{hh}', padZero(date.getHours(), 2)],
    ['{mm}', padZero(date.getMinutes(), 2)],
  ];
  let formattedString = format;
  for (const f of dateFragments) {
    formattedString = formattedString.replace(f[0] as string, f[1] as string);
  }
  return formattedString;
}

/**
 * 0を埋める
 * @param {string | number} source
 * @param {number} count
 * @returns {string}
 */
export function padZero(source: number, count: number) {
  const sourceString = String(source);
  let padString = '';
  for (let i = 0; i < count; i++) {
    padString += '0';
  }
  return (padString + sourceString).slice(-count);
}

/**
 * 指定の開始時間から指定の終了時間までの「X分」や「X時間」などを返す
 * Date型ではなく、number型(ミリ秒)で時間を渡すこと
 * @param {number} startTime
 * @param {number} endTime
 * @return {string}
 */
export function createLimitTime(startTime: number, endTime: number) {
  const now = +new Date(startTime);
  const end = +new Date(endTime);
  const diff = end - now;
  let left, limit;
  if (diff < 0) {
    limit = '-';
  } else if (0 <= diff && diff < MINUTE) {
    // in 1 minute
    limit = '1分';
  } else if (diff < HOUR) {
    // in an hour
    left = Math.floor(diff / MINUTE);
    limit = left + '分';
  } else if (diff < DAY) {
    // in 24 hours
    left = Math.floor(diff / HOUR);
    limit = left + '時間';
  } else {
    // over 1 day
    left = Math.floor(diff / DAY);
    limit = left + '日';
  }
  return limit;
}

/**
 * 第一引数の日から第二引数の日までの日数の差を返す
 * @param {number} d1
 * @param {number} d2
 * @return {number}
 */
export function getDateDistance(d1: number, d2: number) {
  const days = [d1, d2].map((d) => {
    const day = new Date(d);
    day.setHours(0, 0, 0, 0);
    return +new Date(day);
  });
  return (days[1] - days[0]) / DAY;
}

/**
 * 月末月初判定(月末3日間、月初7日間)
 * @return {boolean}
 */
const MONTH_FIRST_TERM = 7;
const MONTH_END_TERM = 3;
export function isMonthlyCampaignTerm() {
  const now = new Date();
  const nowDay = now.getDate();
  const monthEndDay = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0,
  ).getDate();
  const monthEndBefore3day = monthEndDay - MONTH_END_TERM;

  return nowDay <= MONTH_FIRST_TERM || monthEndBefore3day <= nowDay;
}

/**
 * 日本時間で現在の日付から任意の日数を引いた日付を取得する関数
 */
export function getDateWithOffset(
  baseDate: Date,
  offsetDays: number,
  resetTime: boolean = true,
) {
  if (resetTime) {
    baseDate.setHours(0, 0, 0, 0);
  }

  // 日本時間のタイムゾーンオフセット（JSTはUTC+9）
  const jstOffset = 9 * 60 * 60 * 1000;

  // JSTの現在日時を取得
  const nowJst = new Date(baseDate.getTime() + jstOffset);

  // オフセット日数を加算
  nowJst.setDate(nowJst.getDate() + offsetDays);

  // JSTからUTCに戻すためにオフセットを引いて返す
  const resultUtcTime = nowJst.getTime() - jstOffset;
  return new Date(resultUtcTime);
}

/**
 * YYYY-MM-DD HH:MM:SS+09:00 に変換する関数
 */
export function formatDateToJSTString(date: Date) {
  // UTC+9のオフセットを加算
  const jstOffset = 9 * 60 * 60 * 1000;
  date.setTime(date.getTime() + jstOffset);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}+09:00`;
}

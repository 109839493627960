import React from 'react';
import cx from 'classnames';

// background-imageの時に、画像の比率を作ってあげるための画像URL
export const RATIO_IMAGES = {
  // 比率1:2の画像
  OneTwo:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAQAAABeK7cBAAAAC0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=',
};

// ただのグレー画像
const DEFAULT_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsSAAALEgHS3X78AAAAKElEQVRIx+3NMQEAIAwDsA7/fnsyFXAlBjJtbx47+UAikUgkEolEkgVYyQQW6rWZrAAAAABJRU5ErkJggg==';

export enum LazyImageType {
  Image,
  Thumbnail,
  Icon_User,
  Icon_Group,
  Icon_Application,
}

export interface Props {
  src: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  imageType?: LazyImageType;
  className?: string;
  isBackground?: boolean;
  errorImage?: string; // error時の代替画像
  ratioImage?: string; // RATIO_IMAGESから選択
  rootMargin?: string; // 早めに表示させたい場合はマイナス値を多くする
}

interface State {
  targetSrc: string;
  isError: boolean;
}

export class LazyImage extends React.Component<Props, State> {
  observer: any;
  targetNode: any;

  static get defaultProps() {
    return {
      imageType: LazyImageType.Image,
      rootMargin: '-16px',
    };
  }

  constructor(props) {
    super(props);
    this.targetNode = null;
    this.state = {
      targetSrc: DEFAULT_URL, // 読み込み時にグレーになるように画像を入れておく
      isError: false,
    };
    this.dispose = this.dispose.bind(this);
    this.handleError = this.handleError.bind(this);
    this.visibleChanged = this.visibleChanged.bind(this);
  }

  dispose() {
    if (this.observer && this.targetNode) {
      this.observer.unobserve(this.targetNode);
    }
  }

  componentDidMount() {
    const { rootMargin } = this.props;
    this.observer = new IntersectionObserver(this.visibleChanged.bind(this), {
      rootMargin,
    });
    this.observer.observe(this.targetNode);
  }

  componentWillUnmount() {
    this.dispose();
  }

  /**
   * IntersectionObserverのイベント
   */
  visibleChanged(changes) {
    if (!changes.length) {
      return;
    }

    const disposeObserver = () => {
      setTimeout(() => {
        this.observer && this.observer.unobserve(this.targetNode);
      });
    };

    if (this.state.isError) {
      disposeObserver();
      return;
    }
    // 交差領域の getBoundingClientRect()
    const rect = changes[0].intersectionRect;

    if (rect.height > 0 || rect.width > 0) {
      this.setState({ targetSrc: this.props.src });
      disposeObserver();
    }
  }

  /**
   * エラーイベント (imgタグ)
   */
  handleError() {
    this.setState({ isError: true });
  }

  render() {
    const {
      src,
      alt,
      width,
      height,
      imageType,
      className,
      isBackground,
      errorImage,
      ratioImage,
      rootMargin,
      ...props
    } = this.props;
    const { targetSrc, isError } = this.state;

    let element = isBackground && !ratioImage ? 'i' : 'img';
    const imgProps: any = {};
    const sizeStyle = { width, height };

    if (!src || isError) {
      if (isError && errorImage) {
        element = 'img';
        imgProps.src = errorImage;
        imgProps.alt = alt;
        imgProps.style = sizeStyle;
      } else {
        // エラー時はdivタグに切り替えて、代価viewを表示
        element = 'div';
        // 画像のサイズがあれば画像サイズに合わせてアイコンサイズを設定。なければデフォルトサイズ
        const size = height || width ? parseInt(`${height || width}`, 10) : 50;
        imgProps.style = {
          ...sizeStyle,
          fontSize: `${size * 0.7}px` /* アイコンのサイズは画像の７割 */,
          lineHeight: `${
            size / 10
          }rem` /* アイコンの高さは画像サイズに合わせる */,
        };
        imgProps.role = 'img';
        if (alt) {
          imgProps['aria-label'] = alt;
        }
      }
    } else if (isBackground) {
      imgProps.style = {
        ...sizeStyle,
        backgroundImage: `url(${targetSrc})`,
      };
      if (alt) {
        imgProps['aria-label'] = alt;
      }

      // 縦横比を固定するためのImage
      if (ratioImage) {
        imgProps.src = ratioImage;
      }
    } else {
      imgProps.src = targetSrc;
      imgProps.alt = alt;
      imgProps.style = sizeStyle;
      imgProps.onError = this.handleError;
    }
    const cls = cx('LazyImage', className, LazyImageType[imageType], {
      '-backgroundImg': isBackground,
      '-noImage': !src || isError,
      '-ready': !src || isError || targetSrc !== DEFAULT_URL,
    });

    return React.createElement(element, {
      className: cls,
      style: sizeStyle,
      ref: (node) => (this.targetNode = node),
      ...imgProps,
      ...props,
    });
  }
}

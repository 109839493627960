import qs from 'query-string';
import * as frm from '../lib/FrmIdUtils';
import { localStorage } from './Storage';

const KEY = 'fid';

// save on bootstrap
export function save() {
  const q = qs.parse(window.location.search);
  const fid = typeof q.frm_id === 'string' ? q.frm_id : q.frm_id?.join(',');
  localStorage.setItem(KEY, fid);
}

export function load() {
  const fid: string = localStorage.getItem(KEY);
  return frm.parse(fid);
}

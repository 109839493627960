import React from 'react';
import cx from 'classnames';
import { SpinnerBar } from '../Spinner';
import { LoadableFrameError } from './LoadableFrameError';

interface Props {
  className?: string;
  isLoading?: boolean; // ローディング中かどうか
  isError?: boolean; // ローディング中エラーが起きたらisErrorを渡す
  // モジュールによって違うスタイル／エラー文言の出し分け
  moduleType?: 'page' | 'module' | 'mini' | 'free';
  render?(): React.ReactElement<any>;
}

/**
 * ModuleLoading
 */
export const LoadableFrame: React.FC<Props> = ({
  className,
  isLoading,
  isError,
  moduleType,
  render,
}) => {
  if (!isLoading && !isError) {
    return render ? render() : null;
  }

  return (
    <div className={cx('LoadableFrame', `-${moduleType}`, className)}>
      <div className="LoadableFrame_Inner">
        {isError ? (
          <LoadableFrameError moduleType={moduleType} />
        ) : (
          <SpinnerBar />
        )}
      </div>
    </div>
  );
};

LoadableFrame.defaultProps = {
  isLoading: true,
  moduleType: 'free',
};

import { produce } from 'immer';
import { registerCopyright } from '../../../fetchr/services/Copyright/Confirm/ConfirmShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';

import { CurrntCopyrightItem } from '../../../services/amebaPlatformApi/response/AmebaPlatformApiService';

interface State {
  currentCopyrightItem: CurrntCopyrightItem;
  isSuccess: boolean;
}

interface Payload {
  currentCopyrightItem: CurrntCopyrightItem;
  isSuccess: boolean;
}

const initialState = {
  currentCopyrightItem: null,
  isSuccess: false,
};

// TODO: 更新のACTIONを作成
export const POST_REGISTER_COPYRIGHT_SUCCESS =
  'contexts/Copyright/POST_REGISTER_COPYRIGHT_SUCCESS';
export const POST_REGISTER_COPYRIGHT_FAILURE =
  'contexts/Copyright/POST_REGISTER_COPYRIGHT_FAILURE';

export const postCopyrightConfirm: ThunkActionCreator = (body?) => {
  return async (dispatch) => {
    try {
      const { data } = await registerCopyright(null, body);
      dispatch({ type: POST_REGISTER_COPYRIGHT_SUCCESS, payload: data });
    } catch {
      dispatch({ type: POST_REGISTER_COPYRIGHT_FAILURE });
    }
  };
};

export const copyrightConfirm = handleActions<State, Payload>(
  {
    [POST_REGISTER_COPYRIGHT_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.isSuccess = true;
      }),
  },
  initialState,
);

import { TradelawDataPayload } from '../../../../services/appmng/entity/Tradelaw';
import SharedFetchr from '../../FetchrSharedFactory';
import * as AppService from './TradelawConfig';

export function getTradelaw(req?) {
  const fetchr = new SharedFetchr({ req });
  const clientId = req.id;
  return fetchr.read<TradelawDataPayload>(AppService.tradelaw.name, {
    clientId,
  });
}

import React from 'react';
import cx from 'classnames';
import Tap from '../../../atoms/Tap';
import { SECTION_ID, MenuItem } from './constants/MENU';

interface Props {
  className?: string;
  handleClick?(): void;
}

const PcHeaderMenu: React.FC<MenuItem & Props> = ({
  className,
  handleClick,
  target,
  linkUrl,
  title,
  serviceId,
  tapId,
}) => {
  const props = {};
  if (handleClick) {
    props['onClick'] = handleClick;
  } else {
    props['href'] = linkUrl;
  }
  return (
    <li className={cx('PcHeader_Nav_List', className)}>
      <Tap
        transparent
        className="-button"
        target={target}
        track={{
          section_id: SECTION_ID,
          tap_id: tapId,
          service_id: serviceId,
        }}
        {...props}
      >
        {title}
      </Tap>
    </li>
  );
};

export default PcHeaderMenu;

import { ApplicationsServicePayload } from '../../../../services/appmng/response/AppmngApiService';
import SharedFetchr from '../../FetchrSharedFactory';
import * as AppService from './TopConfig';

export function getMyApps(req?) {
  const fetchr = new SharedFetchr({ req });
  return fetchr.read<ApplicationsServicePayload>(AppService.myApps.name, {
    req,
  });
}

import { AmebaConnectApiUserinfoPayload } from '../../../../services/amebaConnect/response/AmebaConnectApiService';
import SharedFetchr from '../../FetchrSharedFactory';
import * as ConnectService from './ConnectConfig';

export function getConnectSettings(req?) {
  const fetchr = new SharedFetchr({ req });
  return fetchr.read<AmebaConnectApiUserinfoPayload>(
    ConnectService.connect.name,
  );
}

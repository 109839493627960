export const ERROR_MESSAGE = {
  404: {
    title: 'ご指定のページを表示できません。',
    text: '指定のページは移動、または、削除された可能性がございます。もしくは、ご指定のURLが違う可能性がございます。URLをご確認のうえ再度アクセスお願いいたします。',
  },
  500: {
    title: 'ご指定のページを表示できません。',
    text: 'ただいま、メンテナンスによりサービスがご利用できない状態となっております。または、障害のため、ご指定のページを表示することができません。お手数ですが、終了するまで今しばらくお待ちください。',
  },
};

import { createClassName } from '../../../../lib/CssUtils';

/**
 * AmebaIconClasses
 * Ameba共通フォント
 * @see { https://ghe.ca-tools.org/ameba-app/ameba-app-design/blob/master/src/font/AmebaSymbolsV3_cord_list.pdf }
 * @see { https://wiki.ca-tools.org/pages/viewpage.action?pageId=10327223 }
 */
const AmebaIconClasses = createClassName('AmebaIcon AmebaIcon_', {
  /* Arrow */
  ArrowBack: null,
  ArrowForward: null,
  ArrowUp: null,

  ArrowPagingLeft: null,
  ArrowPagingRight: null,
  ArrowPagingUp: null,
  ArrowPagingDown: null,

  TriangleLeft: null,
  TriangleRight: null,
  TriangleUp: null,
  TriangleDown: null,

  DecaTriangleLeft: null,
  DecaTriangleRight: null,
  DecaTriangleUp: null,
  DecaTriangleDown: null,
  TriangleEndLeft: null,
  TriangleEndRight: null,

  CircleArrowLeft: null,
  CircleArrowRight: null,
  CircleArrowUp: null,
  CircleArrowDown: null,

  /* Ranking */
  Ranking: null, // 表彰台
  RankUp: null,
  RankStay: null,
  RankDown: null,
  Crown: null,
  CrownOld: null,

  /* 数字 */
  One: null,
  Two: null,
  Three: null,

  /* ビデオ */
  Movie: null,
  Pause: null,
  Play: null,
  Stop: null,
  SpeakerOn: null,
  SpeakerOff: null,
  FreshReplay: null,
  FullScreen: null,
  FullScreenInline: null,

  /* 文字 */
  Exclam: null,
  Question: null,
  Hot: null,
  New: null,

  /* Coin */
  AmeGold: null,
  Coin: null,

  /* Service */
  Ownd: null,
  Pigg: null,
  BlogNeta: null,
  Webview: null,
  Dotmoney: null,
  Line: null,
  Facebook: null,
  FacebookB: null,
  Twitter: null,
  HatenaBlog: null,
  S_755: null,
  Youtube: null,
  Instagram: null,
  Gruppo: null,
  Now: null,
  AbemaTV: null,
  Dokusho: null,

  /* Checklist */
  Checklist: null,
  PcChecklist: null,
  AsChecklist: null,

  /* Ametomo */
  Ametomo: null,
  AmetomoCancel: null,
  AmetomoAdd: null,
  AmetomoDone: null,

  /* Profile */
  Profile: null,
  ProfileCard: null,

  /* Stamp */
  Stamp: null,
  StampCard: null,
  StampStar: null,

  /* Menu */
  Hanburger: null,
  MenuVertical: null,
  MenuHorizontal: null,

  /* Comment */
  Comment: null,
  CommentReject: null,

  /* Loading */
  Loading: null,
  LoadingError: null,

  /* Nice */
  Nice: null,
  NiceDone: null,

  /* Blog */
  TopBlogger: null,
  HtmlTag: null,
  HashTag: null,
  Reblog: null,
  ReblogReject: null,
  AddGenre: null,
  Genre: null,
  AccessCount: null,
  BlogEntry: null,
  EditorAlign: null,
  EditorEmotion: null,
  EditorFontColor: null,
  EditorFontStyle: null,
  EditorKeyboard: null,
  EditorLink: null,
  EditorVoice: null,

  Square: null,
  Beginner: null,
  DesignSetting: null,
  ArrowDown: null,
  Time: null,
  History: null,
  Plus: null,
  Directory: null,
  File: null,
  Calendar: null,
  Blog: null,
  Save: null,
  Caution: null,
  Community: null,
  Abema: null,
  Popular: null,
  Trash: null,
  PhotoEdit: null,
  Game: null,
  Home: null,
  News: null,
  Notification: null,
  Camera: null,
  Service: null,
  Refresh: null,
  RefreshB: null,
  Setting: null,
  User: null,
  Close: null,
  Trophy: null,
  SnsConnect: null,
  All: null,
  Bbs: null,
  Message: null,
  Favorite: null,
  Heart: null,
  AsComment: null,
  AsStar: null,
  MessageBoardOld: null,
  AsAccessLink: null,
  AsAccessPage: null,
  Present: null,
  Night: null,
  RightDrawer: null,
  SortTile: null,
  SortFeed: null,
  Amember: null,
  SecretGroup: null,
  Mail: null,
  Magicwand: null,
  Pickup: null,
  Circle: null,
  Album: null,
  Qrcode: null,
  Replay: null,
  Search: null,
  Check: null,
  AddressBook: null,
  Tv: null,
  Withdrawal: null,
  Draft: null,
  DotNew: null,
  Userlist: null,
  Embed: null,
  Opening: null,
  Clipblog: null,
  Device: null,
  Koeblog: null,
  ExternalLink: null,
});

export default AmebaIconClasses;

import { createAction, handleActions } from '../../../lib/ReduxUtils';

export type SpHeaderType =
  | 'top'
  | 'home'
  | 'campaign_misc'
  | 'copyright'
  | 'apps'
  | 'other';

interface State {
  title: string;
  headerType: SpHeaderType;
}

const initialState = {
  title: null,
  headerType: null,
};

const SET_TITLE = 'AppShell/SpHeader/SET_TITLE';
export const setTitle = createAction(
  SET_TITLE,
  (title: string, headerType?: SpHeaderType) => {
    return {
      title,
      headerType,
    };
  },
);

export const spHeader = handleActions<State, State>(
  {
    [SET_TITLE](state, { payload }) {
      const nextState: any = {
        title: payload.title,
      };

      if (payload.headerType) {
        nextState.headerType = payload.headerType;
      }

      return {
        ...state,
        ...nextState,
      };
    },
  },
  initialState,
);

import 'core-js/stable/map';
import 'core-js/stable/set';
import 'core-js/stable/array/find';
import 'core-js/stable/array/find-index';
import 'core-js/stable/array/from';
import 'core-js/stable/set-immediate';
import 'intersection-observer';
import 'es6-promise/auto';
import 'raf/polyfill';
import * as objectAssign from 'object.assign';
objectAssign.shim();

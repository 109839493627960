import axios from 'axios';
import { AdcrossType } from '../services/adcross/response/AdcrossService';

export const adcrossClient = axios.create({
  baseURL: process.env.API_MEAS_ADCROSS_URL,
  params: {
    v: 4,
  },
});

export { AdcrossType };

export interface AdcrossMeasureSchema {
  type: AdcrossType;
  hash: string;
  genuuid?: boolean;
}

export interface AdcrossBulkMeasureSchema {
  type: AdcrossType;
  hashes: string[];
  genuuid?: boolean;
}

export interface AdcrossBaseMeasureSchema {
  type: AdcrossType;
  hash?: string;
  hashes?: string[];
  genuuid?: boolean;
}

export function trackClick(
  hash: string,
  type: AdcrossType,
  genuuid?: boolean,
): Promise<unknown> {
  if (!hash) {
    return Promise.resolve();
  }
  if (type === AdcrossType.ssp) {
    return trackSspClick(hash);
  }
  return adcrossClient.get(`/${AdcrossType[type]}/meas/click`, {
    params: { hash, genuuid },
  });
}

export function trackView(
  hashes: string[],
  type: AdcrossType,
  genuuid?: boolean,
): Promise<unknown> {
  if (!hashes || !hashes.length) {
    return Promise.resolve();
  }
  if (type === AdcrossType.ssp) {
    return trackSspInview(hashes);
  }
  return adcrossClient.post(
    `/${AdcrossType[type]}/meas/bulk/inview`,
    { hashes },
    {
      params: {
        genuuid,
      },
    },
  );
}

export function trackSspImp(hashes: string[]): Promise<unknown> {
  return Promise.all(
    hashes.map((hash) => {
      return adcrossClient.get(`/ssp/mine/${process.env.MINE_ID}/imp`, {
        params: { hash },
      });
    }),
  );
}

export function trackSspInview(hashes: string[]): Promise<unknown> {
  return Promise.all(
    hashes.map((hash) => {
      return adcrossClient.get(
        `/ssp/mine/${process.env.MINE_ID}/inview?hash=${hash}`,
      );
    }),
  );
}

export function trackSspClick(hash: string): Promise<unknown> {
  return adcrossClient.get(`/ssp/mine/${process.env.MINE_ID}/click`, {
    params: { hash },
  });
}

import { createAction, handleActions } from '../../../lib/ReduxUtils';
import { AbemaProgramSchema } from '../../../services/abema/response/AbemaService';

const initialState = {
  programs: [],
};

export interface ProgramCopyrights {
  id: string;
  title: string;
  copyrights: string[];
}

interface StateAndPayload {
  programs: ProgramCopyrights[];
}

export type AbemaProgramCopyrightsSchema = StateAndPayload;

export const ADD_PROGRAMS = 'Common/AbemaCopyrights/ADD_PROGRAMS';
export const REMOVE_PROGRAMS = 'Common/AbemaCopyrights/REMOVE_PROGRAMS';
export const ADD_RECOMMEND_PROGRAMS =
  'Common/AbemaCopyrights/ADD_RECOMMEND_PROGRAMS';
export const REMOVE_RECOMMEND_PROGRAMS =
  'Common/AbemaCopyrights/REMOVE_RECOMMEND_PROGRAMS';
export const REMOVE_ALL = 'Common/AbemaCopyrights/REMOVE_ALL';

/**
 * programがcomponentに入ったら
 */
export const addPrograms = createAction<StateAndPayload>(
  ADD_PROGRAMS,
  (programs: AbemaProgramSchema[] = []) => {
    return {
      programs: programs.map((program) => ({
        id: program.id,
        title: program.title,
        copyrights: program.copyrights,
      })),
    };
  },
);

/**
 * componentWillUnMountの段階でremove
 */
export const removePrograms = createAction<StateAndPayload>(
  REMOVE_PROGRAMS,
  (programs: AbemaProgramSchema[] = []) => {
    return {
      programs: programs.map((program) => ({
        id: program.id,
        title: program.title,
        copyrights: program.copyrights,
      })),
    };
  },
);

export const removeAll = createAction(REMOVE_ALL, () => {
  return {};
});

export const abemaCopyrights = handleActions<StateAndPayload, StateAndPayload>(
  {
    [ADD_PROGRAMS]: getUniqueProgramReducer,
    [ADD_RECOMMEND_PROGRAMS]: getUniqueProgramReducer,
    [REMOVE_PROGRAMS]: getRemovedProgramsReducer,
    [REMOVE_RECOMMEND_PROGRAMS]: getRemovedProgramsReducer,
    [REMOVE_ALL]: () => initialState,
  },
  initialState,
);

function getUniqueProgramReducer(state, { payload }): StateAndPayload {
  const programs = state.programs.concat(payload.programs);
  const ids = programs.map((program) => program.id);
  return {
    programs: programs.filter((program, i) => ids.indexOf(program.id) === i),
  };
}

function getRemovedProgramsReducer(state, { payload }): StateAndPayload {
  const ids = payload.programs.map((program) => program.id);
  return {
    programs: state.programs.filter(
      (program) => ids.indexOf(program.id) === -1,
    ),
  };
}

import { parse, stringify } from 'querystring';
import { matchRoutes, RouteConfig } from 'react-router-config';
import { Location } from './trigger';

/**
 * Componentに付加するプロパティ名
 * @type {string}
 */
export const ROUTER_PERMISSION_PROPERTY = '@@RouterPermission';

/**
 * Componentに付加する情報型
 */
export interface RouterPermissionObject {
  permission: RouterPermission;
  redirect: string;
  useRedirectPath: boolean;
}

/**
 * RouterPermissionのEnum
 */
export enum RouterPermission {
  AUTHENTICATED,
  UNAUTHENTICATED,
  NONE,
}

/**
 * Routerに認証状態に依るリダイレクト情報を加える
 * @param {RouterPermission} permission
 * @param {string} redirect
 * @param {boolean} useRedirectPath
 * @return {(ComposedComponent) => any}
 *
 * @description
 * classの場合
 * ```
 * composeRouterPermission(RouterPermission.AUTHENTICATED, '/リダイレクト先')(
 *   class extends React.Component {}
 * )
 * ```
 *
 * その他
 * ```
 * composeRouterPermission(RouterPermission.UNAUTHENTICATED, '/リダイレクト先')(TargetComponent);
 * ```
 */
export const composeRouterPermission =
  (permission: RouterPermission, redirect: string, useRedirectPath?: boolean) =>
  (ComposedComponent) => {
    ComposedComponent[ROUTER_PERMISSION_PROPERTY] = {
      permission,
      redirect,
      useRedirectPath,
    };
    return ComposedComponent;
  };

/**
 * Routeの認証状態に依存するリダイレクト情報を取得する
 * 無ければ {void} を返す
 * @param {RouteConfig[]} routes
 * @param {Location} location
 * @param {boolean} isAuthenticated
 * @return {string}
 */
export const checkRouterPermission = (
  routes: RouteConfig[],
  location: Location,
  isAuthenticated: boolean,
): string => {
  const matches = matchRoutes(routes, location.pathname);

  for (const route of matches) {
    const { route: routeConfig } = route;
    if (
      routeConfig.component &&
      routeConfig.component[ROUTER_PERMISSION_PROPERTY]
    ) {
      // check permission & judge redirect
      const routerPermission: RouterPermissionObject =
        routeConfig.component[ROUTER_PERMISSION_PROPERTY];
      if (routerPermission.permission === RouterPermission.NONE) {
        return routerPermission.redirect;
      }
      if (isAuthenticated) {
        if (routerPermission.permission === RouterPermission.UNAUTHENTICATED) {
          return routerPermission.redirect;
        }
      } else {
        if (routerPermission.permission === RouterPermission.AUTHENTICATED) {
          // internal redirect and add redirect_path
          if (
            routerPermission.useRedirectPath &&
            routerPermission.redirect.charAt(0) === '/'
          ) {
            const [path, qs] = routerPermission.redirect.split('?');
            const query = parse(qs);
            // 先頭`/`始まりのものをredirect_pathにする
            const redirect_path =
              location.pathname.charAt(0) === '/'
                ? location.pathname
                : `/${location.pathname}`;
            query.redirect_path = encodeURIComponent(redirect_path);
            if (query.redirect_path) {
              return path + '?' + stringify(query);
            }
          }
          return routerPermission.redirect;
        }
      }
    }
  }

  return;
};

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import {
  SnackBarMessage,
  shiftMessage,
} from '../../../../contexts/AppShellDesktop/SnackBar';
import SnackBar from '../../../atoms/SnackBar';

interface Props {
  messages: SnackBarMessage[];
}

interface State {
  message: SnackBarMessage;
  isOpen: boolean;
}

class SnackBarContainer extends React.Component<
  Props & DispatchProp<any>,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      isOpen: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidUpdate(prevProps: Props) {
    // 非表示の時に
    if (!this.state.isOpen) {
      // まだメッセージが残ってる場合に
      if (this.props.messages.length && !this.state.message) {
        // 表示する
        this.setState({
          message: this.props.messages[0],
          isOpen: true,
        });
      }
    }
  }
  handleClose() {
    this.props.dispatch(shiftMessage());
    this.setState(
      {
        isOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({ message: null });
        }, 500);
      },
    );
  }
  render() {
    const { message, isOpen } = this.state;
    return (
      <SnackBar
        isOpen={isOpen && !!message}
        message={message && message.message}
        type={message && message.type}
        offset={message && message.offset}
        onRequestClose={this.handleClose}
      />
    );
  }
}

function mapStateToProps(state: any) {
  return {
    messages: state.snackBar.messages,
  };
}

export default connect(mapStateToProps)(SnackBarContainer);

import { Request } from 'express';
import { produce } from 'immer';
import get from 'lodash/get';
import { PAGE_IDS } from '../../../constants/routes';
import {
  AccessAnalysisReadPayload,
  BlogCommentReadPayload,
  BlogHistoryReadPayload,
  CoinModuleReadPayload,
  MangafeedReadPayload,
  MangafeedSerialReadPayload,
  MoneyModuleReadPayload,
} from '../../../fetchr/services/HomeDesktop/HomeDesktop/HomeDesktopConfig';
import {
  readAccessAnalysisData,
  readBlogCommentedData,
  readBlogHistoryData,
  readBlogHashTagData,
  readCoinModuleData,
  readInitialData,
  readLazyData,
  readMangafeedData,
  readMangafeedSerialData,
  readMoneyModuleData,
} from '../../../fetchr/services/HomeDesktop/HomeDesktop/HomeDesktopShared';
import {
  readHomeNotifications,
  readNotificationUpdate,
} from '../../../fetchr/services/Notifications/Notifications/NotificationsShared';
import {
  ASYNC_STATUS,
  createAction,
  handleActions,
  ThunkActionCreator,
} from '../../../lib/ReduxUtils';
import { MainTopicEntity } from '../../../services/_common/entity/MainTopicEntity';
import { MediaJackEntity } from '../../../services/_pc/entity/MediaJackEntity';
import { AdcrossAdSchema } from '../../../services/adcross/response/AdcrossService';
import { AmebaRssEntry } from '../../../services/amebaRss/response/AmebaRssEntry';
import BlogTag from '../../../services/blog/entity/BlogTag';
import { NotiReceiverItem } from '../../../services/platform/response/PlatformApiResponse';
import { MypageApiMaintenanceText } from '../../../types/apis/mypage-api';
import { HomeDesktopPopup } from '../../../types/type/home-desktop-popup';
import { getSpot, measureSpot } from '../../../webApi/Adx-v3';
import { trackClick } from '../../../webApi/Tracker';
import { SPOT_HOME_DESKTOP_POPUP } from '../../../constants/adx-v3';

export interface State {
  staffBlog: AmebaRssEntry[];
  maintenanceText: MypageApiMaintenanceText[];
  mediaJack: MediaJackEntity;
  accessAnalysis: AccessAnalysisReadPayload;
  topics: MainTopicEntity[];
  blogBanners: AdcrossAdSchema[];
  campaignBanners: AdcrossAdSchema[];
  popup: HomeDesktopPopup;
  notifications: NotiReceiverItem[];
  notificationsCount: number;
  coinModule: CoinModuleReadPayload;
  moneyModule: MoneyModuleReadPayload;
  blogHistory: BlogHistoryReadPayload;
  mangafeed: MangafeedReadPayload;
  blogHashtag: BlogTag[];
  blogComment: BlogCommentReadPayload;
}

type Payload = Partial<State>;

const initialState: State = {
  staffBlog: null,
  maintenanceText: null,
  mediaJack: null,
  accessAnalysis: null,
  topics: null,
  blogBanners: null,
  campaignBanners: null,
  popup: null,
  notifications: [],
  notificationsCount: null,
  coinModule: null,
  moneyModule: null,
  blogHistory: null,
  mangafeed: null,
  blogHashtag: null,
  blogComment: null,
};

export const INITIALIZE_STATE = 'HomeDesktop/HomeDesktop/INITIALIZE_STATE';
export const initializeState = createAction<Payload>(INITIALIZE_STATE, () => {
  return {};
});

export const FETCH_INITIALIZE_DATA =
  'HomeDesktop/HomeDesktop/FETCH_INITIALIZE_DATA';
export const fetchInitialData = createAction<Payload>(
  FETCH_INITIALIZE_DATA,
  async (req?) => {
    try {
      const { data } = await readInitialData(req);
      return data;
    } catch {
      return {};
    }
  },
);

export const FETCH_LAZY_DATA = 'HomeDesktop/HomeDesktop/FETCH_LAZY_DATA';
export const fetchLazyData = createAction<Payload>(
  FETCH_LAZY_DATA,
  async (req) => {
    try {
      const { data } = await readLazyData(req);
      return data;
    } catch {
      return {};
    }
  },
);

const FETCH_ACCESS_ANALYSIS = 'HomeDesktop/HomeDesktop/FETCH_ACCESS_ANALYSIS';
export const fetchAccessAnalysis = createAction<Payload>(
  FETCH_ACCESS_ANALYSIS,
  async (req) => {
    try {
      const { data: accessAnalysis } = await readAccessAnalysisData(req);
      return { accessAnalysis };
    } catch {
      return {};
    }
  },
);

export const FETCH_COIN_MODULE = 'HomeDesktop/HomeDesktop/FETCH_COIN_MODULE';
export const fetchCoinModule = createAction<Payload>(
  FETCH_COIN_MODULE,
  async (req?) => {
    try {
      const { data: coinModule } = await readCoinModuleData(req);
      return {
        coinModule,
      };
    } catch {
      return {};
    }
  },
);

export const FETCH_MONEY_MODULE = 'HomeDesktop/HomeDesktop/FETCH_MONEY_MODULE';
export const fetchMoneyModule = createAction<Payload>(
  FETCH_MONEY_MODULE,
  async (req?) => {
    try {
      const { data: moneyModule } = await readMoneyModuleData(req);
      return {
        moneyModule,
      };
    } catch {
      return {};
    }
  },
);

export const FETCH_BLOG_HISTORY = 'HomeDesktop/HomeDesktop/FETCH_BLOG_HISTORY';
export const fetchBlogHistory = createAction<Payload>(
  FETCH_BLOG_HISTORY,
  async (req?) => {
    try {
      const { data: blogHistory } = await readBlogHistoryData(req);
      return {
        blogHistory,
      };
    } catch {
      return {
        blogHistory: [],
      };
    }
  },
);

export const EMPTY_BLOG_HISTORY = 'HomeDesktop/HomeDesktop/EMPTY_BLOG_HISTORY';
export const emptyBlogHistory = createAction<Payload>(
  EMPTY_BLOG_HISTORY,
  () => {
    return {};
  },
);

export const FETCH_MANGAFEED = 'HomeDesktop/HomeDesktop/FETCH_MANGAFEED';
export const fetchMangafeed = createAction<Payload>(
  FETCH_MANGAFEED,
  async (req?) => {
    try {
      const { data: mangafeed } = await readMangafeedData(req);
      return {
        mangafeed,
      };
    } catch {
      return {};
    }
  },
);

export const FETCH_MANGAFEED_SERIAL_START =
  'HomeDesktop/HomeDesktop/FETCH_MANGAFEED_SERIAL_START';
export const fetchMangafeedSerialStart = createAction<Payload>(
  FETCH_MANGAFEED_SERIAL_START,
  () => {
    return {};
  },
);

export const FETCH_MANGAFEED_SERIAL_COMPLETE =
  'HomeDesktop/HomeDesktop/FETCH_MANGAFEED_SERIAL_COMPLETE';
export const fetchMangafeedSerialComplete = createAction<Payload>(
  FETCH_MANGAFEED_SERIAL_COMPLETE,
  (mangafeed: MangafeedReadPayload, serial?: MangafeedSerialReadPayload) => {
    if (!serial) {
      mangafeed.serial.status = ASYNC_STATUS.failure;
    } else {
      // replace serial so it is detected as new state
      const _serial = { ...serial };
      _serial.entityList.unshift(...mangafeed.serial.entityList);
      mangafeed.serial = _serial;
    }
    return {
      mangafeed,
    };
  },
);

export const fetchMangafeedSerial: ThunkActionCreator = (req?: Request) => {
  return async (dispatch, getState) => {
    const { homeDesktop } = getState();
    const mangafeed = homeDesktop.mangafeed;
    const status = get(mangafeed, 'serial.status', null);
    const offset = get(mangafeed, 'serial.offset', null);

    if (status === ASYNC_STATUS.success && mangafeed && offset) {
      dispatch(fetchMangafeedSerialStart());
      const { data: serial } = await readMangafeedSerialData(req, offset);
      dispatch(fetchMangafeedSerialComplete(mangafeed, serial));
    }
  };
};

export const FETCH_BLOG_HASHTAG = 'HomeDesktop/HomeDesktop/FETCH_BLOG_HASHTAG';
export const fetchBlogHashTag = createAction<Payload>(
  FETCH_BLOG_HASHTAG,
  async (req?) => {
    try {
      const { data: blogHashtag } = await readBlogHashTagData(req);
      return {
        blogHashtag,
      };
    } catch {
      return {
        blogHashtag: [],
      };
    }
  },
);

export const FETCH_BLOG_COMMENT = 'HomeDesktop/HomeDesktop/FETCH_BLOG_COMMENT';
export const fetchBlogComment = createAction<Payload>(
  FETCH_BLOG_COMMENT,
  async (req?) => {
    try {
      const { data: blogComment } = await readBlogCommentedData(req);
      return {
        blogComment,
      };
    } catch {
      return {
        blogComment: [],
      };
    }
  },
);

export const EMPTY_BLOG_COMMENT = 'HomeDesktop/HomeDesktop/EMPTY_BLOG_COMMENT';
export const emptyBlogComment = createAction<Payload>(
  EMPTY_BLOG_COMMENT,
  () => {
    return {};
  },
);

export const FETCH_NOTIFICATIONS_SUCCESS =
  'Notifications/FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE =
  'Notifications/FETCH_NOTIFICATIONS_FAILURE';
export const fetchNotifications: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    try {
      const { data } = await readHomeNotifications(req);
      dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_NOTIFICATIONS_FAILURE });
    }
  };
};

export const updateNotification: ThunkActionCreator = (
  notificationId,
  linkUrl,
  clickTrack,
) => {
  return async () => {
    try {
      await readNotificationUpdate(null, notificationId);
      trackClick(clickTrack);
      location.href = linkUrl;
    } catch {
      return;
    }
  };
};

export const fetchOnClientLoaded: ThunkActionCreator =
  (req?) => async (dispatch, getState) => {
    const user = get(getState(), 'loginInfo.user', {});
    const tasks = [
      Promise.resolve()
        .then(() => dispatch(fetchLazyData(req)))
        .catch(() => null),
      dispatch(fetchNotifications(req)),
      dispatch(fetchMangafeed(req)),
      dispatch(fetchBlogHashTag(req)),
    ];
    if (user.amebaId) {
      tasks.push(dispatch(fetchBlogHistory(req)));
      tasks.push(dispatch(fetchAccessAnalysis(req)));
    } else {
      tasks.push(dispatch(emptyBlogHistory()));
    }
    await Promise.all(tasks);
  };

export const fetchOnLeftColumnInViewed: ThunkActionCreator =
  (req?) => async (dispatch, getState) => {
    const user = get(getState(), 'loginInfo.user', {});
    const tasks = [];
    if (user.amebaId) {
      tasks.push(dispatch(fetchBlogComment(req)));
    } else {
      tasks.push(dispatch(emptyBlogComment()));
    }
    await Promise.all(tasks);
  };

const FETCH_HOME_POPUP = 'HomeDesktop/FETCH_HOME_POPUP';
export const fetchPopup: ThunkActionCreator =
  () => async (dispatch, getState) => {
    const asId = getState().loginInfo.user.asId;
    const qpi = PAGE_IDS.home;

    try {
      const result = await getSpot(
        SPOT_HOME_DESKTOP_POPUP,
        {
          rver: '1',
          ms: 'pc_home_popup',
          qpi,
        },
        asId,
      );

      const popupItem = get(result, ['frames', '0', 'items', '0']);
      // popupがあるときだけ反映
      if (popupItem) {
        const popup: HomeDesktopPopup = {
          mqps: result.mqps,
          itemMqps: popupItem.meta.mqps,
          dspMqps: popupItem.meta.dspMqps,
          linkUrl: popupItem.link,
          imageUrl: popupItem.image.url,
          imageCaption: popupItem.caption,
        };
        dispatch({ type: FETCH_HOME_POPUP, payload: { popup } });
        await dispatch(trackPopup('inview'));
      }
    } catch {
      //
    }
  };

export const trackPopup: ThunkActionCreator =
  (action: 'tap' | 'inview') => async (_dispatch, getState) => {
    const asId = getState().loginInfo.user.asId;
    const { mqps, itemMqps, dspMqps } = getState().homeDesktop.popup;
    await measureSpot(
      SPOT_HOME_DESKTOP_POPUP,
      action,
      {
        qpi: PAGE_IDS.home,
        rver: '3',
      },
      mqps,
      itemMqps,
      dspMqps,
      asId,
    ).catch(() => {
      // noop
    });
  };

export const homeDesktop = handleActions<State, Payload>(
  {
    [INITIALIZE_STATE]() {
      return initialState;
    },
    [FETCH_INITIALIZE_DATA](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_LAZY_DATA](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_ACCESS_ANALYSIS](state, { payload }) {
      return {
        ...state,
        accessAnalysis: payload.accessAnalysis,
      };
    },
    [FETCH_NOTIFICATIONS_SUCCESS](state, { payload, error }) {
      if (error) {
        return state;
      }
      const notifications = payload.notifications.filter(
        (notification) => notification.unread,
      );
      return {
        ...state,
        notifications: notifications,
        notificationsCount: get(payload, 'summary.unread'),
      };
    },
    [FETCH_COIN_MODULE](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_MONEY_MODULE](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_BLOG_HISTORY](state, { payload, error }) {
      if (error) {
        return {
          ...state,
          blogHistory: [],
        };
      }
      return {
        ...state,
        ...payload,
      };
    },
    [EMPTY_BLOG_HISTORY](state) {
      return {
        ...state,
        blogHistory: [],
      };
    },
    [FETCH_MANGAFEED](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_MANGAFEED_SERIAL_START](state) {
      const mangafeed = { ...state.mangafeed };
      mangafeed.serial.status = ASYNC_STATUS.loading;
      return {
        ...state,
        mangafeed,
      };
    },
    [FETCH_MANGAFEED_SERIAL_COMPLETE](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_BLOG_HASHTAG](state, { payload, error }) {
      if (error) {
        return {
          ...state,
          blogHashtag: [],
        };
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_BLOG_COMMENT](state, { payload, error }) {
      if (error) {
        return {
          ...state,
          blogComment: [],
        };
      }
      return {
        ...state,
        ...payload,
      };
    },
    [EMPTY_BLOG_COMMENT](state) {
      return {
        ...state,
        blogComment: [],
      };
    },
    [FETCH_HOME_POPUP]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.popup = payload.popup;
      }),
  },
  initialState,
);

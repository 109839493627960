import { Request } from 'express';
import {
  Ameba20thInvitation,
  Ameba20thInvitationEventsDetail,
} from '../../../../types/type/ameba20th-invitation';
import SharedFetchr from '../../FetchrSharedFactory';
import {
  Ameba20thInvitationConfig,
  Ameba20thInvitationEventsDetailConfig,
} from './Ameba20thInvitationConfig';

/**
 * 招待状ページ情報を取得するAPI
 */
export function readAmeba20thInvitationTop(req?: Request) {
  const fetchr = new SharedFetchr({ req });
  return fetchr.read<Ameba20thInvitation>(Ameba20thInvitationConfig.name);
}

/**
 * イベント詳細ページ情報を取得するAPI
 */
export function readAmeba20thInvitationEventsDetail(
  contentId: string,
  req?: Request,
) {
  const fetchr = new SharedFetchr({ req });
  return fetchr.read<Ameba20thInvitationEventsDetail>(
    Ameba20thInvitationEventsDetailConfig.name,
    {
      contentId,
    },
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { ProgramCopyrights } from '../../../../contexts/Common/AbemaCopyrights';

interface Props {
  programs: ProgramCopyrights[];
}

function mapStateToProps(state) {
  return {
    programs: state.abemaCopyrights.programs,
  };
}

export const AbemaCopyrights: React.FC<Props> = ({ programs }) => {
  if (!programs.length) {
    return null;
  } else {
    return (
      <div className="AbemaCopyrights">
        <ul className="AbemaCopyrights_List clearfix">
          {programs.map((program) => (
            <li
              className="AbemaCopyrights_Item"
              key={`abema-copyright-${program.id}`}
            >
              <h5 className="AbemaCopyrights_Title">{program.title}</h5>
              <ul>
                {program.copyrights.map((copyright, i) => (
                  <li key={`abema-copyright-${program.id}-copyright-${i}`}>
                    {copyright}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
};

export default connect(mapStateToProps)(AbemaCopyrights);

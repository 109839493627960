import MineServiceIds from '../../../../../constants/serviceIds';

interface MenuSettingLink {
  title: string;
  contentId: string;
  serviceId?: string;
  linkUrl?: string; // external
  to?: string; // internal
}

export interface MenuSetting {
  title: string;
  links: MenuSettingLink[];
}

export const SETTING_USER: MenuSetting = {
  title: '登録情報',
  links: [
    {
      title: 'パスワードの変更',
      contentId: 'change-password',
      linkUrl: `${process.env.DOMAIN_AUTH_USER_URL}/settings/password`,
    },
    {
      title: 'メールアドレスの変更',
      contentId: 'change-email',
      linkUrl: `${process.env.DOMAIN_AUTH_USER_URL}/settings/email`,
    },
    {
      title: '2段階認証設定',
      contentId: 'change-twostepauth',
      linkUrl: `${process.env.DOMAIN_AUTH_USER_URL}/settings/two-step-verification`,
    },
  ],
};

export const SETTING_NOTIFICATION: MenuSetting = {
  title: '通知設定',
  links: [
    {
      title: 'メールマガジン',
      contentId: 'mail-magazine',
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/blog/bloginfoinput.do#infoMail`,
    },
    {
      title: 'フォロー通知',
      contentId: 'reader-notifications',
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/blog/bloginfoinput.do#infoMail`,
    },
    {
      title: 'ブログ更新通知',
      contentId: 'update-notifications',
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/blog/bloginfoinput.do#infoMail`,
    },
    {
      title: 'アメンバー記事更新通知',
      contentId: 'amember-article-update-notifications',
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/amember/follow.do`,
    },
    {
      title: 'アメンバー申請通知',
      contentId: 'amember-request-notifications',
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/amember/setting.do`,
    },
    {
      title: 'コメント通知',
      contentId: 'comment-notifications',
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/blog/bloginfoinput.do#infoMail`,
    },
    {
      title: 'あなたのマンガ新着設定',
      contentId: 'settings-mangafeed',
      to: '/settings/mangafeed',
    },
  ],
};

export const SETTING_AMEBLO: MenuSetting = {
  title: 'アメブロ設定',
  links: [
    {
      title: 'ブログ管理トップ',
      contentId: 'top',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/top.do`,
    },
  ],
};

export const SETTING_BLOG_EDIT: MenuSetting = {
  title: '投稿・編集',
  links: [
    {
      title: '記事の編集・削除',
      contentId: 'edit',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/entry/srventrylist.do`,
    },
    {
      title: '画像フォルダ',
      contentId: 'image-folder',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/upload/srvimagelist.do`,
    },
  ],
};

export const SETTING_BLOG: MenuSetting = {
  title: 'ブログの設定',
  links: [
    {
      title: 'タイトルの設定',
      contentId: 'title',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/blog/bloginfoinput.do`,
    },
    {
      title: '記事共有ボタンの設定',
      contentId: 'share',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/blog/bloginfoinput.do#articleFooterSet`,
    },
    {
      title: 'フォローの設定',
      contentId: 'reader-register',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/blog/bloginfoinput.do#readerSet`,
    },
    {
      title: '公式ジャンルの設定',
      contentId: 'ranking-genre',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/bloggenre/srvgenreinput.do`,
    },
  ],
};

export const SETTING_BLOG_REACTION: MenuSetting = {
  title: 'いいね！・コメント',
  links: [
    {
      title: 'いいね！履歴',
      contentId: 'like-history',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/iine/list.html`,
    },
    {
      title: 'コメント管理',
      contentId: 'comment-management',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/blog/bloginfoinput.do#commentSet`,
    },
  ],
};

export const SETTING_BLOG_DATA: MenuSetting = {
  title: 'ブログデータ',
  links: [
    {
      title: 'アクセス解析',
      contentId: 'access-analysis',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/analysis/analysis.do`,
    },
  ],
};

export const SETTING_BLOG_DESIGN: MenuSetting = {
  title: 'ブログデザイン',
  links: [
    {
      title: 'デザインの変更',
      contentId: 'change-design',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/skin/skinselecttop.do`,
    },
  ],
};

export const SETTING_BLOG_SIDEBAR: MenuSetting = {
  title: 'サイドバー',
  links: [
    {
      title: '設定・管理',
      contentId: 'sidebar-settings',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/sidebar/srvsectionupdateinput.do`,
    },
    {
      title: '配置設定',
      contentId: 'arrangement-settings',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/sidebar/srvsidebarupdateinput.do`,
    },
    {
      title: 'フリースペースの編集',
      contentId: 'change-free-space',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/profile/srvprofileupdateinput.do`,
    },
    {
      title: 'ブックマーク管理',
      contentId: 'bookmark',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/bookmark/bookmarkinput.do`,
    },
  ],
};

export const SETTING_BLOG_OTHERS: MenuSetting = {
  title: '各種設定',
  links: [
    {
      title: 'フォロワー管理',
      contentId: 'reader-management',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/reader/readerlist.do`,
    },
    {
      title: 'メッセージボード本文編集',
      contentId: 'message-board',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/message-board`,
    },
    {
      title: '外部サービス連携設定',
      contentId: 'link-outside-service',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/seo/webmastertoolinput.do`,
    },
  ],
};

export const SETTING_SERVICE: MenuSetting = {
  title: '各種情報の設定',
  links: [
    {
      title: 'プロフィール',
      contentId: 'profile-edit',
      serviceId: MineServiceIds.Profile,
      linkUrl: `${process.env.DOMAIN_PROFILE_AMEBA_URL}/edit`,
    },
    {
      title: 'アメンバー',
      contentId: 'amember-settings',
      serviceId: MineServiceIds.Blog,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/ucs/amember/setting.do`,
    },
    {
      title: 'メッセージ',
      contentId: 'settings',
      serviceId: MineServiceIds.Message,
      linkUrl: `${process.env.DOMAIN_MESSAGE_URL}/ucs/config/index`,
    },
  ],
};

export const SETTING_OTHERS: MenuSetting = {
  title: 'その他のサービス',
  links: [
    {
      title: 'Amebaヘルプ',
      contentId: 'help',
      serviceId: MineServiceIds.Ameba,
      linkUrl: `${process.env.DOMAIN_HELPS_URL}`,
    },
    {
      title: 'Amebaブログの有料プラン',
      contentId: 'premium',
      serviceId: MineServiceIds.Ameba,
      linkUrl: `${process.env.DOMAIN_BLOG_AMEBA_URL}/paid-plan/contract`,
    },
  ],
};

export const SETTING_NO_AMEBA_ID: MenuSetting = {
  title: '設定',
  links: [
    {
      title: 'プロフィール',
      contentId: 'profile-edit',
      serviceId: MineServiceIds.Profile,
      linkUrl: `${process.env.DOMAIN_PROFILE_AMEBA_URL}/edit`,
    },
    {
      title: 'パスワードの変更',
      contentId: 'change-password',
      linkUrl: `${process.env.DOMAIN_AUTH_USER_URL}/settings/password`,
    },
    {
      title: 'メールアドレスの変更',
      contentId: 'change-email',
      linkUrl: `${process.env.DOMAIN_AUTH_USER_URL}/settings/email`,
    },
    {
      title: 'あなたのマンガ新着設定',
      contentId: 'setting-mangafeed',
      to: '/setting/mangafeed',
    },
    {
      title: 'Amebaヘルプ',
      contentId: 'help',
      serviceId: MineServiceIds.Ameba,
      linkUrl: `${process.env.DOMAIN_HELPS_URL}`,
    },
  ],
};

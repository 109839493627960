import {
  AmebaDeliverySystemGetUserAsAsIdMailSettingResponse,
  AmebaDeliverySystemPostUserAsAsIdMailSettingResponse,
} from '../../../../types/apis/ameba-delivery-system';
import SharedFetchr from '../../FetchrSharedFactory';
import { notifications } from './NotificationsConfig';

/**
 * 通知設定の取得
 */
export function getNotificationsSettings(req?) {
  const fetchr = new SharedFetchr({ req });
  return fetchr.read<AmebaDeliverySystemGetUserAsAsIdMailSettingResponse>(
    notifications.name,
    req,
  );
}

/**
 * 通知設定の更新
 */
export function updateNotificationsSettings(
  req,
  body: {
    accept: boolean;
    group: string;
  },
) {
  const fetchr = new SharedFetchr({ req });
  return fetchr.update<AmebaDeliverySystemPostUserAsAsIdMailSettingResponse>(
    notifications.name,
    null,
    body,
  );
}

import { SnackBarType } from '../../../constants/snackBar';
import { createAction, handleActions } from '../../../lib/ReduxUtils';

export interface SnackBarMessage {
  message: string;
  type: SnackBarType;
  offset?: number;
}

interface State {
  messages: SnackBarMessage[];
}

interface Payload {
  message?: SnackBarMessage;
}

const initialState: State = {
  messages: [],
};

export const PUSH_MESSAGE = 'AppShellDesktop/SnackBar/PUSH_MESSAGE';
export const SHIFT_MESSAGE = 'AppShellDesktop/SnackBar/SHIFT_MESSAGE';

export const pushMessage = createAction<Payload>(
  PUSH_MESSAGE,
  (message: string, type: SnackBarType, offset?: number) => {
    return {
      message: {
        message,
        type,
        offset,
      },
    };
  },
);

export const shiftMessage = createAction<Payload>(SHIFT_MESSAGE, () => ({}));

export const snackBar = handleActions<State, Payload>(
  {
    [PUSH_MESSAGE](state, { payload }) {
      return {
        messages: state.messages.concat([payload.message]),
      };
    },
    [SHIFT_MESSAGE](state) {
      const messages = state.messages;
      messages.shift();
      return {
        messages,
      };
    },
  },
  initialState,
);

import React from 'react';
import cx from 'classnames';

import SpriteTypes from './SpriteTypes';
export { SpriteTypes };

interface Props {
  // SpriteTypesから指定
  iconClass: string;
  alt?: string;
  className?: string;
}

/**
 * SpriteSheetアイコンのコンポーネント
 */
const Sprite: React.FC<Props> = ({ iconClass, alt, className, ...props }) => {
  const localProps: any = Object.assign({}, props);
  // altがある時は画像として扱う
  if (alt) {
    localProps.role = 'img';
    localProps['aria-label'] = alt;
  }
  return <i className={cx('Sprite', className, iconClass)} {...localProps} />;
};

export default Sprite;

import React from 'react';
import { matchRoutes } from 'react-router-config';
import { LoadableFrame } from '../components/atoms/LoadableFrame';
import AmebaError from '../components/blocks/AmebaError';

const Loading = () => <LoadableFrame moduleType="page" />;

/**
 * CodeSplittingなComponentを提供するhoc
 */
interface State {
  error: Error;
  Component: any;
}
interface AsyncOptions {
  ErrorComponent?: React.FC<{
    error?: Error;
    statusCode?: number;
  }>;
  LoadingComponent?: React.FC;
}
export function getAsyncComponent<P>(
  getComponent: () => Promise<any>,
  options: AsyncOptions = {},
) {
  let Component = null;
  const ErrorComponent = options.ErrorComponent || AmebaError;
  const LoadingComponent = options.LoadingComponent || Loading;

  return class AsyncComponent extends React.Component<P, State> {
    constructor(props) {
      super(props);
      this.updateState = this.updateState.bind(this);
      this.state = {
        Component,
        error: null,
      };
    }

    componentDidMount() {
      AsyncComponent.load()
        .then(this.updateState)
        .catch((error) => {
          Component = null;
          this.setState({
            error,
            Component: null,
          });
        });
    }

    updateState() {
      if (this.state.Component !== Component) {
        this.setState({
          Component,
          error: null,
        });
      }
    }

    static load() {
      return getComponent().then((LoadedComponent) => {
        Component = LoadedComponent['default'] || LoadedComponent;
      });
    }

    renderError(error: Error) {
      return ErrorComponent ? <ErrorComponent error={error} /> : null;
    }

    renderLoading() {
      return LoadingComponent ? <LoadingComponent /> : null;
    }

    render() {
      const { Component, error } = this.state;
      if (error) {
        return this.renderError(error);
      }

      return Component ? <Component {...this.props} /> : this.renderLoading();
    }
  };
}

export function ensureReady(routeConfig, providedLocation) {
  const matches = matchRoutes(routeConfig, providedLocation);
  return Promise.all(
    matches.map((match) => {
      const component: any = match.route.component;
      if (component && component['load']) {
        return component.load();
      }
      return;
    }),
  );
}

import { Request } from 'express';
import { produce } from 'immer';
import {
  readAmeba20thInvitationEventsDetail,
  readAmeba20thInvitationTop,
} from '../../fetchr/services/Ameba20thInvitation/Ameba20thInvitation/Ameba20thInvitationShared';
import { handleActions, ThunkActionCreator } from '../../lib/ReduxUtils';
import {
  Ameba20thInvitation,
  Ameba20thInvitationEventsDetail,
} from '../../types/type/ameba20th-invitation';

export type Ameba20thInvitationState = {
  invitation: Ameba20thInvitation | null;
  eventMap: {
    [contentId: string]: Ameba20thInvitationEventsDetail;
  };
};

const initialState: Ameba20thInvitationState = {
  invitation: null,
  eventMap: {},
};

const FETCH_AMEBA20TH_INVITATION_TOP_REQUEST =
  'contexts/Ameba20thInvitation/FETCH_AMEBA20TH_INVITATION_TOP_REQUEST';
const FETCH_AMEBA20TH_INVITATION_EVENTS_DETAIL_REQUEST =
  'contexts/Ameba20thInvitation/FETCH_AMEBA20TH_INVITATION_EVENTS_DETAIL_REQUEST';

/**
 * 招待状ページ情報を取得する
 */
export const fetchAmeba20thInvitationTop: ThunkActionCreator =
  (req?: Request) => async (dispatch) => {
    const { data } = await readAmeba20thInvitationTop(req);
    dispatch({ type: FETCH_AMEBA20TH_INVITATION_TOP_REQUEST, payload: data });
  };

/**
 * イベント詳細ページ情報を取得する
 */
export const fetchAmeba20thInvitationEventsDetail: ThunkActionCreator =
  (contentId: string, req?: Request) => async (dispatch) => {
    const { data } = await readAmeba20thInvitationEventsDetail(contentId, req);
    dispatch({
      type: FETCH_AMEBA20TH_INVITATION_EVENTS_DETAIL_REQUEST,
      payload: data,
    });
  };

export const ameba20thInvitation = handleActions<
  Ameba20thInvitationState,
  unknown
>(
  {
    [FETCH_AMEBA20TH_INVITATION_TOP_REQUEST]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.invitation = payload as Ameba20thInvitation;
      }),
    [FETCH_AMEBA20TH_INVITATION_EVENTS_DETAIL_REQUEST]: (state, { payload }) =>
      produce(state, (draft) => {
        const data = payload as Ameba20thInvitationEventsDetail;
        draft.eventMap[data.content_id] = data;
      }),
  },
  initialState,
);

import { getAsyncComponent } from '../../../../lib/ComponentUtils';
import { fetchCoinSummary } from '../../../../contexts/Coin/Summary';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../../lib/ReactRouterUtils';
import { setTitle } from '../../../../contexts/AppShell/SpHeader';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('コイン', 'other'));
  },
  async clientLoader({ req, dispatch }) {
    const device = 'pc';
    await dispatch(fetchCoinSummary(req, device));
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./CoinSummaryDesktop'))),
);

import { Request } from 'express';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import { MangaSetting } from '../../../../services/dokusho/response/MangaService';
import {
  mangafeedSettings,
  MangafeedSettingsReadPayload,
} from './MangafeedConfig';

/**
 * マンガフィード設定情報取得
 * @param {e.Request} req
 * @return {Promise<{data: MangafeedSettingReadPayload}>
 */
export function readMangafeedSettingsData(req: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<MangafeedSettingsReadPayload>(mangafeedSettings.name);
}

/**
 * マンガフィード設定情報更新
 * @param {e.Request} req
 * @param {MangaSetting} setting
 * @return {Promise<{data: MangaSetting}>
 */
export function updateMangafeedSettingsData(
  req: Request,
  setting: MangaSetting,
) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.update<MangaSetting>(mangafeedSettings.name, {}, setting);
}

/**
 * createClassName
 * @export
 * @param {string} base
 * @param {T} obj
 * @returns {[K in keyof T]: string}
 *
 * @description
 * createClassName(BaseClass, KeyMapping)
 * KeyMappingからそれぞれのkeyをもとに
 * KeyMappingのそれぞれのvalueに BaseClass+Key のような形式で
 * CSSのClass名のマッピングObjectを作る
 */
export function createClassName<T>(
  base: string,
  obj: T,
): { [K in keyof T]: string } {
  const classNames = {};
  let key;
  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('Argument must be an object.');
  }
  for (key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    classNames[key] = `${base}${key}`;
  }
  return classNames as any;
}

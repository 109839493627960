import {
  fetchInitialData,
  fetchLazyData,
} from '../../../contexts/TopDesktop/Top';
import { getAsyncComponent } from '../../../lib/ComponentUtils';
import logger from '../../../lib/Logger';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';

export default composeRoute({
  async routerLoader({ req, dispatch }) {
    logger.debug('router loader at top desktop');
    await dispatch(fetchInitialData(req));
  },
  async clientLoader({ dispatch }) {
    await dispatch(fetchLazyData());
  },
})(
  composeRouterPermission(
    RouterPermission.UNAUTHENTICATED,
    '/home',
  )(getAsyncComponent(() => import('./TopDesktop'))),
);

import { produce } from 'immer';
import get from 'lodash/get';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';
import {
  readMangafeedSettingsData,
  updateMangafeedSettingsData,
} from '../../../fetchr/services/Settings/Mangafeed/MangafeedShared';
import { MangafeedSettingsReadPayload } from '../../../fetchr/services/Settings/Mangafeed/MangafeedConfig';
import { trackTap } from '../../../webApi/Tracker';

export interface State {
  settings: MangafeedSettingsReadPayload;
  isLoading: boolean;
}

type Payload = Partial<State>;

const initialState: State = {
  settings: null,
  isLoading: true,
};

export const FETCH_SETTINGS = 'Settings/SettingsMangafeed/FETCH_SETTINGS';
export const UPDATE_SETTINGS = 'Settings/SettingsMangafeed/UPDATE_SETTINGS';
export const FETCH_SETTINGS_FAILURE =
  'Settings/SettingsMangafeed/FETCH_SETTINGS_FAILURE';

/**
 * マンガフィード設定情報を取得する
 */
export const fetchSettings: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    try {
      const { data: settings } = await readMangafeedSettingsData(req);
      dispatch({ type: FETCH_SETTINGS, payload: { settings } });
    } catch {
      dispatch({ type: FETCH_SETTINGS_FAILURE });
    }
  };
};

/**
 * マンガフィード設定情報を更新する
 */
export const updateSettings: ThunkActionCreator = (
  req?,
  body?,
  clickTrack?,
) => {
  return async (dispatch) => {
    try {
      const { data: settings } = await updateMangafeedSettingsData(req, body);
      trackTap(clickTrack);
      dispatch({ type: UPDATE_SETTINGS, payload: { settings } });
    } catch {
      dispatch({ type: FETCH_SETTINGS_FAILURE });
    }
  };
};

export const settingsMangafeed = handleActions<State, Payload>(
  {
    [FETCH_SETTINGS]: (state, { payload }) =>
      produce(state, (draft) => {
        const settings = get(payload, 'settings');
        draft.settings = settings;
        draft.isLoading = false;
      }),
    [UPDATE_SETTINGS]: (state, { payload }) =>
      produce(state, (draft) => {
        const settings = get(payload, 'settings');
        draft.settings = settings;
        draft.isLoading = false;
      }),
    [FETCH_SETTINGS_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
  },
  initialState,
);

import React from 'react';
import { createAmebloUrl } from '../../../lib/UrlUtils';
import TextLink from '../../atoms/TextLink';
import { ERROR_MESSAGE } from './constants/ERROR_MESSAGE';

interface Props {
  error?: Error;
  statusCode?: number;
}

export default class AmebaError extends React.PureComponent<Props> {
  static get defaultProps() {
    return {
      statusCode: 500,
    };
  }

  render() {
    const message = ERROR_MESSAGE[this.props.statusCode] || ERROR_MESSAGE[500];
    return (
      <div className="AmebaError">
        <div className="AmebaError_Inner">
          <p className="AmebaError_Logo AmebaError_HideSp">
            <img
              src="/images/common/ameba_logo.svg"
              alt="Ameba"
              width="224"
              height="76"
            />
          </p>
          <h1 className="AmebaError_Headline">{message.title}</h1>
          <p className="AmebaError_Text">{message.text}</p>
          <p className="AmebaError_Text AmebaError_HideSp">
            サービスに関する情報は
            <TextLink
              className="AmebaError_Link"
              href={createAmebloUrl('staff')}
            >
              スタッフブログ
            </TextLink>
            、または、
            <TextLink
              className="AmebaError_Link"
              href={process.env.DOMAIN_HELPS_URL}
            >
              ヘルプ
            </TextLink>
            にてご確認ください。
          </p>
          <dl className="AmebaError_LinkList">
            <dt className="AmebaError_LinkList_Item">アメーバヘルプ</dt>
            <dd className="AmebaError_LinkList_Item">
              <TextLink
                className="AmebaError_Link"
                href={process.env.DOMAIN_HELPS_URL}
              >
                {process.env.DOMAIN_HELPS_URL}
              </TextLink>
            </dd>
            <dt className="AmebaError_LinkList_Item">アメーバトップ</dt>
            <dd className="AmebaError_LinkList_Item">
              <TextLink
                className="AmebaError_Link"
                href={process.env.DOMAIN_WWW_URL}
              >
                {process.env.DOMAIN_WWW_URL}
              </TextLink>
            </dd>
          </dl>
          {process.env.NODE_ENV !== 'production' && this.props.error && (
            <code>{this.props.error.stack}</code>
          )}
        </div>
      </div>
    );
  }
}

import FetchrSharedFactory from '../../FetchrSharedFactory';
import { copyrightSearchConfig, CopyrightSearchPayload } from './SearchConfig';

export function getCopyrightSearch(req?, word?, offset?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<CopyrightSearchPayload>(copyrightSearchConfig.name, {
    word,
    offset,
  });
}

import { Request } from 'express';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  accessAnalysisConfig,
  AccessAnalysisReadPayload,
  blogCommentConfig,
  BlogCommentReadPayload,
  blogHashTagConfig,
  BlogHashTagReadPayload,
  blogHistoryConfig,
  BlogHistoryReadPayload,
  checklistConfig,
  ChecklistReadPayload,
  coinModuleConfig,
  CoinModuleReadPayload,
  genreConfig,
  genreFollowConfig,
  GenreFollowCreatePayload,
  GenreFollowDeletePayload,
  GenreFollowReadPayload,
  GenreFollowUpdatePayload,
  GenreReadPayload,
  initialConfig,
  InitialReadPayload,
  lazyConfig,
  LazyReadPayload,
  mangafeedConfig,
  MangafeedReadPayload,
  mangafeedSerialConfig,
  MangafeedSerialReadPayload,
  moneyModuleConfig,
  MoneyModuleReadPayload,
} from './HomeDesktopConfig';

/**
 * ホーム初期ロード
 * @param {e.Request} req
 * @return {Promise<{data: InitialReadPayload}>}
 */
export function readInitialData(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<InitialReadPayload>(initialConfig.name);
}

/**
 * ホーム遅延読み込み
 * @param {e.Request} req
 * @return {Promise<{data: LazyReadPayload}>}
 */
export function readLazyData(req: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<LazyReadPayload>(lazyConfig.name);
}

/**
 * アクセス解析情報の取得
 * @param {e.Request} req
 * @return {Promise<{data: any}>}
 */
export function readAccessAnalysisData(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<AccessAnalysisReadPayload>(accessAnalysisConfig.name);
}

/**
 * コイン情報取得
 * @param {e.Request} req
 * @return {Promise<{data: CoinModuleReadPayload}>}
 */
export function readCoinModuleData(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<CoinModuleReadPayload>(coinModuleConfig.name);
}

/**
 * ドットマネー情報取得
 * @param {e.Request} req
 * @return {Promise<{data: MoneyModuleReadPayload}>}
 */
export function readMoneyModuleData(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<MoneyModuleReadPayload>(moneyModuleConfig.name);
}

/**
 * チェックリスト情報取得
 * @param {e.Request} req
 * @param {string} cursor
 * @param {number} limit
 * @return {Promise<{data: ChecklistReadPayload}>}
 */
export function readChecklistModuleData(
  req?: Request,
  cursor?: string,
  limit?: number,
) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<ChecklistReadPayload>(checklistConfig.name, {
    limit,
    cursor,
  });
}

/**
 * 最近見たブログ情報取得
 * @param {e.Request} req
 * @return {Promise<{data: BlogHistoryReadPayload}>}
 */
export function readBlogHistoryData(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<BlogHistoryReadPayload>(blogHistoryConfig.name);
}

/**
 * マンガフィード情報取得
 * @param {e.Request} req
 * @return {Promise<{data: MangafeedReadPayload}>}
 */
export function readMangafeedData(req: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<MangafeedReadPayload>(mangafeedConfig.name);
}

/**
 * マンガフィード情報取得
 * @param {e.Request} req
 * @param {number} offset
 * @return {Promise<{data: MangafeedReadPayload}>}
 */
export function readMangafeedSerialData(req: Request, offset: number) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<MangafeedSerialReadPayload>(mangafeedSerialConfig.name, {
    offset,
  });
}

/**
 * 人気のハッシュタグ情報取得
 * @param {e.Request} req
 * @return {Promise<{data: BlogHashTagReadPayload}>}
 */
export function readBlogHashTagData(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<BlogHashTagReadPayload>(blogHashTagConfig.name);
}

/**
 * コメントした記事情報取得API
 * @param {e.Request} req
 * @return {Promise<{data: BlogCommentReadPayload}>}
 */
export function readBlogCommentedData(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<BlogCommentReadPayload>(blogCommentConfig.name);
}

/**
 * すべてのジャンル取得
 * @param {e.Request} req
 * @return {Promise<{data: GenreReadPayload}>}
 */
export function readGenreData(req: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<GenreReadPayload>(genreConfig.name);
}

/**
 * フォロージャンル追加
 * @param {e.Request} req
 * @param {string} code
 * @return {Promise<{data: GenreFollowCreatePayload}>}
 */
export function createGenreFollowData(req: Request, code: string) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.create<GenreFollowCreatePayload>(genreFollowConfig.name, {
    code,
  });
}

/**
 * フォロージャンル取得
 * @param {e.Request} req
 * @return {Promise<{data: GenreFollowReadPayload}>}
 */
export function readGenreFollowData(req: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<GenreFollowReadPayload>(genreFollowConfig.name);
}

/**
 * フォロージャンル並び替え
 * @param {e.Request} req
 * @param {string[]} codes
 * @return {Promise<{data: GenreFollowUpdatePayload}>}
 */
export function updateGenreFollowData(req: Request, codes: string[]) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.update<GenreFollowUpdatePayload>(genreFollowConfig.name, {
    codes,
  });
}

/**
 * フォロージャンル削除
 * @param {e.Request} req
 * @param {string} code
 * @return {Promise<{data: GenreFollowDeletePayload}>}
 */
export function deleteGenreFollowData(req: Request, code: string) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.delete<GenreFollowDeletePayload>(genreFollowConfig.name, {
    code,
  });
}

/**
 * Clientサイド用Tracker
 */
import { parse, stringify } from 'query-string';
import * as MineService from '../fetchr/services/Common/Mine/MineShared';
import {
  TapContents,
  ClickContents,
  ViewSectionContents,
  InviewContents,
} from '../services/mine/response/Mine';

export function trackView() {
  return MineService.trackView(null, window.location.href);
  // return Promise.all([
  //   MineService.trackView(null, window.location.href),
  //   trackAction('page', undefined, undefined, true)
  // ]);
}

export function trackViewSection(contents: ViewSectionContents) {
  return MineService.trackViewSection(null, window.location.href, contents);
}

export function trackInviewSection(contents: InviewContents) {
  return MineService.trackInviewSection(null, window.location.href, contents);
}

export function trackTap(contents: TapContents) {
  return MineService.trackTap(null, window.location.href, contents);
}

export function trackClick(contents: ClickContents) {
  return MineService.trackClick(null, window.location.href, contents);
}

export function createLink(linkUrl: string, dataset?: TapContents) {
  if (!dataset || !dataset.section_id || !dataset.tap_id) {
    return linkUrl;
  } else {
    const frm_id: string =
      'v.' +
      [
        dataset.section_id,
        dataset.tap_id,
        dataset.category_id,
        dataset.service_id,
        dataset.order_id,
      ]
        .join('--')
        .replace(/([-]+)$/, '');
    const hashSplit: string[] = linkUrl.split('#');
    const hash: string = hashSplit[1];
    const querySplit: string[] = hashSplit[0].split('?');
    const _url: string = querySplit[0];
    const query: any = parse(querySplit[1]) || {};
    if (query.frm_id) {
      query.frm_id += ',' + frm_id;
    } else {
      query.frm_id = frm_id;
    }
    const search = stringify(query);
    return _url + '?' + search + (hash ? '#' + hash : '');
  }
}

import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../../lib/ReactRouterUtils';
import { getAsyncComponent } from '../../../../lib/ComponentUtils';
import { setTitle } from '../../../../contexts/AppShell/SpHeader';
import { fetchMyApps } from '../../../../contexts/MyApps/Top';
import { fetchApps } from '../../../../contexts/Apps/Top';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('マイアプリ', 'other'));
  },
  async clientLoader({ dispatch }) {
    await Promise.all([dispatch(fetchMyApps()), dispatch(fetchApps())]);
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./MyAppsTopDesktop'))),
);

import { ActionMap, PureFluxStandardAction } from './interface';

/**
 * ActionMapを渡してFSAベースのReducerを返す
 */
export function handleActions<State, Payload>(
  actionMap: ActionMap<State, Payload>,
  initialState,
) {
  return (
    state: State = initialState,
    action: PureFluxStandardAction<Payload>,
  ): State => {
    const _action = actionMap[action.type];
    if (_action) {
      return _action(state, action);
    }

    return state;
  };
}

import { History } from 'history';

const PUSH = 'PUSH';

export function manageScroll(history: History) {
  history.listen((location, action) => {
    // PUSHのときのみScrollTopする
    // POPのときはブラウザデフォルト
    if (action === PUSH) {
      window.scrollTo(0, 0);
    }
  });
}

/**
 * 特定の位置までY値をスクロールさせる
 * @param {HTMLElement} element - ターゲット
 * @param {number} to - 移動位置、上から何pxの位置(0以上)
 * @param {number} duration - 移動にかける秒数 デフォルト200
 */
export function scrollTo(element: HTMLElement, to: number, duration = 200) {
  if (duration <= 0) {
    return;
  }

  const distance = to - element.scrollTop;
  const move = (distance / duration) * 10;

  requestAnimationFrame(() => {
    element.scrollTop = element.scrollTop + move;

    if (element.scrollTop === to) {
      return;
    }

    scrollTo(element, to, duration - 10);
  });
}

/**
 * ページ自身をscrollToする場合にスクロールの対象となるエレメントを取得する
 * @returns {HTMLElement}
 */
export function getScrollPageElement(): HTMLElement {
  if (document.scrollingElement) {
    // scrollingElementはElement型のためダウンキャストをおこなう。(HTMLElementはElementを継承したインターフェース)
    return document.scrollingElement as HTMLElement;
  }
  if (navigator.userAgent.indexOf('WebKit') !== -1) {
    return document.body;
  }
  return document.documentElement;
}

import { createClassName } from '../../../lib/CssUtils';

export default createClassName('Sprite_', {
  FooterExternalLink: null,
  Service_755: null,
  Service_AWA: null,
  Service_Galsho: null,
  Service_Gang: null,
  Service_Gf: null,
  Service_Hitsuji: null,
  Service_Makuake: null,
  Service_Mogg: null,
  Service_Ownd: null,
  Service_Pigg: null,
  Service_Platina: null,
  Service_R25: null,
  Service_Satori: null,
  Service_SatoriTelephone: null,
  Service_Tuyagirl: null,
  Service_PiggParty: null,
  Service_PiggLifeApp: null,
  Service_Monoreco: null,
  Service_Terakoya: null,
  Service_LessMo: null,
  Service_Winticket: null,
  Service_Manekai: null,
  Service_Abema: null,
  Service_Bkrs2: null,
  Service_Chocofarm: null,
  Service_Vampire: null,
  Service_AmebaChoice: null,
});

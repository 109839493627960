import shallowequal from 'shallowequal';
import logger from '../Logger';
import { checkRouterPermission } from './RouterPermission';
import { trigger } from './trigger';
import { triggerNames } from './triggerNames';

/**
 * Navigation(History)に変更が起きたら実行されるブラウザ側のスクリプト
 * @param routes
 * @param nextLocation
 * @param locals
 * @param firstLoad
 * @returns {Promise<void>}
 */
async function shouldNavigationChange(
  routes,
  nextLocation,
  locals,
  firstLoad?: boolean,
) {
  const redirect = checkRouterPermission(
    routes,
    nextLocation,
    locals.isAuthenticated,
  );
  if (redirect) {
    window.location.replace(redirect);
    return;
  }

  if (!firstLoad) {
    try {
      await trigger(triggerNames.ROUTER, routes, nextLocation, locals);
      logger.debug(
        'Success to trigger navigation routerLoader.',
        nextLocation.pathname,
      );
    } catch (e) {
      logger.error(
        e,
        `Failed to trigger navigation routerLoader. ${nextLocation.pathname}`,
      );
    }
  }

  try {
    await trigger(triggerNames.CLIENT, routes, nextLocation, locals);
    logger.debug(
      'Success to trigger navigation clientLoader.',
      nextLocation.pathname,
    );
  } catch (e) {
    logger.error(
      e,
      `Failed to trigger navigation clientLoader. ${nextLocation.pathname}`,
    );
  }
}

/**
 * ComponentClassをブラウザ側でのSPAアプリケーション基盤コンポーネント化
 * composeRouteで定義したrouteLoaderを遷移タイミングでcallする
 * @see {@link ./composeRoute.ts}
 * ```tsx
 * connect()( // reduxと連携したい時はconnectしてpropsにdispatchを含めておく
 *   composeContainer()(
 *     class AppShell extends React.Component {}
 *   )
 * )
 * ```
 */
export const composeContainer = () => (ComposedComponent) => {
  // AppShellがDidMountされるのは最初だけなので
  // 一番最初のLoad時にのみ呼ばれる
  ComposedComponent.prototype.componentDidMount = function () {
    shouldNavigationChange(
      [this.props.route],
      this.props.location,
      this.props,
      true,
    );
  };
  ComposedComponent.prototype.componentDidUpdate = function (prevProps) {
    const navigated = !shallowequal(prevProps.location, this.props.location);
    if (navigated) {
      // Loader側の引数はlocalsとして渡す
      // composeContainerではAppShellのpropsが渡されます
      // reduxと連携したい時はAppShellをconnectして上げる必要があります
      shouldNavigationChange(
        [this.props.route],
        this.props.location,
        this.props,
      );
    }
  };
  return ComposedComponent;
};

import { LOCATION_CHANGE } from 'connected-react-router';
import { trackView } from '../../webApi/Tracker';

/**
 * FluxStandardActionに基づいた非同期payloadをサポートするmiddlewareの作成
 * @export
 */
export function asyncMiddleware({ dispatch }) {
  return (next) =>
    (action: any = {}) => {
      // そもそもFSA出ない時は普通に通す
      if (!action || !('payload' in action)) {
        return next(action);
      }

      // FSAの時は各々返却
      if (action.payload && typeof action.payload.then === 'function') {
        // payloadが非同期のときは解決時にdispatch
        return action.payload.then(
          (payload) => dispatch({ ...action, payload }),
          (error) => {
            dispatch({ ...action, payload: error, error: true });
            return Promise.reject(error);
          },
        );
      } else {
        // payloadが同期のときはそのままdispatch
        return next(action);
      }
    };
}

export function pageViewMiddleware({ dispatch }) {
  return (next) =>
    (action: any = {}) => {
      if (action.type === LOCATION_CHANGE) {
        setTimeout(() => {
          // track
          trackView();
        }, 0);
      }

      return next(action);
    };
}

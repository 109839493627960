import { createClassName } from '../../../../lib/CssUtils';

/**
 * PFIconClasses
 * プラットフォームオリジナルfont
 * @see { https://wiki.ca-tools.org/pages/viewpage.action?pageId=10327223 }
 * @see { https://wiki.ca-tools.org/pages/viewpage.action?pageId=48532569 }
 */
const PFIconClasses = createClassName('PFIcon PFIcon_', {
  /* Months */
  Month1: null,
  Month2: null,
  Month3: null,
  Month4: null,
  Month5: null,
  Month6: null,
  Month7: null,
  Month8: null,
  Month9: null,
  Month10: null,
  Month11: null,
  Month12: null,

  /* Members */
  No0: null,
  No1: null,
  No2: null,
  No3: null,
  No4: null,
  No5: null,
  No6: null,
  No7: null,
  No8: null,
  No9: null,
});
export default PFIconClasses;

import React from 'react';
import cx from 'classnames';

import PFIconClasses from './PFIcon/PFIconClasses';
import AmebaIconClasses from './AmebaIcon/AmebaIconClasses';

export const IconTypes = {
  ...PFIconClasses,
  ...AmebaIconClasses,
};

export interface Props {
  iconClass: string;
  className?: string;
}

export const Icon: React.FC<Props> = ({ iconClass, className, ...props }) => (
  <i
    className={cx('Icon', iconClass, className)}
    aria-hidden
    role="presentation"
    {...props}
  />
);

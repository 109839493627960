import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  TapContents,
  ClickContents,
  ViewSectionContents,
  InviewContents,
} from '../../../../services/mine/response/Mine';
import { mineConfig } from './MineConfig';

/**
 * View(PV)ログ送信
 * @param req
 * @param {string} url
 * @return {Promise<{data: boolean}>}
 */
export function trackView(req, url: string) {
  const fetchr = new FetchrSharedFactory({ req });
  const params = {
    url,
    action: 'view',
  };
  return fetchr.read<boolean>(mineConfig.name, params);
}

/**
 * ViewSection(Impression)ログ送信
 * @param req
 * @param {string} url
 * @param {ViewSectionContents} contents
 * @returns {Promise<{data: boolean}>}
 */
export function trackViewSection(
  req,
  url: string,
  contents: ViewSectionContents,
) {
  const fetchr = new FetchrSharedFactory({ req });
  const params = {
    url,
    action: 'view_section',
    contents,
  };
  return fetchr.read<boolean>(mineConfig.name, params);
}

/**
 * InviewSection(Impression)ログ送信
 * @param req
 * @param {string} url
 * @param {InviewContents} contents
 * @returns {Promise<{data: boolean}>}
 */
export function trackInviewSection(req, url: string, contents: InviewContents) {
  const fetchr = new FetchrSharedFactory({ req });
  const params = {
    url,
    action: 'inview',
    contents,
  };
  return fetchr.read<boolean>(mineConfig.name, params);
}

/**
 * Tap(ユーザーアクション)ログ送信
 * @param req
 * @param {string} url
 * @param {TapContents} contents
 * @returns {Promise<{data: boolean}>}
 */
export function trackTap(req, url: string, contents: TapContents) {
  const fetchr = new FetchrSharedFactory({ req });
  const params = {
    url,
    action: 'tap',
    contents,
  };
  return fetchr.read<boolean>(mineConfig.name, params);
}

/**
 * Click(ユーザーアクション)ログ送信
 * @param req
 * @param {string} url
 * @param {ClickContents} contents
 * @returns {Promise<{data: boolean}>}
 */
export function trackClick(req, url: string, contents: ClickContents) {
  const fetchr = new FetchrSharedFactory({ req });
  const params = {
    url,
    action: 'click',
    contents,
  };
  return fetchr.read<boolean>(mineConfig.name, params);
}

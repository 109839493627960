import React from 'react';

/**
 * 非会員TOP下部 SNSシェアー訴求
 */
export class FooterSns extends React.PureComponent {
  render() {
    return (
      <div className="FooterSns clearfix">
        <ul className="FooterSns_Left clearfix">
          <li className="FooterSns_Item">
            <a
              className="twitter-follow-button"
              href="https://twitter.com/ameba_official"
              data-show-screen-name="false"
              data-show-count="false"
            >
              Follow @TwitterDev
            </a>
          </li>
          <li className="FooterSns_Item">
            <div
              className="fb-like"
              data-href="https://www.facebook.com/Ameba.official/"
              data-layout="button"
              data-action="like"
              data-size="small"
              data-show-faces="false"
              data-share="false"
            />
          </li>
        </ul>
        <ul className="FooterSns_Right">
          <li className="FooterSns_Item -jasraq">
            <img
              src="https://c.stat100.ameba.jp/common_style/img/ameba/common/bnr_jasrac.png"
              alt="Licensed by JASRAC JASRAC 許諾番号：9008000020Y45123、9008000019Y38200、9008000018Y45037"
              width={180}
              height={65}
            />
          </li>
        </ul>
      </div>
    );
  }
}

import { createAction, handleActions } from '../../../lib/ReduxUtils';
import { UserProfileObject } from '../../../services/userProfile/entity/UserProfile';

/**
 * ログインユーザー情報
 */
export interface LoginInfo {
  isAuthenticated: boolean;
  user?: UserProfileObject;
}

/**
 * デフォは未ログイン
 */
export const initialState: LoginInfo = {
  isAuthenticated: false,
};

export const UPDATE_LOGIN_INFO = 'Common/LoginInfo/UPDATE_USER_INFO';
export const LOGOUT = 'Common/LoginInfo/LOGOUT';

/**
 * ログインユーザー情報更新の窓口
 */
export const updateLoginInfo = createAction<LoginInfo>(
  UPDATE_LOGIN_INFO,
  (info: LoginInfo) => {
    return info;
  },
);

export const logout = createAction<LoginInfo>(LOGOUT, () => {
  return initialState;
});

/**
 * ログインユーザー情報はこちらから取得
 */
export const loginInfo = handleActions<LoginInfo, LoginInfo>(
  {
    [UPDATE_LOGIN_INFO](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [LOGOUT](state, { payload }) {
      return payload;
    },
  },
  initialState,
);

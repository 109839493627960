import { getAsyncComponent } from '../../../lib/ComponentUtils';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';
import { fetchSettings } from '../../../contexts/Settings/SettingsMangafeed';

export default composeRoute({
  async clientLoader({ dispatch }) {
    await dispatch(fetchSettings());
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./SettingsMangafeedDesktop'))),
);

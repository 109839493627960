import { Request } from 'express';
import { BlogListItemSchema } from '../../../services/blog/response/BlogCommon';
import { ChecklistModuleEntity } from '../../../services/blog/entity/ChecklistModuleEntity';
import { readChecklistModuleData } from '../../../fetchr/services/HomeDesktop/HomeDesktop/HomeDesktopShared';
import {
  handleActions,
  ThunkActionCreator,
  AsyncState,
  ASYNC_STATUS,
  createAction,
} from '../../../lib/ReduxUtils';

export interface ChecklistState extends ChecklistModuleEntity, AsyncState {}

interface Payload {
  recommends?: BlogListItemSchema[];
  checklist?: BlogListItemSchema[];
  nextCursor?: string;
}

export const initialState: ChecklistState = {
  recommends: [],
  checklist: [],
  nextCursor: null,
  status: ASYNC_STATUS.none,
};

export const INITIALIZE = 'HomeDesktop/HomeChecklist/INITIALIZE';
export const FETCH_CHECKLIST_REQUEST = 'HomeDesktop/HomeChecklist/REQUEST';
export const FETCH_CHECKLIST_SUCCESS = 'HomeDesktop/HomeChecklist/SUCCESS';
export const FETCH_CHECKLIST_FAILURE = 'HomeDesktop/HomeChecklist/FAILURE';

export const initializeChecklist = createAction<Payload>(
  INITIALIZE,
  () => ({}),
);

export const fetchChecklistRequest = createAction<Payload>(
  FETCH_CHECKLIST_REQUEST,
  () => ({}),
);

export const fetchChecklistSuccess = createAction<Payload>(
  FETCH_CHECKLIST_SUCCESS,
  (data: ChecklistModuleEntity) => {
    return {
      checklist: data.checklist || null,
      recommends: data.recommends || null,
      nextCursor: data.nextCursor || null,
    };
  },
);

export const fetchChecklistFailure = createAction<Payload>(
  FETCH_CHECKLIST_FAILURE,
  () => ({}),
);

export const fetchChecklist: ThunkActionCreator =
  (req?: Request, cursor?: string) => async (dispatch, getState) => {
    const state = getState();
    // abort if loading
    if (state.homeChecklist.status === ASYNC_STATUS.loading) {
      return;
    }

    // if no cursor selected, initialize checklist state
    if (!cursor) {
      dispatch(initializeChecklist());
    }

    // fetch start
    dispatch(fetchChecklistRequest());

    try {
      const { data } = await readChecklistModuleData(req, cursor);
      dispatch(fetchChecklistSuccess(data));
    } catch (error) {
      dispatch(fetchChecklistFailure());
    }
  };

export const homeChecklist = handleActions<ChecklistState, Payload>(
  {
    [INITIALIZE]() {
      return initialState;
    },
    [FETCH_CHECKLIST_REQUEST](state) {
      return {
        ...state,
        status: ASYNC_STATUS.loading,
      };
    },
    [FETCH_CHECKLIST_SUCCESS](state, { payload }) {
      const newState = { ...state };
      if (payload.recommends) {
        newState.recommends = payload.recommends;
      } else {
        newState.checklist = (newState.checklist || []).concat(
          payload.checklist || [],
        );
        newState.nextCursor = payload.nextCursor;
      }
      newState.status = ASYNC_STATUS.success;
      return newState;
    },
    [FETCH_CHECKLIST_FAILURE](state) {
      const newState = { ...state };
      newState.status = ASYNC_STATUS.failure;
      return newState;
    },
  },
  initialState,
);

import { createAction, handleActions } from '../../../lib/ReduxUtils';

export interface StateAndPayload {
  isActive: boolean;
}

export const TOGGLE = 'context/AppShellDesktop/LoginDialog/TOGGLE';

export const toggle = createAction<StateAndPayload>(
  TOGGLE,
  (isActive?: boolean) => {
    return {
      isActive,
    };
  },
);

export const loginDialog = handleActions<StateAndPayload, StateAndPayload>(
  {
    [TOGGLE](state, { payload }) {
      let isActive;
      if (typeof payload.isActive !== 'boolean') {
        isActive = !state.isActive;
      } else {
        isActive = payload.isActive;
      }
      return {
        isActive,
      };
    },
  },
  { isActive: false },
);

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { ameba20thInvitation } from './Ameba20thInvitation';
import { loginInfo } from './Common/LoginInfo';
import { abemaCopyrights } from './Common/AbemaCopyrights';
import { userAgent } from './Common/UserAgent';

export default function createReducer(history: any, reducers: any = {}) {
  return combineReducers({
    router: connectRouter(history),
    // TODO: イベント終了時に削除する
    ameba20thInvitation,
    abemaCopyrights,
    loginInfo,
    userAgent,
    ...reducers,
  });
}

import React from 'react';
import get from 'lodash/get';
import { connect, DispatchProp } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { toggle } from '../../../contexts/AppShellDesktop/LoginDialog';
import { composeContainer } from '../../../lib/ReactRouterUtils';
import AbemaCopyrights from '../../organizers/AppShellDesktop/AbemaCopyrights';
import { FooterAmeba } from '../../organizers/AppShellDesktop/FooterAmeba';
import JumpTop from '../../organizers/AppShellDesktop/JumpTop';
import { LoginDialog } from '../../organizers/AppShellDesktop/LoginDialog';
import PcFooter from '../../organizers/AppShellDesktop/PcFooter';
import PcHeader from '../../organizers/AppShellDesktop/PcHeader';
import SnackBarContainer from '../../organizers/AppShellDesktop/SnackBarContainer';

interface Props {
  isLoginDialogActive: boolean;
  isAuthenticated: boolean;
}

const mapStateToProps = (state) => {
  return {
    isLoginDialogActive: get(state, 'loginDialog.isActive'),
    isAuthenticated: get(state, 'loginInfo.isAuthenticated'),
  };
};

class AppShellDesktop extends React.Component<
  Props & RouteConfigComponentProps & DispatchProp
> {
  handleToggleDialog = (bool: boolean) => {
    this.props.dispatch(toggle(bool));
  };

  render() {
    const { route, isAuthenticated, isLoginDialogActive } = this.props;
    return (
      <div id="AppShellDesktop">
        <Helmet
          defaultTitle={process.env.TITLE}
          titleTemplate="%s | Ameba（アメーバ）"
        />
        {/* グローバルヘッダー */}
        <PcHeader />
        {/* Topへもどるボタン */}
        <JumpTop />
        {/* メインのコンテンツ */}
        <main>{renderRoutes(route.routes)}</main>
        <footer>
          {/* Abemaのコピーライト */}
          <AbemaCopyrights />
          {/* Ameba関連導線等 */}
          <FooterAmeba />
          {/* グローバルフッター */}
          <PcFooter />
        </footer>
        {/* モーダル */}
        <LoginDialog
          isActive={isLoginDialogActive}
          isAuthenticated={isAuthenticated}
          onClickToggle={this.handleToggleDialog}
        />
        {/* SnackBar */}
        <SnackBarContainer />
      </div>
    );
  }
}

export default connect(mapStateToProps)(composeContainer()(AppShellDesktop));

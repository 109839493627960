import { produce } from 'immer';
import get from 'lodash/get';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';
import { getConnectSettings } from '../../../fetchr/services/Settings/Connect/ConnectShared';
import { AmebaConnectApiUserinfoPayload } from '../../../services/amebaConnect/response/AmebaConnectApiService';

interface State {
  connect: AmebaConnectApiUserinfoPayload;
}

interface Payload {
  connect?: AmebaConnectApiUserinfoPayload;
}

const initialState = {
  connect: null,
};

export const FETCH_CONNECT = 'contexts/Settings/Connect/FETCH_CONNECT';
export const FETCH_CONNECT_FAILURE =
  'contexts/Settings/Connect/FETCH_CONNECT_FAILURE';

export const fetchConnect: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    try {
      const { data } = await getConnectSettings(req);
      dispatch({
        type: FETCH_CONNECT,
        payload: { connect: data },
      });
    } catch {
      dispatch({ type: FETCH_CONNECT_FAILURE });
    }
  };
};

export const settingsConnect = handleActions<State, Payload>(
  {
    [FETCH_CONNECT]: (state, { payload }) =>
      produce(state, (draft) => {
        const connect = get(payload, 'connect');
        draft.connect = connect;
      }),
  },
  initialState,
);

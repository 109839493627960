const ServiceIds = {
  Ameba: 'ameba',
  Abema: 'abema',
  Blog: 'blog',
  Pigg: 'pigg',
  Ownd: 'ownd',
  PiggLife: 'pigg-life',
  PiggWorld: 'pigg-world',
  PiggBrave: 'pigg-brave',
  PiggCafe: 'pigg-cafe',
  PiggIsland: 'pigg-island',
  Mogg: 'mogg',
  PiggParty: 'pigg-party',
  PiggLifeApp: 'pigg-life-app',
  R25: 'r25',
  Nanagogo: 'nanagogo',
  Awa: 'awa',
  Makuake: 'makuake',
  Vilect: 'vilect',
  Dokusho: 'dokusho',
  Dotmoney: 'dotmoney',
  TsuyaGirl: 'tsuya-girl',
  PlatinumGirl: 'platinum-girl',
  Garusho: 'garusho',
  Hitsuji: 'hitsuji',
  Gf: 'gf',
  Gang: 'gang',
  Satori: 'satori',
  SatoriTelephone: 'satori-telephone',
  Game: 'game',
  Profile: 'profile',
  Message: 'message',
  Notification: 'notification',
  Terakoyaplus: 'terakoya',
  Datsumo: 'datsumo',
  Winticket: 'winticket',
  Manekai: 'manekai',
  AmebaNews: 'amebanews',
  Bkrs2: 'bkrs2',
  Chocofarm: 'chocofarm',
  Vampire: 'vampire',
  AmebaChoice: 'ameba-choice',
};

export default ServiceIds;

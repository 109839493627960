interface AmebaLinkProps {
  title: string;
  key: string;
  linkUrl: string;
}

export const AMEBA_RULES: AmebaLinkProps = {
  title: '利用規約',
  key: 'terms',
  linkUrl: `${process.env.DOMAIN_HELPS_URL}/rules/`,
};

export const ACCESS_DATA: AmebaLinkProps = {
  title: 'アクセスデータの利用',
  key: 'access-data',
  linkUrl: `${process.env.DOMAIN_HELPS_URL}/rules/ameba_1.html`,
};

export const AMEBA_SAFE: AmebaLinkProps = {
  title: '安心・安全なご利用のために',
  key: 'cs-trouble',
  linkUrl: `${process.env.DOMAIN_HELPS_URL}/index.html#safe`,
};

export const AMEBA_INQUIRY: AmebaLinkProps = {
  title: 'お問い合わせ',
  key: 'cs-inquiry',
  linkUrl: `${process.env.DOMAIN_HELPS_URL}/inquiry.html`,
};

export const AMEBA_HELP: AmebaLinkProps = {
  title: 'ヘルプ',
  key: 'cs-help',
  linkUrl: process.env.DOMAIN_HELPS_URL,
};

export const COMPANY_OVERVIEW: AmebaLinkProps = {
  title: '企業概要',
  key: 'company-overview',
  linkUrl: 'https://www.cyberagent.co.jp/',
};

export const AMEBA_ADVERTISE: AmebaLinkProps = {
  title: '広告掲載について',
  key: 'ameba-advertise',
  linkUrl: 'https://about.ameba.jp/media-guide/',
};

export const AMEBA_PROFILE: AmebaLinkProps = {
  title: 'Amebaプロフィール',
  key: 'ameba-profile',
  linkUrl: `${process.env.DOMAIN_WWW_URL}/profile/`,
};

export const PRIVACY_POLICY: AmebaLinkProps = {
  title: 'プライバシーポリシー',
  key: 'privacy-policy',
  linkUrl: 'https://helps.ameba.jp/rules/privacy.html',
};

export const INVESTMENT_POLICY: AmebaLinkProps = {
  title: '健全化に資する運用方針',
  key: 'investment-policy',
  linkUrl: 'http://helps.ameba.jp/trouble/post_636.html',
};

export const WHATS_AMEBA: AmebaLinkProps = {
  title: 'Amebaってなに',
  key: 'whats-ameba',
  linkUrl: process.env.DOMAIN_AMEBLO_URL,
};

export const IMPORT_AMEBA: AmebaLinkProps = {
  title: 'Amebaへ引っ越し',
  key: 'import-ameba',
  linkUrl: 'http://blog.ameba.jp/import/index.html',
};

export const OFFICIAL_BLOG: AmebaLinkProps = {
  title: '芸能人・有名人ブログ',
  key: 'official-blog',
  linkUrl: process.env.DOMAIN_OFFICIAL_URL,
};

export const AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS: AmebaLinkProps = {
  title: '特定商取引法に基づく表記',
  key: 'specified-commercial-transactions',
  linkUrl: `${process.env.DOMAIN_HELPS_URL}/rules/post_855.html`,
};

import { FluxStandardAction } from './interface';

/**
 * 何もしないAction
 */
export const NULL_ACTION = 'lib/ReduxUtils/NULL_ACTION';

/**
 * ActionCreatorを作るActionCreatorCreator
 * actionMethodは必ずpayloadを返すGenericsも同様
 */
export function createAction<Payload>(
  namespace: string,
  actionMethod: (...args) => Payload | Promise<Payload>,
  allowMultipleCall = false,
) {
  let pending = false;
  return (...args): FluxStandardAction<Payload> => {
    // pending状態の時はNULL_ACTION発行
    if (pending) {
      return {
        type: NULL_ACTION,
        payload: null,
      };
    }

    // 多重呼び出し禁止時にはpending状態にしない
    // TODO: SERVERの時は無視
    if (process.env.BROWSER_ENV && !allowMultipleCall) {
      pending = true;
    }

    // payloadの作成
    const payload = actionMethod(...args);

    // payloadがPromiseな返り値の場合解決時にpending解除
    if (typeof (payload as any).then === 'function') {
      (payload as any).then(
        (result) => {
          pending = false;
          return result;
        },
        (error) => {
          pending = false;
          throw error;
        },
      );
    } else {
      pending = false;
    }

    // FluxStandardActionを返す
    return {
      type: namespace,
      payload,
    };
  };
}

import { produce } from 'immer';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import { readTopData } from '../../../fetchr/services/Game/Top/TopShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';
import { pickRandom } from '../../../lib/ArrayUtils';

import { ApplicationServicePayload } from '../../../services/appmng/response/AppmngApiService';
import { AmebaConnectApiApplicationsPayload } from '../../../services/amebaConnect/response/AmebaConnectApiService';
import { AdcrossAdSchema } from '../../../services/adcross/response/AdcrossService';

interface State {
  gameUsedList: AmebaConnectApiApplicationsPayload[];
  gameApps: ApplicationServicePayload[];
  piggImage: string;
  connectApplications: AmebaConnectApiApplicationsPayload[];
  adcross: AdcrossAdSchema;
  isUsedPigg: boolean;
  isLoading: boolean;
}

interface Payload {
  gameUsedList: AmebaConnectApiApplicationsPayload[];
  gameApps: ApplicationServicePayload[];
  piggImage: string;
  connectApplications: AmebaConnectApiApplicationsPayload[];
  adcross: AdcrossAdSchema[];
}

const initialState = {
  gameUsedList: [],
  gameApps: [],
  piggImage: null,
  connectApplications: [],
  adcross: {},
  isUsedPigg: false,
  isLoading: true,
};

export const FETCH_GAME_REQUEST = 'contexts/Game/FETCH_GAME_REQUEST';
export const FETCH_GAME_SUCCESS = 'contexts/Game/FETCH_GAME_SUCCESS';
export const FETCH_GAME_FAILURE = 'contexts/Game/FETCH_GAME_FAILURE';

export const fetchGameTop: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_GAME_REQUEST });
    try {
      const { data } = await readTopData(req);
      dispatch({ type: FETCH_GAME_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_GAME_FAILURE });
    }
  };
};

export const gameTop = handleActions<State, Payload>(
  {
    [FETCH_GAME_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_GAME_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const applications = get(payload, 'applications', []);
        const connectApplications = get(payload, 'connectApplications');

        // カテゴリが 'game' のアプリのみを絞り込み
        const gameApps = applications.filter((app) => {
          return app.category === 'game';
        });

        // グループが 'pigg' のアプリのみを絞り込み
        const groupPigg = applications.filter((app) => {
          return app.group === 'pigg';
        });

        // 表示対象をdisplay_orderの降順に並べ、表示件数を5件に絞る
        draft.gameApps = orderBy(gameApps, 'display_order', 'desc').slice(0, 5);

        if (connectApplications && connectApplications.length > 0) {
          // 認可アプリ（connectApplications）と同じclient_idのアプリを絞り込み
          const filteredUsedApplication = connectApplications.filter((app) => {
            return gameApps.find(
              (connectApp) => connectApp.client_id === app.client_id,
            );
          });

          // 表示件数を4件に絞る
          draft.gameUsedList = filteredUsedApplication.slice(0, 4);

          // 認可アプリ（connectApplications）と同じclient_idのピグアプリを絞り込み
          const filteredPiggApplication = groupPigg.filter((app) => {
            return connectApplications.find(
              (connectApp) => connectApp.client_id === app.client_id,
            );
          });
          draft.isUsedPigg = filteredPiggApplication.length > 0;
        }

        draft.piggImage = get(payload, 'piggImage');
        draft.adcross = pickRandom(get(payload, 'adcross'));
        draft.isLoading = false;
      }),
    [FETCH_GAME_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
  },
  initialState,
);

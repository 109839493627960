import { fetchAmeba20thInvitationTop } from '../../../../contexts/Ameba20thInvitation';
import { getAsyncComponent } from '../../../../lib/ComponentUtils';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../../lib/ReactRouterUtils';
import { createLoginUrl } from '../../../../lib/UrlUtils';

export default composeRoute({
  async routerLoader({ req, dispatch }) {
    await dispatch(fetchAmeba20thInvitationTop(req));
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    createLoginUrl('/ameba20th-invitation'),
    true,
  )(getAsyncComponent(() => import('./Ameba20thInvitationTop'))),
);

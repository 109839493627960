import { produce } from 'immer';
import get from 'lodash/get';
import { getTradelaw } from '../../fetchr/services/Tradelaw/Tradelaw/TradelawShared';
import { handleActions, ThunkActionCreator } from '../../lib/ReduxUtils';
import { TradelawDataPayload } from '../../services/appmng/entity/Tradelaw';

interface State {
  tradelawData: TradelawDataPayload;
  isLoading: boolean;
}

interface Payload {
  tradelawData?: TradelawDataPayload;
}

const initialState = {
  tradelawData: null,
  isLoading: true,
};

// TODO: 更新のACTIONを作成
export const FETCH_TRADELAW_REQUEST =
  'contexts/Tradelaw/FETCH_TRADELAW_REQUEST';
export const FETCH_TRADELAW_SUCCESS =
  'contexts/Tradelaw/FETCH_TRADELAW_SUCCESS';
export const FETCH_TRADELAW_FAILURE =
  'contexts/Tradelaw/FETCH_TRADELAW_FAILURE';

export const fetchTradelaw: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_TRADELAW_REQUEST });
    try {
      let tradelaw = {};
      const { data } = await getTradelaw(req);

      if (data) {
        const application = get(data, 'tradelawApp') || null;
        const developer = get(data, 'tradelawDeveloper') || null;

        tradelaw = {
          id: application?.client_id,
          name: developer?.name,
          owner: {
            name: developer?.representative,
          },
          address: developer?.address,
          aboutInquiry: developer?.about_inquiry,
          phoneNumber: developer?.phone_number,
          compatibleModel: application?.compatible_model,
        };
      }

      dispatch({ type: FETCH_TRADELAW_SUCCESS, payload: { tradelaw } });
    } catch {
      dispatch({ type: FETCH_TRADELAW_FAILURE });
    }
  };
};

export const tradelaw = handleActions<State, Payload>(
  {
    [FETCH_TRADELAW_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_TRADELAW_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const tradelaw = get(payload, 'tradelaw');
        draft.tradelawData = tradelaw;
        draft.isLoading = false;
      }),
    [FETCH_TRADELAW_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
  },
  initialState,
);

import { InstantStorage, localStorage } from './Storage';

/**
 * クライアントサイドストレージ
 */
export class ClientStorage {
  cache: { [x: string]: any };
  storage: InstantStorage | Storage;

  constructor(public namespace: string) {
    this.storage = localStorage;
    let cache;
    try {
      cache = JSON.parse(this.storage.getItem(this.namespace) || '{}');
    } catch {
      cache = {};
    }
    this.cache = cache;
  }

  getItem(name: string) {
    return this.cache[name];
  }

  setItem(name: string, value: any) {
    this.cache[name] = value;
    this.save();
  }

  removeItem(name: string) {
    delete this.cache[name];
    this.save();
  }

  save() {
    try {
      const value = JSON.stringify(this.cache);
      this.storage.setItem(this.namespace, value);
    } catch {
      //
    }
  }
}

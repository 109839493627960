import React from 'react';
import cx from 'classnames';
import Tap, { TapProps } from '../Tap';
export { UNDERLINE_TEXT_CLS_NAME } from '../Tap';

/**
 * テキストのみのシンプルなリンク
 */
export const TextLink: React.FC<TapProps> = ({ className, ...props }) => (
  <Tap {...props} className={cx('TextLink', className)} />
);

import Fetchr from 'fetchr';
import FetchrSetting from '../setting';

export default class FetchrSharedFactory {
  public fetchr: Fetchr;

  constructor(options) {
    this.fetchr = new Fetchr({
      ...options,
      ...FetchrSetting,
    });
  }

  read<T>(resource: string, params = {}, config = {}): Promise<{ data: T }> {
    return this.fetchr.read(resource).params(params).clientConfig(config).end();
  }

  delete<T>(resource: string, params = {}, config = {}): Promise<{ data: T }> {
    return this.fetchr
      .delete(resource)
      .params(params)
      .clientConfig(config)
      .end();
  }

  create<T>(
    resource: string,
    params = {},
    body = {},
    config = {},
  ): Promise<{ data: T }> {
    return this.fetchr
      .create(resource)
      .params(params)
      .body(body)
      .clientConfig(config)
      .end();
  }

  update<T>(
    resource: string,
    params = {},
    body = {},
    config = {},
  ): Promise<{ data: T }> {
    return this.fetchr
      .update(resource)
      .params(params)
      .body(body)
      .clientConfig(config)
      .end();
  }
}

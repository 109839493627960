/**
 * ログインエンドポイントを返却
 * @param redirect_path
 */
export const createLoginUrl = (redirect_path?: string): string => {
  return redirect_path
    ? `/auth/login?redirect_path=${encodeURIComponent(redirect_path)}`
    : `/auth/login`;
};

/**
 * オフィシャルブログの画像URL
 * @param {string} amebaId
 * @return {string}
 */
export const createOfficialBlogImageUrl = (amebaId: string): string => {
  return `${process.env.DOMAIN_STAT100_URL}/blog/img/ameba/officialblog/face/${amebaId}_120.jpg`;
};

/**
 * ブログのURL
 * @param {string} amebaId
 * @return {string}
 */
export const createAmebloUrl = (amebaId: string): string => {
  return `${process.env.DOMAIN_AMEBLO_URL}/${amebaId}/`;
};

/**
 * 画像URLにoption cropをつけて返す
 * stat.amebame.comの画像 以外にはつかえないので注意
 * @param {string} url
 * @param {number} width
 * @param {number} height
 * @return {string}
 */
export const getCropImageUrl = (
  url: string,
  width: number,
  height: number,
): string => {
  return `${url}?option=crop&width=${width}&height=${height}`;
};

/**
 * 画像URLにoption cropをつけて２倍サイズで返す
 * stat.amebame.comの画像 以外にはつかえないので注意
 * @param {string} url
 * @param {number} width
 * @param {number} height
 * @return {string}
 */
export const getCropRetinaImageUrl = (
  url: string,
  width: number,
  height: number,
) => {
  return getCropImageUrl(url, width * 2, height * 2);
};

/**
 * 広告サービスで稼ぐURL
 * @param {string} path
 * @param {string} mId
 * @returns {string}
 */
export const createAwMobadameUrl = (path: string, mId: string) => {
  return `${process.env.DOMAIN_AW_MOBADAME_URL}/${path}?m_id=${mId}`;
};

/**
 * 画像URLにfit cropをつけて返す
 * hayabusaの画像 以外にはつかえないので注意
 * @param {string} url
 * @param {number} width
 * @param {number} height
 * @return {string}
 */
export const getHayabusaCropImageUrl = (
  url: string,
  width: number,
  height?: number,
): string => {
  if (!height) {
    return `${url}?fit=crop&width=${width}`;
  }
  return `${url}?fit=crop&width=${width}&height=${height}`;
};

/**
 * 画像URLにfit cropをつけて２倍サイズで返す
 * hayabusaの画像 以外にはつかえないので注意
 * @param {string} url
 * @param {number} width
 * @param {number} height
 * @return {string}
 */
export const getHayabusaCropRetinaImageUrl = (
  url: string,
  width: number,
  height?: number,
): string => {
  if (!height) {
    return `${url}?fit=crop&width=${width * 2}`;
  }
  return `${url}?fit=crop&width=${width * 2}&height=${height * 2}`;
};

/**
 * 画像URLにAmebaマンガ推奨のpc用パラメータをつけて返す
 * dokushoの画像 以外にはつかえないので注意
 * 横幅固定の高さauto
 * @param {string} url
 * @param {number} width
 * @return {string}
 */
export const getDokushoImageUrl = (url: string, width: number): string => {
  return `${url}?q=80&jpeghint=y&da=l&dw=${width}`;
};

/**
 * 画像URLにAmebaマンガ推奨のpc用cropパラメータをつけて返す
 * dokushoの画像 以外にはつかえないので注意
 * @param {string} url
 * @param {number} width
 * @param {number} height
 * @return {string}
 */
export const getDokushoCropImageUrl = (
  url: string,
  width: number,
  height: number,
): string => {
  return `${url}?q=80&jpeghint=y&da=l&dw=${width}&dh=${height}&cw=${width}&ch=${height}&cc=FFFFFF`;
};

/**
 * Amebaマンガログインしてurlにリダイレクトするurlを返す
 * @param {string} redirectUrl
 * @return {string}
 */
export const getDokushoLoginRedirectUrl = (redirectUrl: string): string => {
  return `${
    process.env.DOMAIN_DOKUSHO_URL
  }/ameba_manga_login?to=${encodeURIComponent(redirectUrl)}`;
};

interface UtmParams {
  utm_source: string;
  utm_medium: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

/**
 * UTMキャンペーンURL取得
 * @param {string} url
 * @param {UtmParams} params
 * @return {string}
 *
 * utmパラメータとは
 * @see {@link https://wiki.ca-tools.org/pages/viewpage.action?pageId=75848808}
 */
export const getUtmCampaignUrl = (url: string, params: UtmParams): string => {
  const qs = [];
  Object.keys(params).forEach((key) => {
    qs.push(`${key}=${encodeURIComponent(params[key])}`);
  });
  const str = qs.join('&');
  const [uri, hash] = url.split('#');
  const hasQuery = uri.indexOf('?') > -1;
  const hasHash = !!hash;
  return uri + (hasQuery ? '&' + str : '?' + str) + (hasHash ? '#' + hash : '');
};

/**
 * 登録導線用linkUrl取得関数
 * @param {string} url
 * @param {UtmParams} utmParams
 * @returns {string}
 *
 * utmパラメータとは
 * @see {@link https://wiki.ca-tools.org/pages/viewpage.action?pageId=75848808}
 * force_ameba_idとは
 * クライアントIDの指定を上書きする形でアメーバID有無を指定できるパラメータ
 * @see {@link https://ameba.esa.io/posts/8765#force_ameba_id}
 */
export const getAccountsRegistrationLinkUrl = (
  url: string,
  utmParams: UtmParams,
): string => {
  const qs = [];
  Object.keys(utmParams).forEach((key) => {
    qs.push(`${key}=${encodeURIComponent(utmParams[key])}`);
  });
  const str = qs.join('&');
  const [uri, hash] = url.split('#');
  const hasQuery = uri.indexOf('?') > -1;
  const hasHash = !!hash;
  return uri + (hasQuery ? '&' + str : '?' + str) + (hasHash ? '#' + hash : '');
};

/**
 * URLのパスから指定のセグメントを取得
 */
export const getPathSegmentByIndex = (url: string, index: number) => {
  const parsedUrl = new URL(url);
  return parsedUrl.pathname.split('/')[index + 1]; // 0番目は空文字なので1つずらす
};

import React from 'react';
import cx from 'classnames';
import Icon, { IconTypes } from '../../../atoms/Icon';
import Tap from '../../../atoms/Tap';
import TextLink from '../../../atoms/TextLink';
import { SECTION_ID } from './constants/MENU';
import {
  MenuSetting,
  SETTING_AMEBLO,
  SETTING_BLOG,
  SETTING_BLOG_DATA,
  SETTING_BLOG_DESIGN,
  SETTING_BLOG_EDIT,
  SETTING_BLOG_OTHERS,
  SETTING_BLOG_REACTION,
  SETTING_BLOG_SIDEBAR,
  SETTING_NOTIFICATION,
  SETTING_OTHERS,
  SETTING_SERVICE,
  SETTING_USER,
  SETTING_NO_AMEBA_ID,
} from './constants/SETTING';

const PcHeaderSettingCategory: React.FC<
  {
    onClick(): void;
    className?: string;
  } & MenuSetting
> = ({ className, onClick, title, links }) => {
  return (
    <div className={cx('PcHeader_Menu_SettingItem', className)}>
      <h3 className="PcHeader_Menu_Setting_Title">{title}</h3>
      <ul>
        {links.map((link) => {
          return (
            <li key={SECTION_ID + '-setting-' + link.title}>
              <TextLink
                className="PcHeader_Menu_Setting_Link TapText"
                to={link.to || null}
                href={link.linkUrl}
                onClick={(link.to && onClick) || null}
                track={{
                  section_id: SECTION_ID,
                  tap_id: 'navigation-settings',
                  service_id: link.serviceId,
                  content_ids: [link.contentId],
                }}
                visitable
                transparent
              >
                {link.title}
              </TextLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface Props {
  open: boolean;
  isAmebaUser: boolean;
  handleClick(): void;
}

const PcHeaderSetting: React.FC<Props> = ({
  open,
  isAmebaUser,
  handleClick,
}) => {
  return (
    <div className="PcHeader_SettingButton">
      <Tap
        className="PcHeader_Control_Tap"
        track={{
          section_id: SECTION_ID,
          tap_id: 'setting',
        }}
        onClick={handleClick}
        transparent
      >
        設定
        <Icon
          className="-align"
          iconClass={open ? IconTypes.ArrowPagingUp : IconTypes.ArrowPagingDown}
        />
      </Tap>
      <div
        className="PcHeader_Menu_Categories"
        aria-hidden={!open}
        style={{ textAlign: 'left' }}
      >
        {isAmebaUser ? (
          <div className="PcHeader_Menu_Categories_Inner">
            <div className="PcHeader_Menu_Category">
              <PcHeaderSettingCategory
                {...SETTING_USER}
                onClick={handleClick}
              />
              <PcHeaderSettingCategory
                {...SETTING_NOTIFICATION}
                onClick={handleClick}
              />
            </div>
            <div className="PcHeader_Menu_Category -ameblo">
              <PcHeaderSettingCategory
                className="PcHeader_Menu_Setting_Ameblo"
                {...SETTING_AMEBLO}
                onClick={handleClick}
              />
              <div className="PcHeader_Menu_Setting_AmebloColumn">
                <PcHeaderSettingCategory
                  {...SETTING_BLOG_EDIT}
                  onClick={handleClick}
                />
                <PcHeaderSettingCategory
                  {...SETTING_BLOG}
                  onClick={handleClick}
                />
                <PcHeaderSettingCategory
                  {...SETTING_BLOG_REACTION}
                  onClick={handleClick}
                />
                <PcHeaderSettingCategory
                  {...SETTING_BLOG_DATA}
                  onClick={handleClick}
                />
              </div>
              <div className="PcHeader_Menu_Setting_AmebloColumn">
                <PcHeaderSettingCategory
                  {...SETTING_BLOG_DESIGN}
                  onClick={handleClick}
                />
                <PcHeaderSettingCategory
                  {...SETTING_BLOG_SIDEBAR}
                  onClick={handleClick}
                />
                <PcHeaderSettingCategory
                  {...SETTING_BLOG_OTHERS}
                  onClick={handleClick}
                />
              </div>
            </div>
            <div className="PcHeader_Menu_Category">
              <PcHeaderSettingCategory
                {...SETTING_SERVICE}
                onClick={handleClick}
              />
              <PcHeaderSettingCategory
                {...SETTING_OTHERS}
                onClick={handleClick}
              />
            </div>
          </div>
        ) : (
          <div className="PcHeader_Menu_Categories_Inner">
            <div className="PcHeader_Menu_Category">
              <PcHeaderSettingCategory
                {...SETTING_NO_AMEBA_ID}
                onClick={handleClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PcHeaderSetting;

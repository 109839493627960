/**
 * 共通スキーマ
 */
export interface AdcrossAdSchema<T = any> {
  cid: number;
  hash: string;
  linkUrl: string;
  imageUrl?: string;
  title?: string;
  description?: string;
  text?: string;
  custom: T;
  genuuid?: boolean;
}

/**
 * アドクロスの広告配信タイプ
 */
export type AdType = 'bn' | 'tx' | 'btd' | 'overlay';

/**
 * 広告配信クリエイティブ
 *
 * @export
 * @interface AdcrossCreativeSchema
 */
export interface AdcrossCreativeSchema {
  cid: number;
  sid: string;
  hash: string;
  target: {
    url: string;
  };
  img_url?: string;
  text?: string;
  title?: string;
  description?: string;
  custom?: any;
}

/**
 * 広告配信レスポンス
 *
 * @export
 * @interface AdcrossPayload
 */
export interface AdcrossPayload {
  status: number;
  v: number;
  type: AdType;
  meas_domain: string;
  creatives: AdcrossCreativeSchema[];
  pid: number;
  width: number;
  height: number;
}

/**
 * 複数広告配信1つのresultレスポンス
 *
 * @export
 * @interface AdcrossPayload
 */
export interface AdcrossBulkResultPayload {
  status: number;
  v?: number;
  type?: AdType;
  meas_domain?: string;
  creatives?: AdcrossCreativeSchema[];
  pid?: number;
  width?: number;
  height?: number;
}

/**
 * 複数広告配信レスポンス
 *
 * @export
 * @interface AdcrossBulkPayload
 */
export interface AdcrossBulkPayload {
  results: AdcrossBulkResultPayload[];
}

/**
 * 記事配信リクエストパラメータ
 * @see { https://wiki.ca-tools.org/pages/viewpage.action?pageId=45289307 }
 *
 * @export
 * @interface TopicOptions
 */
export type DeviceType = 'pc' | 'ios' | 'android' | 'iosApp' | 'androidApp';
export interface TopicOptions {
  v?: number;
  age?: number | 'none';
  gender?: string;
  device?: DeviceType;
  limit?: number;
  offset?: number;
  sort?: 'ctr' | 'postDt' | 'invCtr' | 'bandit' | 'click';
  dt?: string;
  service?: string;
  media?: 'photo' | 'video';
}

/**
 * 動画配信リクエストパラメータ
 * @see {@link https://wiki.ca-tools.org/pages/viewpage.action?pageId=45297536}
 *
 * @export
 * @interface VideoOptions
 */
export interface VideoOptions {
  os?: 'pc' | 'ios' | 'android' | 'ios_app' | 'android_app';
  gender?: 'male' | 'female';
  label?: string; // default 'playlist' (任意の文字列)
}

/**
 * 写真／動画フラグ
 *
 * @export
 * @interface Media
 */
export interface Media {
  has_photo?: boolean;
  has_video?: boolean;
}

export interface Service {
  name: string;
  code: string;
}

/**
 * 大人気／人気／急上昇
 */
export type Popularity = 'hpop' | 'pop' | 'hot';

/**
 * 記事配信クリエイティブ
 *
 * @export
 * @interface TopicsCreativeSchema
 */
export interface TopicsCreativeSchema {
  id: number;
  format: string;
  hash: string;
  target: {
    url: string;
    is_same_dmn?: boolean;
  };
  service?: Service;
  title: string;
  post_dt: string;
  img_url?: string;
  popularity?: Popularity;
  media?: Media;
}

/**
 * 記事配信レスポンス
 *
 * @export
 * @interface TopicsPayload
 */
export interface TopicsPayload {
  status: number;
  v: number;
  area_code: string;
  meas_domain: string;
  topics: TopicsCreativeSchema[];
  following?: string;
}

export interface VideoCreativeSchema {
  id: number;
  src_fmt: string;
  title: string;
  target: {
    url: string;
    is_same_dmn?: boolean;
  };
  btn_title: string;
  vdo: {
    thumbnail: string;
    url: string;
    width: number;
    height: number;
  };
  service: Service;
  hash: string;
}

export interface VideoPayload {
  status: number;
  v: number;
  area_code: string;
  meas_domain: string;
  items: VideoCreativeSchema[];
}

/**
 * アドクロスAPI種別
 */
export enum AdcrossType {
  own /* 広告系 */,
  tpc /* 記事配信系 */,
  vdo /* 動画配信 */,
  ssp /* SSP */,
}

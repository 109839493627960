import React from 'react';
import cx from 'classnames';
import logger from '../../../lib/Logger';
import {
  BaseContents,
  InviewContents,
  ViewSectionContents,
} from '../../../services/mine/response/Mine';
import {
  AdcrossBaseMeasureSchema,
  AdcrossBulkMeasureSchema,
  trackView,
} from '../../../webApi/Adcross';
import { trackInviewSection, trackViewSection } from '../../../webApi/Tracker';

type Callback = () => void;

export interface TrackableViewportProps<T = unknown> {
  adcross?: AdcrossBaseMeasureSchema;
  track?: BaseContents;
  inviewTrack?: InviewContents;
  className?: string;
  element?: string;
  timeout?: number;
  // IntersectionObserverOptions
  root?: Element;
  rootMargin?: string;
  threshold?: number | number[];

  triggerOnce?: boolean;
  order?: number;
  onInView?: Callback;
  onOutView?: Callback;

  // HTML Standard Props
  id?: string;
  children?: React.ReactNode;
}

export class TrackableViewport extends React.Component<TrackableViewportProps> {
  /* ----------------- static members ----------------- */
  static get defaultProps() {
    return {
      element: 'div',
      timeout: 1000,
      threshold: [0.1, 0.15],
      triggerOnce: true,
    };
  }

  static convertViewSectionTrack(track: BaseContents): ViewSectionContents {
    const {
      section_id,
      tap_id,
      category_id,
      service_id,
      order_id,
      content_ids,
      content_id,
    } = track;
    return {
      section_id,
      tap_id: tap_id || 'view',
      category_id,
      service_id,
      order_id,
      content_ids,
      content_id,
    };
  }

  static convertInviewSectionTrack(inviewTrack: InviewContents) {
    const {
      client_id,
      template_name,
      grouping_key,
      new_flag,
      unread_flag,
      area_url,
      order,
    } = inviewTrack;
    return {
      client_id,
      template_name,
      grouping_key,
      new_flag,
      unread_flag,
      area_url,
      order,
    };
  }

  static convertAdcrossTrack(
    adcross: AdcrossBaseMeasureSchema,
  ): AdcrossBulkMeasureSchema {
    const hashes = adcross.hash ? [adcross.hash] : adcross.hashes;
    return {
      type: adcross.type,
      hashes,
      genuuid: adcross.genuuid,
    };
  }

  private targetRef: React.RefObject<HTMLElement>;
  private observer: IntersectionObserver;
  private mounted: boolean;
  private timerId: any; // number
  private sent: boolean;
  private triggered: boolean;

  /**
   * コンストラクター
   * @param props
   */
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
  }

  /* -------------- Self defined members -------------- */
  setTrackTimeout(callback?: Callback) {
    if (this.timerId || this.sent) {
      return;
    }
    const { timeout } = this.props;

    this.timerId = setTimeout(() => {
      this.sent = true;
      // do track
      this.track();
      // callback
      callback && callback();
    }, timeout);
  }

  clearTrackTimeout() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  track() {
    // do track
    const { track, inviewTrack, adcross } = this.props;
    if (inviewTrack) {
      trackInviewSection(
        TrackableViewport.convertInviewSectionTrack(inviewTrack),
      ).catch((e) => {
        logger.error(e);
      });
    }
    if (track) {
      trackViewSection(TrackableViewport.convertViewSectionTrack(track)).catch(
        (e) => {
          logger.error(e);
        },
      );
    }
    if (adcross) {
      const convertedAdcross = TrackableViewport.convertAdcrossTrack(adcross);
      trackView(
        convertedAdcross.hashes,
        convertedAdcross.type,
        convertedAdcross.genuuid,
      ).catch((e) => {
        logger.error(e);
      });
    }
  }

  handleIntersectionChange(callback: Callback) {
    if (this.props.triggerOnce && this.triggered) {
      return;
    }

    if (callback) {
      this.triggered = true;
      callback();
    }
  }

  handleTrackObserver(
    entries: IntersectionObserverEntry[],
    trackedCallback: Callback,
  ) {
    if (!this.mounted) {
      this.removeObserverListener();
      return;
    }
    const { onInView, onOutView } = this.props;
    const entry = entries[0];

    if (entry.intersectionRatio > 0.1) {
      // handle inview
      // set track timeout
      this.setTrackTimeout(trackedCallback);
      this.handleIntersectionChange(onInView);
    } else {
      // handle un-inview
      // clear track timeout
      this.clearTrackTimeout();
      this.handleIntersectionChange(onOutView);
    }

    this.handleObserverEnd();
  }

  handleObserverEnd() {
    const { onOutView, onInView, triggerOnce } = this.props;
    const isObserverEnd =
      onInView || onOutView
        ? triggerOnce && this.triggered && this.sent
        : this.sent;
    if (isObserverEnd) {
      this.removeObserverListener();
    }
  }

  addObserverListener() {
    if (!this.observer && this.targetRef.current) {
      const { root, rootMargin, threshold } = this.props;
      // initialize observer
      this.observer = new IntersectionObserver(
        (entries) => {
          this.handleTrackObserver(entries, () => {
            this.handleObserverEnd();
          });
        },
        {
          root,
          rootMargin,
          threshold,
        },
      );
      // start observe
      this.observer.observe(this.targetRef.current);
    }
  }

  removeObserverListener() {
    if (this.observer && this.targetRef.current) {
      // end observe
      this.observer.unobserve(this.targetRef.current);
      this.observer = null;
    }
  }

  /* ----------------- React members ----------------- */
  componentDidMount() {
    this.mounted = true;
    this.sent = false;
    this.triggered = false;
    this.addObserverListener();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.removeObserverListener();
  }

  render() {
    const {
      element,
      className,
      children,
      // remove from props
      adcross,
      track,
      timeout,
      root,
      rootMargin,
      threshold,
      triggerOnce,
      onInView,
      onOutView,
      ...props
    } = this.props;

    return React.createElement(
      element,
      {
        className: cx('TrackableViewport', className),
        ref: this.targetRef,
        ...props,
      },
      [children],
    );
  }
}

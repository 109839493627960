import { match } from 'react-router';
import { NAMESPACE } from './namespace';

interface ExtMatch extends match<any> {
  pathname: string;
  searchParams: any;
  hash: string;
}

/**
 * composeRouteで渡すLoaderのインターフェース
 */
interface Triggers {
  serverLoader?(params: any, match: ExtMatch): Promise<void>;
  routerLoader?(params: any, match: ExtMatch): Promise<void>;
  clientLoader?(params: any, match: ExtMatch): Promise<void>;
}

/**
 * RouteComponentのクラスでサーバーサイド、SPAルーティングのタイミングで実行するものを定義
 * serverLoaderはサーバーサイドで
 * routerLoaderはサーバーサイドとcomposeContainerのライフサイクルで実行される
 * @param {Triggers} triggers
 */
export const composeRoute = (triggers: Triggers) => (ComposedComponent) => {
  ComposedComponent[NAMESPACE] = triggers;
  return ComposedComponent;
};

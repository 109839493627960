import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../../lib/ReactRouterUtils';
import { getAsyncComponent } from '../../../../lib/ComponentUtils';
import { setTitle } from '../../../../contexts/AppShell/SpHeader';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('楽曲利用申請フォーム', 'other'));
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./CopyrightCompleteDesktop'))),
);

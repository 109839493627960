// adx-v3 raicho api

import { AdxV3GetASpotResponse } from '../types/apis/adx-v3-api';

const prepareInit = () => {
  const init: RequestInit = {
    mode: 'cors',
    credentials: 'include',
  };
  return init;
};

export const getSpot = async (
  spot: string,
  params: {
    ms: string;
    qpi: string; // pageType
    rver: `${number}`;
  },
  asId?: string,
) => {
  const uri = new URL(process.env.API_ADX_V3_URL);
  uri.pathname = `/a/spot/${spot}`;

  const searchParams = new URLSearchParams(params);
  searchParams.set('qa', asId);
  searchParams.set('uid', asId);
  uri.search = searchParams.toString();

  const res = await fetch(uri, prepareInit());
  if (!res.ok) {
    throw new Error(`Failed to fetch ${spot}`);
  }
  return res.json() as Promise<AdxV3GetASpotResponse>;
};

export const measureSpot = async (
  spot: string,
  action: 'inview' | 'tap',
  params: {
    qpi: string; // pageType
    rver: `${number}`;
  },
  mqps: string,
  itemMqps: string,
  dspMqps: string,
  asId?: string,
) => {
  const uri = new URL(process.env.API_ADX_V3_MEASURE_URL);
  uri.pathname = `/a/noti/${spot}-${action}`;

  const searchParams = new URLSearchParams(params);
  if (asId) {
    searchParams.set('qa', asId);
    searchParams.set('uid', asId);
  }
  searchParams.set('qpl', 'web');
  uri.search = [searchParams.toString(), mqps, itemMqps, dspMqps].join('&');

  const res = await fetch(uri, prepareInit());
  if (!res.ok) {
    throw new Error(`Failed to fetch ${spot}`);
  }
  return true; // JSONではないので、成功をbooleanで返却する
};

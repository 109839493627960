import { Request } from 'express';
import { produce } from 'immer';
import get from 'lodash/get';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';

import { CoinSummaryPayload } from '../../../services/_sp/response/CoinSummaryService';
import { readCoinSummaryData } from '../../../fetchr/services/Coin/Summary/SummaryShared';

const initialState = {
  coinSummaryInfo: null,
  isLoading: true,
};

interface State {
  coinSummaryInfo: CoinSummaryPayload;
  isLoading: boolean;
}

interface Payload {
  coinSummaryInfo: CoinSummaryPayload;
}

/**
 *  ユーザーコイン情報
 */
export const FETCH_COIN_SUMMARY = 'Coin/Summary/FETCH_COIN_SUMMARY';

export const FETCH_COIN_SUMMARY_REQUEST =
  'Coin/Summary/FETCH_COIN_SUMMARY_REQUEST';
export const FETCH_COIN_SUMMARY_SUCCESS =
  'Coin/Summary/FETCH_COIN_SUMMARY_SUCCESS';
export const FETCH_COIN_SUMMARY_FAILURE =
  'Coin/Summary/FETCH_COIN_SUMMARY_FAILURE';

export const fetchCoinSummary: ThunkActionCreator = (
  req?: Request,
  device?: string,
) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COIN_SUMMARY_REQUEST });
    try {
      const { data } = await readCoinSummaryData(req, device);
      dispatch({ type: FETCH_COIN_SUMMARY_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_COIN_SUMMARY_FAILURE });
    }
  };
};

export const coinSummary = handleActions<State, Payload>(
  {
    [FETCH_COIN_SUMMARY_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_COIN_SUMMARY_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const coinSummaryInfo = get(payload, 'coinSummaryInfo');
        draft.coinSummaryInfo = coinSummaryInfo;
        draft.isLoading = false;
      }),
    [FETCH_COIN_SUMMARY_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
  },
  initialState,
);

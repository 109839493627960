import { Request } from 'express';
import { handleActions } from '../../../lib/ReduxUtils';
import { PureFluxStandardAction } from '../../../lib/ReduxUtils/interface';

interface State {
  source: string;
  version: string;
  isAndroid: boolean;
  isSafari: boolean;
  isChrome: boolean;
  isMobile: boolean;
  isWebkit: boolean;
  isCaApp: boolean;
  isAmbApp: boolean;
  ambAppVersion: string;
}

type Payload = Partial<State>;

const initialState: State = {
  source: null,
  version: null,
  isAndroid: null,
  isSafari: null,
  isChrome: null,
  isMobile: null,
  isWebkit: null,
  isCaApp: null,
  isAmbApp: null,
  ambAppVersion: null,
};

/**
 * ActionTypes
 */
export const REGISTER_USERAGENT = 'Common/UserAgent/REGISTER_USERAGENT';

/**
 * Actions
 */
export const registerUserAgent = (
  req: Request,
): PureFluxStandardAction<Payload> => {
  if (!req) {
    throw new Error(
      'Action#registerUserAgent can only call with express.Request instance.',
    );
  }
  return {
    type: REGISTER_USERAGENT,
    payload: {
      source: req.useragent.source,
      version: req.useragent.version,
      isAndroid: req.useragent.isAndroid,
      isSafari: req.useragent.isSafari,
      isChrome: req.useragent.isChrome,
      isMobile: req.useragent.isMobile,
      isWebkit: req.useragent.isWebkit,
      isCaApp: req.useragent.isCaApp,
      isAmbApp: req.useragent.isAmbApp,
      ambAppVersion: req.useragent.ambAppVersion,
    },
  };
};

/**
 * Reducer
 */
export const userAgent = handleActions<State, Payload>(
  {
    [REGISTER_USERAGENT](state, { payload, error }) {
      if (error) {
        return state;
      } else {
        return { ...state, ...payload };
      }
    },
  },
  initialState,
);

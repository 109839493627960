import { Request } from 'express';
import {
  readBlogFeedData,
  readBlogFeedInitializeData,
} from '../../../fetchr/services/HomeDesktop/BlogFeed/BlogFeedShared';
import {
  createAction,
  handleActions,
  ThunkActionCreator,
} from '../../../lib/ReduxUtils';
import { CardCollectionEntity } from '../../../services/_pc/entity/CardCollectionEntity';

export interface BlogFeedState {
  codes: string[];
  collections: CardCollectionEntity[];
  offset: number;
  isLoading: boolean;
  isError: boolean;
}

type Payload = Partial<BlogFeedState>;

const initialState: BlogFeedState = {
  codes: null,
  collections: null,
  offset: 0,
  isLoading: false,
  isError: false,
};

export const INITIALIZE_FEED = 'HomeDesktop/HomeBlogFeed/INITIALIZE_FEED';
export const initializeFeed = createAction<Payload>(INITIALIZE_FEED, () => {
  return {};
});

export const SET_FEED_CODES = 'HomeDesktop/HomeBlogFeed/SET_FEED_CODES';
export const setFeedCodes = createAction<Payload>(
  SET_FEED_CODES,
  (codes: string[]) => {
    return {
      codes,
    };
  },
);

export const FETCH_START = 'HomeDesktop/HomeBlogFeed/FETCH_START';
export const fetchStart = createAction<Payload>(FETCH_START, () => {
  return {};
});

export const FETCH_SUCCESS = 'HomeDesktop/HomeBlogFeed/FETCH_SUCCESS';
export const fetchSuccess = createAction<Payload>(
  FETCH_SUCCESS,
  (collections: CardCollectionEntity[]) => {
    return {
      collections,
    };
  },
);

export const FETCH_ERROR = 'HomeDesktop/HomeBlogFeed/FETCH_ERROR';
export const fetchError = createAction<Payload>(FETCH_ERROR, () => {
  return {};
});

export const initializeFeedByGenreCodes: ThunkActionCreator = (
  req: Request,
  followCodes: string[],
) => {
  return async (dispatch) => {
    dispatch(initializeFeed());
    dispatch(fetchStart());

    try {
      const { data: codes } = await readBlogFeedInitializeData(
        req,
        followCodes,
      );
      dispatch(setFeedCodes(codes));

      await dispatch(fetchBlogFeed(req));
    } catch {
      dispatch(fetchError());
    }
  };
};

const LIMIT = 3;
export const fetchBlogFeed: ThunkActionCreator = (req?: Request) => {
  return async (dispatch, getState) => {
    dispatch(fetchStart());

    try {
      const { homeBlogFeed, loginInfo } = getState();
      const codes = homeBlogFeed.codes.slice(
        homeBlogFeed.offset,
        homeBlogFeed.offset + LIMIT,
      );
      const { data: collections } = await readBlogFeedData(
        req,
        codes,
        loginInfo.user.gender,
        homeBlogFeed.offset,
      );
      dispatch(fetchSuccess(collections));
    } catch {
      dispatch(fetchError());
    }
  };
};

export const homeBlogFeed = handleActions<BlogFeedState, Payload>(
  {
    [INITIALIZE_FEED]() {
      return initialState;
    },
    [SET_FEED_CODES](state, { payload }) {
      return {
        ...state,
        codes: payload.codes,
      };
    },
    [FETCH_START](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [FETCH_SUCCESS](state, { payload }) {
      const collections = state.collections || [];
      collections.push(...payload.collections);
      return {
        ...state,
        collections,
        offset: state.offset + LIMIT,
        isLoading: false,
        isError: false,
      };
    },
    [FETCH_ERROR](state) {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
  },
  initialState,
);

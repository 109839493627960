/**
 * ルート名管理
 */
export const ROUTE_NAMES = {
  // ページは存在しないが、パスは存在するもの
  logout: '/logout',
  logoutHome: '/logout/home',

  // pc/sp両方存在するページ
  top: '/',
  home: '/home',
  nonamebaid: '/nonamebaid',
  campaign: '/campaign/?',
  settings: '/settings',
  settings_notifications: '/settings/notifications',
  settings_mangafeed: '/settings/mangafeed',
  coinSummary: '/coin/summary',
  tradelaw: '/tradelaw/:id',
  copyright: '/copyright',
  copyrightVideos: '/copyright/videos',
  copyrightSearch: '/copyright/search',
  copyrightConfirm: '/copyright/confirm',
  copyrightComplete: '/copyright/complete',
  game: '/game',
  apps: '/apps',
  apps_category: '/apps/:id',
  myapps: '/myapps',

  // 以下はメインがSP用ページ
  notifications: '/notifications',
  gamePigg: '/game/pigg',
  talk: '/talk',

  contact: '/contact',

  profileSettings: '/profile/settings',

  appsRanking: '/apps/ranking',
  appsTag: '/apps/tag/:id',
  appDetail: '/app/detail/:id',

  searchGroup: '/search/group',

  invitationExternal: '/invitation/external',

  firstLoginBonus: '/campaign/first_login',
  comebackBonus: '/dotmoney/comeback',

  // TODO: 20th終わったら削除
  ameba20thInvitation: '/ameba20th-invitation',
  ameba20thInvitationEventsDetail: '/ameba20th-invitation/events/:id',
  ameba20thInvitationTicket: '/ameba20th-invitation/ticket',
};

export const PAGE_IDS = {
  // pc/sp両方存在するページ
  top: 'top',
  home: 'home',
  nonamebaid: 'nonamebaid',
  campaign: 'campaign',
  notifications: 'notifications',
  settings: 'settings',
  settings_notifications: 'settings-notifications',
  settings_mangafeed: 'settings-mangafeed',
  coin_summary: 'coin-summary',
  tradelaw: 'tradelaw',
  copyright: 'copyright',
  copyright_videos: 'copyright-videos',
  copyright_search: 'copyright-search',
  copyright_confirm: 'copyright-confirm',
  copyright_complete: 'copyright-complete',
  game: 'game',
  apps: 'apps',
  apps_category: 'apps',
  app_detail: 'app-detail',
  myapps: 'myapps',

  // TODO: 20th終わったら削除
  ameba20thInvitation: 'ameba20th-invitation',
  ameba20thInvitationEventsDetail: 'ameba20th-invitation-events-detail',
  ameba20thInvitationTicket: 'ameba20th-invitation-ticket',
};

import { fetchChecklist } from '../../../contexts/HomeDesktop/HomeChecklist';
import {
  fetchInitialData,
  fetchOnClientLoaded,
  initializeState,
} from '../../../contexts/HomeDesktop/HomeDesktop';
import { getAsyncComponent } from '../../../lib/ComponentUtils';
import logger from '../../../lib/Logger';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';

export default composeRoute({
  async routerLoader({ req, dispatch }) {
    logger.debug('routerLoader started');
    try {
      await dispatch(initializeState());
      await dispatch(fetchInitialData(req));
    } catch (e) {
      logger.error(e, 'Failed to load data');
    }
  },
  async clientLoader({ req, dispatch }) {
    logger.debug('clientLoader started');
    try {
      const tasks = [];
      tasks.push(dispatch(fetchOnClientLoaded(req)));
      tasks.push(dispatch(fetchChecklist(req, null, true)));
      await Promise.all(tasks);
    } catch (e) {
      logger.error(e, 'Failed to load client Loader');
    }
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./Home'))),
);

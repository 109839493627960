import { parse } from 'querystring';
import { matchRoutes, RouteConfig } from 'react-router-config';
import { NAMESPACE } from './namespace';

export interface Location {
  pathname: string;
  search?: string;
  hash?: string;
}

/**
 * 特定のLoaderメソッドを実行する関数
 * Loaderで引数として使用したいものをlocalsに入れて渡す
 * @param {string} triggerName
 * @param {RouteConfig[]} routes
 * @param {Location} location
 * @param locals
 * @returns {Promise<any[]>}
 */
export function trigger(
  triggerName: string,
  routes: RouteConfig[],
  location: Location,
  locals: any,
) {
  const tasks = [];
  const ext = {
    pathname: location.pathname,
    searchParams: location.search
      ? parse(location.search.replace(/^\?/, ''))
      : {},
    hash: location.hash || '',
  };
  matchRoutes(routes, location.pathname).forEach(({ route, match }) => {
    if (route.component[NAMESPACE] && route.component[NAMESPACE][triggerName]) {
      tasks.push(
        route.component[NAMESPACE][triggerName](locals, { ...match, ...ext }),
      );
    }
  });
  return Promise.all(tasks);
}

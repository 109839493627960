import React from 'react';
import get from 'lodash/get';
import { RouterState } from 'connected-react-router';
import { connect, DispatchProp } from 'react-redux';
import { LOGIN, REGISTER_LOGIN } from '../../../../constants/amebaLogin';
import { toggle } from '../../../../contexts/AppShellDesktop/LoginDialog';
import Tap from '../../../atoms/Tap';
import PcSearchBox from '../../../blocks/PcSearchBox';
import { getAccountsRegistrationLinkUrl } from '../../../../lib/UrlUtils';
import Button, { ButtonSize, ButtonType } from '../../../atoms/Button';
import {
  MENU_AMEBA_NEWS,
  MENU_BLOG,
  MENU_BLOG_GENRE,
  MENU_BLOG_TALENT,
  MENU_CATEGORY,
  MENU_DOKUSHO,
  MENU_DOKUSHO_AUTHENTICATED,
  MENU_PIGG,
  SECTION_ID,
} from './constants/MENU';
import PcHeaderMenu from './PcHeaderMenu';
import PcHeaderSetting from './PcHeaderSetting';
import PcHeaderToggleMenu from './PcHeaderToggleMenu';

const TOGGLE = {
  setting: false,
  pigg: false,
  game: false,
  category: false,
};

interface Props {
  router: RouterState;
  isAmebaUser: boolean;
  isAuthenticated: boolean;
}

interface State {
  menu: {
    setting: boolean;
    pigg: boolean;
    game: boolean;
    category: boolean;
  };
}

const mapStateToProps = (state) => {
  return {
    router: get(state, 'router'),
    isAmebaUser: !!get(state, 'loginInfo.user.amebaId'),
    isAuthenticated: get(state, 'loginInfo.isAuthenticated'),
  };
};

export class PcHeader extends React.Component<
  Props & DispatchProp<any>,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      menu: TOGGLE,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(id?: 'pigg' | 'game' | 'category' | 'setting') {
    const toggle = {};

    if (id) {
      toggle[id] = !this.state.menu[id];
    }

    this.setState({
      menu: {
        ...TOGGLE,
        ...toggle,
      },
    });
  }

  renderLogin() {
    return (
      <div className="PcHeader_Controls_Login clearfix">
        <Button
          buttonType={ButtonType.Primary}
          className="PcHeader_Controls_Login_Tap"
          href={getAccountsRegistrationLinkUrl(REGISTER_LOGIN.linkUrl, {
            utm_medium: 'ameba',
            utm_source: 'www.ameba.jp',
            utm_campaign: 'ameba_home-header_register_link',
          })}
          track={{ section_id: SECTION_ID, tap_id: REGISTER_LOGIN.key }}
          transparent
          size={ButtonSize.Small}
        >
          {REGISTER_LOGIN.text}
        </Button>
        <Button
          buttonType={ButtonType.Secondary}
          className="PcHeader_Controls_Login_Tap"
          href={LOGIN.linkUrl}
          track={{ section_id: SECTION_ID, tap_id: LOGIN.key }}
          transparent
          size={ButtonSize.Small}
        >
          {LOGIN.text}
        </Button>
      </div>
    );
  }

  renderLogout(isAmebaUser: boolean) {
    return (
      <div className="PcHeader_Controls_Logout clearfix">
        <PcHeaderSetting
          open={this.state.menu.setting}
          isAmebaUser={isAmebaUser}
          handleClick={() => this.handleToggle('setting')}
        />
        <Tap className="PcHeader_Control_Tap" to="/logout" transparent>
          ログアウト
        </Tap>
      </div>
    );
  }

  render() {
    const { router, isAmebaUser, isAuthenticated, dispatch } = this.props;
    const { menu } = this.state;
    const { location } = router;
    const topLinkUrl = isAuthenticated ? '/home' : '/';

    return (
      <header className="PcHeader">
        <div className="PcHeader_Inner">
          <h1 className="PcHeader_Logo">
            <Tap
              className="PcHeader_Logo_Link"
              to={topLinkUrl}
              disabled={location.pathname === topLinkUrl}
            >
              <img
                className="PcHeader_Logo_Image"
                src="/images/common/ameba_logo.svg"
                alt="Ameba"
                width="76"
                height="26"
              />
            </Tap>
          </h1>
          <nav className="PcHeader_Nav">
            <ul className="PcHeader_Nav_Wrap clearfix">
              <PcHeaderMenu
                {...MENU_BLOG}
                handleClick={
                  isAuthenticated && isAmebaUser
                    ? null
                    : () => dispatch(toggle())
                }
              />
              <PcHeaderMenu {...MENU_BLOG_GENRE} />
              <PcHeaderMenu {...MENU_BLOG_TALENT} />
              <PcHeaderMenu className="-border" {...MENU_AMEBA_NEWS} />
              {!isAuthenticated ? (
                <PcHeaderMenu {...MENU_DOKUSHO} />
              ) : (
                <PcHeaderMenu {...MENU_DOKUSHO_AUTHENTICATED} />
              )}
              <PcHeaderToggleMenu
                className="-center"
                {...MENU_PIGG}
                handleClick={() => this.handleToggle('pigg')}
                open={menu.pigg}
              />
              <PcHeaderToggleMenu
                className="-category"
                {...MENU_CATEGORY}
                handleClick={() => this.handleToggle('category')}
                open={menu.category}
              />
            </ul>
          </nav>
          <div className="PcHeader_Controls">
            <div className="PcHeader_Search">
              <PcSearchBox sectionId={SECTION_ID} />
            </div>
            {/* login / logout */}
            {!isAuthenticated
              ? this.renderLogin()
              : this.renderLogout(isAmebaUser)}
          </div>
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps)(PcHeader);

import React from 'react';
import Sprite from '../../../atoms/Sprite';
import Tap from '../../../atoms/Tap';
import { MenuApp, SECTION_ID } from './constants/MENU';

interface Props {
  app: MenuApp;
}

const PcHeaderMenuApp: React.FC<Props> = ({ app }) => {
  return (
    <li className="PcHeader_Menu_App clearfix">
      <Tap
        className="PcHeader_Menu_App_Link"
        href={app.linkUrl}
        target="_blank"
        track={{
          section_id: SECTION_ID,
          tap_id: app.tapId,
          service_id: app.serviceId,
        }}
      >
        <div className="PcHeader_Menu_App_Image">
          <Sprite iconClass={app.iconClass} />
        </div>
        <div className="PcHeader_Menu_App_Body">
          <p className="PcHeader_Menu_App_SubTitle">{app.subTitle}</p>
          <p className="PcHeader_Menu_App_Title">{app.title}</p>
        </div>
      </Tap>
    </li>
  );
};

export default PcHeaderMenuApp;

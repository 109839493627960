import React from 'react';
import cx from 'classnames';
import SearchIcon from '@openameba/spindle-ui/Icon/Search';
import Tap from '../../atoms/Tap';
import SearchBox from '../../atoms/SearchBox';
import { trackTap } from '../../../webApi/Tracker';

interface Props {
  sectionId: string;
}

/**
 * ブログ検索フォームのPCデザイン
 */
export default class PcSearchBox extends SearchBox<Props> {
  constructor(props) {
    super(props);
    this.handleSubmitWithTrack = this.handleSubmitWithTrack.bind(this);
  }
  handleSubmitWithTrack(e) {
    if (this.handleSubmit(e)) {
      trackTap({
        section_id: this.props.sectionId,
        tap_id: 'submit',
        content_ids: [this.state.searchValue],
      });
    }
  }
  render() {
    return (
      <form
        className={cx('PcSearchBox', {
          '-edited': this.state.edited,
        })}
        name="search"
        action={`${process.env.DOMAIN_SEARCH_AMEBA_URL}/search.html`}
        onSubmit={this.handleSubmitWithTrack}
      >
        <Tap className="PcSearchBox_Button" type="submit" aria-label="検索">
          <SearchIcon aria-hidden="true" className="PcSearchBox_Icon" />
        </Tap>
        <input
          className="PcSearchBox_Input"
          type="text"
          name="q"
          value={this.state.searchValue}
          placeholder="検索"
          aria-label="検索"
          onChange={this.handleChange}
        />
      </form>
    );
  }
}

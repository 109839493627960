import React from 'react';
import { LOGIN_URL } from '../../../../constants/urls/auth';
import { REGISTER_LOGIN } from '../../../../constants/amebaLogin';
import { getAccountsRegistrationLinkUrl } from '../../../../lib/UrlUtils';
import Button, { ButtonSize, ButtonType } from '../../../atoms/Button';
import { Modal } from '../../../blocks/Modal/Modal';

interface Props {
  isActive: boolean;
  isAuthenticated: boolean;
  onClickToggle(flag: boolean): void;
}

export class LoginDialog extends React.Component<Props> {
  static SECTION_ID = 'login-dialog';

  lastFocus: HTMLElement;
  elementRef: React.RefObject<HTMLDivElement>;

  constructor(props: Readonly<Props>) {
    super(props);
    this.elementRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.isActive) {
      this.lastFocus = document.activeElement as HTMLElement;
      this.elementRef.current && this.elementRef.current.focus();
      // Escキーでモーダルを閉じる
      window.addEventListener('keydown', this.handleKeyDown);
    } else {
      this.lastFocus && this.lastFocus.focus();
      this.dispose();
    }
  }

  componentWillUnmount() {
    this.dispose();
  }

  dispose = () => {
    window.removeEventListener('keydown', this.handleKeyDown);
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (
      this.props.isActive &&
      (e.key === 'Escape' || e.key === 'Esc') // If Legacy browser, sometimes key returns Esc.
    ) {
      this.handleToggleClose();
    }
  };

  handleToggleClose = () => {
    this.props.onClickToggle(false);
  };

  renderLoginDialog() {
    return (
      <>
        <p className="LoginDialog_Text">
          このコンテンツを使用するにはアメーバIDでログインする必要があります
        </p>
        <Button
          className="LoginDialog_Button"
          href={getAccountsRegistrationLinkUrl(REGISTER_LOGIN.linkUrl, {
            utm_medium: 'ameba',
            utm_source: 'www.ameba.jp',
            utm_campaign: 'ameba_home-blog_register_button',
          })}
          size={ButtonSize.Medium}
          buttonType={ButtonType.Primary}
          track={{
            section_id: LoginDialog.SECTION_ID,
            tap_id: 'register',
          }}
          transparent
        >
          アメーバIDを取得する
        </Button>
        <p className="LoginDialog_Text -login">
          すでにアカウントをお持ちの方はログインしてください
        </p>
        <Button
          className="LoginDialog_Button"
          href={LOGIN_URL}
          size={ButtonSize.Medium}
          buttonType={ButtonType.Secondary}
          track={{
            section_id: LoginDialog.SECTION_ID,
            tap_id: 'login',
          }}
          transparent
        >
          ログイン
        </Button>
      </>
    );
  }

  renderRegistrationDialog() {
    return (
      <>
        <p className="LoginDialog_Text">
          このコンテンツを使用するにはアメーバIDでログインする必要があります
        </p>
        <Button
          className="LoginDialog_Button"
          href={`${process.env.DOMAIN_AUTH_USER_URL}/settings`}
          size={ButtonSize.Medium}
          buttonType={ButtonType.Primary}
          track={{
            section_id: LoginDialog.SECTION_ID,
            tap_id: 'register-ameba-id',
          }}
          transparent
        >
          アメーバIDを取得する
        </Button>
      </>
    );
  }

  render() {
    const { isActive, isAuthenticated } = this.props;
    return (
      <Modal
        title="このコンテンツを使用するにはアメーバIDが必要です"
        open={isActive}
        handleClose={this.handleToggleClose}
        track={{
          section_id: LoginDialog.SECTION_ID,
        }}
      >
        <div className="LoginDialog" ref={this.elementRef} tabIndex={-1}>
          {isAuthenticated
            ? this.renderRegistrationDialog()
            : this.renderLoginDialog()}
        </div>
      </Modal>
    );
  }
}

import { Request } from 'express';
import { SnackBarType } from '../../../constants/snackBar';
import {
  createGenreFollowData,
  deleteGenreFollowData,
  readGenreData,
  readGenreFollowData,
  updateGenreFollowData,
} from '../../../fetchr/services/HomeDesktop/HomeDesktop/HomeDesktopShared';
import { DAY } from '../../../lib/DateUtils';
import {
  createAction,
  handleActions,
  ThunkActionCreator,
} from '../../../lib/ReduxUtils';
import { BlogExternalGenreSchema } from '../../../services/blog/response/BlogExternalApiService';
import { StructuredGenreSchema } from '../../../services/blog/response/BlogGenreService';
import { GenreBase } from '../../../services/blog/entity/GenreBase';
import { ClientStorage } from '../../../webApi/ClientStorage';
import { pushMessage } from '../../AppShellDesktop/SnackBar';
import { initializeFeedByGenreCodes } from '../HomeBlogFeed';

export interface FollowRecommendGenre {
  code: string;
  title: string;
  icon: string;
}

export interface State {
  follows: BlogExternalGenreSchema[];
  genres: StructuredGenreSchema<GenreBase>;
  genreFollowRecommend: FollowRecommendGenre;
}

type Payload = Partial<State>;

const initialState: State = {
  follows: null,
  genres: null,
  genreFollowRecommend: null,
};

const ERROR_MESSAGE = '更新に失敗しました';
const SUCCESS_MESSAGE = 'ジャンルの更新が完了しました';

export const FETCH_FOLLOW_GENRES = 'HomeDesktop/HomeGenre/FETCH_FOLLOW_GENRES';
export const fetchFollowGenres = createAction<Payload>(
  FETCH_FOLLOW_GENRES,
  async (req?: Request) => {
    const { data: follows } = await readGenreFollowData(req);
    return { follows };
  },
);

export const EMPTY_FOLLOW_GENRE = 'HomeDesktop/HomeGenre/EMPTY_FOLLOW_GENRE';
export const emptyFollowGenres = createAction<Payload>(
  EMPTY_FOLLOW_GENRE,
  () => {
    return {};
  },
);

export const FOLLOW_GENRE = 'HomeDesktop/HomeGenre/FOLLOW_GENRE';
export const followGenre = createAction<Payload>(
  FOLLOW_GENRE,
  async (code: string) => {
    const { data: follows } = await createGenreFollowData(null, code);
    return { follows };
  },
);

export const UNFOLLOW_GENRE = 'HomeDesktop/HomeGenre/UNFOLLOW_GENRE';
export const unfollowGenre = createAction<Payload>(
  UNFOLLOW_GENRE,
  async (code: string) => {
    const { data: follows } = await deleteGenreFollowData(null, code);
    return { follows };
  },
);

export const SORT_FOLLOW_GENRES = 'HomeDesktop/HomeGenre/SORT_FOLLOW_GENRES';
export const sortFollowGenres = createAction<Payload>(
  SORT_FOLLOW_GENRES,
  async (codes: string[]) => {
    const { data: follows } = await updateGenreFollowData(null, codes);
    return { follows };
  },
);

export const FETCH_GENRES = 'HomeDesktop/HomeGenre/FETCH_GENRES';
export const fetchGenres = createAction<Payload>(
  FETCH_GENRES,
  async (req?: Request) => {
    const { data: genres } = await readGenreData(req);
    return { genres };
  },
);

export const SET_RECOMMEND_GENRE = 'HomeDesktop/HomeGenre/SET_RECOMMEND_GENRE';
export const setRecommendGenre = createAction<Payload>(
  SET_RECOMMEND_GENRE,
  (genre: FollowRecommendGenre) => {
    return {
      genreFollowRecommend: genre,
    };
  },
);

export const handleClickGenre: ThunkActionCreator = (
  genre: FollowRecommendGenre,
) => {
  return (dispatch, getState) => {
    // Server validation
    // サーバーの時
    if (typeof window === 'undefined') {
      return;
    }

    const { homeGenre, loginInfo } = getState();
    const { follows, genreFollowRecommend } = homeGenre;

    // すでにsetされている時
    if (genreFollowRecommend) {
      return;
    }

    if (!loginInfo || !loginInfo.user || !loginInfo.user.amembId) {
      return;
    }

    // まだフォローを取得していない時
    if (!follows) {
      return;
    }

    // フォロー件数が20件以上の時
    if (follows.length >= 20) {
      return;
    }

    // 該当ジャンルをフォローしている時
    if (follows.map((item) => item.code).indexOf(genre.code) > -1) {
      return;
    }

    const STORAGE_NAMESPACE = 'amb_home_genre_date';
    const storage = new ClientStorage(STORAGE_NAMESPACE);
    const now = new Date();
    const cache = storage.getItem(genre.code);
    if (cache) {
      const cachedDate = new Date(cache);
      const diff = now.getTime() - cachedDate.getTime();
      if (diff < DAY) {
        // 直近のクリックが1日以内
        // 値をset
        dispatch(setRecommendGenre(genre));
        // 初期化
        storage.removeItem(genre.code);
        return;
      }
    }

    // クリック時間を保存
    storage.setItem(genre.code, now.getTime());
  };
};

export const fetchGenresIfNeeded: ThunkActionCreator = (req?: Request) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (!state.homeGenre.genres) {
      await dispatch(fetchGenres(req));
    }
  };
};

export const fetchGenreFollowsIfNeeded: ThunkActionCreator = (
  req?: Request,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (!state.homeGenre.follows) {
      try {
        if (state.loginInfo.user.amebaId) {
          await dispatch(fetchFollowGenres(req));
        } else {
          dispatch(emptyFollowGenres());
        }
      } catch {
        //
      }

      try {
        const { homeGenre } = getState();
        const codes = homeGenre.follows.map((genre) => genre.code);
        await dispatch(initializeFeedByGenreCodes(req, codes));
      } catch {
        //
      }
    }
  };
};

export const followGenreWithNotify: ThunkActionCreator = (
  code: string,
  follow: boolean,
) => {
  return async (dispatch) => {
    try {
      if (follow) {
        await dispatch(followGenre(code));
      } else {
        await dispatch(unfollowGenre(code));
      }
    } catch {
      dispatch(pushMessage(ERROR_MESSAGE, SnackBarType.ERROR));
    }
  };
};

export const sortGenreWithNotify: ThunkActionCreator = (codes: string[]) => {
  return async (dispatch) => {
    try {
      await dispatch(sortFollowGenres(codes));
      dispatch(pushMessage(SUCCESS_MESSAGE, SnackBarType.SUCCESS));
    } catch {
      dispatch(pushMessage(ERROR_MESSAGE, SnackBarType.ERROR));
    }
  };
};

export const homeGenre = handleActions<State, Payload>(
  {
    [FETCH_FOLLOW_GENRES](state, { payload, error }) {
      if (error) {
        return {
          ...state,
          follows: state.follows || [],
        };
      }
      return {
        ...state,
        follows: payload.follows,
      };
    },
    [EMPTY_FOLLOW_GENRE](state) {
      return {
        ...state,
        follows: [],
      };
    },
    [FOLLOW_GENRE](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        follows: payload.follows,
      };
    },
    [UNFOLLOW_GENRE](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        follows: payload.follows,
      };
    },
    [SORT_FOLLOW_GENRES](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        follows: payload.follows,
      };
    },
    [FETCH_GENRES](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        genres: payload.genres,
      };
    },
    [SET_RECOMMEND_GENRE](state, { payload }) {
      return {
        ...state,
        genreFollowRecommend: payload.genreFollowRecommend,
      };
    },
  },
  initialState,
);

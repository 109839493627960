import { create } from '../../FetchrConfigFactory';

import {
  NotiReceiverListPayload,
  NotiReceiverHomeListPayload,
  UpdateNotiReceiverPayload,
} from '../../../../services/platform/response/PlatformApiResponse';

export const notificationsConfig = create('notifications/noti_receiver');
export interface NotifiationsReadPayload {
  notifications: NotiReceiverListPayload;
}

export const notificationsHomeConfig = create(
  'notifications/noti_receiver_home',
);
export interface NotifiationsReadHomePayload {
  notifications: NotiReceiverHomeListPayload;
}

export const notificationUpdateConfig = create(
  'notification/noti_receiver/update',
);
export interface NotifiationReadUpdatePayload {
  notificationUpdate: UpdateNotiReceiverPayload;
}

export const notificationsUpdateConfig = create(
  'notifications/noti_receiver/update',
);
export interface NotifiationsReadUpdatePayload {
  notificationsUpdate: UpdateNotiReceiverPayload;
}

import { CardCollectionEntity } from '../../../../services/_pc/entity/CardCollectionEntity';
import { create } from '../../FetchrConfigFactory';

export const blogFeedConfig = create('home_desktop/blog_feed');
export type BlogFeedReadPayload = CardCollectionEntity[];

export const blogFeedInitializeConfig = create(
  'home_desktop/blog_feed/initialize',
);
export type BlogFeedInitializeReadPayload = string[];

import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../../lib/ReactRouterUtils';
import { getAsyncComponent } from '../../../../lib/ComponentUtils';
import { setTitle } from '../../../../contexts/AppShell/SpHeader';
import { fetchNotificationsSettings } from '../../../../contexts/Settings/SettingsNotifications';
import { fetchConnect } from '../../../../contexts/Settings/Connect';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('設定', 'other'));
  },
  async clientLoader({ dispatch }) {
    await dispatch(fetchNotificationsSettings());
    await dispatch(fetchConnect());
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./SettingsNotificationsDesktop'))),
);

import { produce } from 'immer';
import get from 'lodash/get';
import * as NotificationsService from '../../../fetchr/services/Settings/Notifications/NotificationsShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';
import { AmebaDeliverySystemGetUserAsAsIdMailSettingResponse } from '../../../types/apis/ameba-delivery-system';
import { trackTap } from '../../../webApi/Tracker';

type NotificationsCategoryId =
  | 'campaign'
  | 'minigraph_22288139'
  | 'minigraph_5543072250'
  | 'minigraph_8633993418'
  | 'minigraph_16450741'
  | 'minigraph_33855233';

export interface State {
  notifications: AmebaDeliverySystemGetUserAsAsIdMailSettingResponse;
  allId: NotificationsCategoryId[];
  appIds: NotificationsCategoryId[];
  label: { [x in NotificationsCategoryId]: string };
}

interface Payload {
  notifications?: AmebaDeliverySystemGetUserAsAsIdMailSettingResponse;
}

const initialState: State = {
  notifications: null,
  allId: ['campaign'],
  appIds: [
    'minigraph_22288139',
    'minigraph_5543072250',
    'minigraph_8633993418',
    'minigraph_16450741',
    'minigraph_33855233',
  ],
  label: {
    campaign: 'アメーバからのお知らせ',
    minigraph_22288139: 'オウンド(deka-stg)',
    minigraph_5543072250: 'Amebaマンガ',
    minigraph_8633993418: 'Ameba Ownd',
    minigraph_16450741: 'モグ',
    minigraph_33855233: 'ガールフレンド（仮）',
  },
};

export const FETCH_NOTIFICATIONS_SETTINGS =
  'contexts/Settings/SettingsNotifications/FETCH_NOTIFICATIONS_SETTINGS';
export const UPDATE_NOTIFICATIONS_SETTINGS =
  'contexts/Settings/SettingsNotifications/UPDATE_NOTIFICATIONS_SETTINGS';
export const FETCH_NOTIFICATIONS_FAILURE =
  'contexts/Settings/SettingsNotifications/FETCH_NOTIFICATIONS_FAILURE';

export const fetchNotificationsSettings: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    try {
      let notifications = {};
      const { data } = await NotificationsService.getNotificationsSettings(req);
      if (data) {
        const mailSetting = get(data, ['mailSetting'], {});
        const mailGroupsSetting = get(data, ['mailGroupsSetting'], {});

        notifications = {
          accept: get(mailSetting, ['accept']),
          categories: mailGroupsSetting,
        };
      }

      dispatch({
        type: FETCH_NOTIFICATIONS_SETTINGS,
        payload: { notifications },
      });
    } catch {
      dispatch({ type: FETCH_NOTIFICATIONS_FAILURE });
    }
  };
};

export const updateNotificationsSettings: ThunkActionCreator = (
  req?,
  body?,
  track?,
) => {
  return async (dispatch) => {
    try {
      trackTap(track);
      await NotificationsService.updateNotificationsSettings(req, body);
      dispatch({
        type: UPDATE_NOTIFICATIONS_SETTINGS,
        payload: { notifications: body },
      });
    } catch {
      dispatch({ type: FETCH_NOTIFICATIONS_FAILURE });
    }
  };
};

export const settingsNotifications = handleActions<State, Payload>(
  {
    [FETCH_NOTIFICATIONS_SETTINGS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.notifications = get(payload, ['notifications']);
      }),
    [UPDATE_NOTIFICATIONS_SETTINGS]: (state, { payload }) =>
      produce(state, (draft) => {
        const { accept, categories } = get(payload, ['notifications']);

        if (categories) {
          draft.notifications.categories = {
            ...state.notifications.categories,
            ...categories,
          };
        } else {
          draft.notifications.accept = accept;
        }
      }),
  },
  initialState,
);

import { LoggerImpl, LogLevel, LogType } from '@ca-media-nantes/logger';

const logger = new LoggerImpl({
  logType: LogType.App,
  service: 'venice-web',
  appVersion: process.env.APP_VERSION || 'unknown',
  logLevel: (process.env.LOG_LEVEL as LogLevel) || LogLevel.Info,
  pretty: process.env.NODE_ENV !== 'production',
});

export default logger;

import React from 'react';
import Icon, { IconTypes } from '../../atoms/Icon';
import Tap from '../../atoms/Tap';
import { TrackableViewport } from '../../atoms/TrackableViewport';

export interface Props {
  title?: string;
  open: boolean;
  track?: {
    section_id: string;
    content_ids?: string[];
  };

  handleClose(): void;

  children: React.ReactNode;
}

export class Modal extends React.Component<Props> {
  static get defaultProps() {
    return {
      track: {
        section_id: 'modal',
      },
    };
  }

  render() {
    if (!this.props.open) {
      return null;
    }

    const { title, children, track, handleClose } = this.props;

    return (
      <div className="Modal">
        <Tap
          className="Modal_Overlay"
          track={{ ...track, tap_id: 'overlay-close' }}
          onClick={handleClose}
        />
        <div className="Modal_Container" role="dialog">
          <div className="Modal_Inner" role="document">
            {/* 閉じるボタン */}
            <Tap
              className="Modal_Close"
              track={{ ...track, tap_id: 'close' }}
              onClick={handleClose}
              transparent
            >
              <Icon
                aria-label="モーダルウィンドウを閉じる"
                iconClass={IconTypes.Close}
              />
            </Tap>
            {/* モーダルの中身 */}
            <TrackableViewport
              className="Modal_Body"
              track={{ ...track, tap_id: 'view' }}
            >
              {title && <p className="Modal_Title">{title}</p>}
              {children}
            </TrackableViewport>
          </div>
        </div>
      </div>
    );
  }
}

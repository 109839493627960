import React from 'react';
import cx from 'classnames';
import { scrollTo } from '../../../../lib/ScrollUtils';
import { getScrollPosition } from '../../../../lib/RectUtils';
import Tap from '../../../atoms/Tap';
import Icon, { IconTypes } from '../../../atoms/Icon';

const POINT_Y = 350;

/**
 * 上に戻るボタン
 * はじめは隠れているが、Topから350pxスクロールすると現れる
 * タップでページTOPへスクロール移動する
 */
const JumpTop: React.FC = () => {
  const [shouldShowButton, setShouldShowButton] = React.useState(false);
  const handleClick = () => {
    const element = document.documentElement.scrollTop
      ? document.documentElement
      : document.body;
    scrollTo(element, 0);
  };
  React.useEffect(() => {
    const handleScroll = () => {
      const _shouldShowButton = getScrollPosition().y > POINT_Y;
      setShouldShowButton(_shouldShowButton);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Tap
      className={cx('JumpTop', {
        '-show': shouldShowButton,
      })}
      onClick={handleClick}
      aria-label="ページトップへ"
      aria-hidden={!shouldShowButton}
    >
      <Icon iconClass={IconTypes.ArrowUp} className="JumpTop_Icon" />
    </Tap>
  );
};

export default JumpTop;

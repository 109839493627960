import React from 'react';
import cx from 'classnames';
import { SnackBarType } from '../../../constants/snackBar';
import Icon, { IconTypes } from '../Icon';

interface Props {
  isOpen: boolean;
  message: string;
  offset?: number;
  type: SnackBarType;
  autoHideDuration?: number;
  className?: string;
  onClick?(): void;
  onRequestClose?(): void;
}

export class SnackBar extends React.Component<Props> {
  static Type = SnackBarType;
  private timer;
  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.startTimeout();
      } else {
        this.clearTimeout();
      }
    }
  }
  startTimeout() {
    const { autoHideDuration, onRequestClose } = this.props;
    this.timer = setTimeout(() => {
      onRequestClose && onRequestClose();
    }, autoHideDuration || 3000);
  }
  clearTimeout() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
  renderIcon() {
    const { type } = this.props;
    let iconType;
    switch (type) {
      case SnackBarType.SUCCESS:
        iconType = IconTypes.Check;
        break;
      case SnackBarType.ERROR:
        iconType = IconTypes.Close;
        break;
    }
    return (
      <span className={`SnackBar_Icon -${type}`}>
        <Icon iconClass={iconType} />
      </span>
    );
  }
  render() {
    const { message, offset, isOpen, onRequestClose } = this.props;
    return (
      <div
        aria-hidden={!isOpen}
        className={cx('SnackBar_Container', { '-open': isOpen })}
        style={{ bottom: isOpen && offset ? offset : 0 }}
      >
        <div className="SnackBar" onClick={onRequestClose} role="button">
          {this.renderIcon()}
          {message}
        </div>
      </div>
    );
  }
}

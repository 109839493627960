import { AsyncStatus } from '../../../../lib/ReduxUtils';
import AmebaMoneyInfo from '../../../../services/_common/entity/AmebaMoneyInfo';
import { MainTopicEntity } from '../../../../services/_common/entity/MainTopicEntity';
import { MediaJackEntity } from '../../../../services/_pc/entity/MediaJackEntity';
import { AdcrossAdSchema } from '../../../../services/adcross/response/AdcrossService';
import { AmebaRssEntry } from '../../../../services/amebaRss/response/AmebaRssEntry';
import BlogTag from '../../../../services/blog/entity/BlogTag';
import { ChecklistModuleEntity } from '../../../../services/blog/entity/ChecklistModuleEntity';
import { GenreBase } from '../../../../services/blog/entity/GenreBase';
import { BlogListItemSchema } from '../../../../services/blog/response/BlogCommon';
import { BlogExternalGenreSchema } from '../../../../services/blog/response/BlogExternalApiService';
import { StructuredGenreSchema } from '../../../../services/blog/response/BlogGenreService';
import MangafeedNewlyEntity from '../../../../services/dokusho/entity/MangafeedNewly';
import {
  MangaCouponSchema,
  MangafeedRecommendSchema,
  MangafeedSerialSchema,
} from '../../../../services/dokusho/response/MangaService';
import { PurchaseBonusPayload } from '../../../../services/snsApi/response/PurchaseService';
import { MypageApiMaintenanceText } from '../../../../types/apis/mypage-api';
import { BlogAnalysisTwoDays } from '../../../../types/type/blog-analysis';
import { BlogHistory } from '../../../../types/type/blog-history';
import { BlogRecentlyCommented } from '../../../../types/type/blog-recently-commented';
import { create } from '../../FetchrConfigFactory';

export const initialConfig = create('home_desktop/initial');

export interface InitialReadPayload {
  mediaJack: MediaJackEntity;
  staffBlog: AmebaRssEntry[];
  maintenanceText: MypageApiMaintenanceText[];
  topics: MainTopicEntity[];
}

export const lazyConfig = create('home_desktop/lazy');

export interface LazyReadPayload {
  blogBanners: AdcrossAdSchema[];
  campaignBanners: AdcrossAdSchema[];
}

export const accessAnalysisConfig = create('home_desktop/access_count');
export type AccessAnalysisReadPayload = BlogAnalysisTwoDays;

export const coinModuleConfig = create('home_desktop/coin_info');

export interface CoinModuleReadPayload {
  coinInfo: AmebaMoneyInfo;
  coinCampaign: PurchaseBonusPayload;
}

export const moneyModuleConfig = create('home_desktop/money_info');

export interface MoneyModuleReadPayload {
  moneyInfo: AmebaMoneyInfo;
}

export const checklistConfig = create('home_desktop/checklist');

export interface ChecklistReadPayload extends Partial<ChecklistModuleEntity> {
  recommends?: BlogListItemSchema[];
}

export const blogHistoryConfig = create('home_desktop/blog_history');
export type BlogHistoryReadPayload = BlogHistory[];

export const mangafeedConfig = create('home_desktop/mangafeed');

export interface MangafeedReadPayload {
  coupon: MangaCouponSchema;
  newly: MangafeedNewlyEntity[];
  recommend: MangafeedRecommendSchema;
  serial: MangafeedSerialReadPayload;
}

export const mangafeedSerialConfig = create('home_desktop/mangafeed_serial');

export interface MangafeedSerialReadPayload extends MangafeedSerialSchema {
  status: AsyncStatus;
  offset?: number;
}

export const blogHashTagConfig = create('home_desktop/blog_hashtag');
export type BlogHashTagReadPayload = BlogTag[];

export const blogCommentConfig = create('home_desktop/blog_comment');
export type BlogCommentReadPayload = BlogRecentlyCommented[];

export const genreConfig = create('home_desktop/genre');
export type GenreReadPayload = StructuredGenreSchema<GenreBase>;

export const genreFollowConfig = create('home_desktop/genre_follow');
export type GenreFollowReadPayload = BlogExternalGenreSchema[];
export type GenreFollowCreatePayload = BlogExternalGenreSchema[];
export type GenreFollowUpdatePayload = BlogExternalGenreSchema[];
export type GenreFollowDeletePayload = BlogExternalGenreSchema[];

import { createStore, applyMiddleware, Store } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createReducer from '../contexts/createReducer';
import { asyncMiddleware, pageViewMiddleware } from '../lib/ReduxUtils';

export type AsyncReducer = (state: any, action: any) => any;

export interface AsyncReducerMap {
  [x: string]: AsyncReducer;
}

export interface ExStore extends Store<any> {
  asyncReducerMap?: AsyncReducerMap;
  injectReducer?(name: string, reducer: AsyncReducer): void;
}

const ASYNC_INIT = '@@async-init';

export default function configureStore(
  history,
  initialState?,
  reducers = {},
): ExStore {
  const preloadedReducers = {
    ...reducers,
  };
  const rootReducer = createReducer(history, preloadedReducers);

  const middlewares = [thunk, asyncMiddleware];

  middlewares.push(routerMiddleware(history));

  if (typeof window !== 'undefined') {
    middlewares.push(pageViewMiddleware);
  }

  if (process.env.NODE_ENV !== 'production' && process.env.BROWSER_ENV) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    middlewares.push(require('redux-logger')['default']);
  }

  const store: ExStore = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares),
  );

  // extends store
  store.asyncReducerMap = {}; // registry of async reducer
  store.injectReducer = (name, reducer) => {
    if ('asyncReducerMap' in store) {
      const rootReducer = createReducer(history, preloadedReducers);
      store.asyncReducerMap[name] = reducer;
      store.replaceReducer(
        createReducer({
          ...rootReducer,
          ...store.asyncReducerMap,
        }),
      );
      store.dispatch({ type: ASYNC_INIT });
    }
  };

  return store;
}

export function isServer() {
  return typeof window === 'undefined';
}

function isSpecificBrowser() {
  const isOpera =
    navigator.userAgent.toLowerCase().indexOf('opera') + 1 ? 1 : 0;
  const isSafari =
    navigator.appVersion.toLowerCase().indexOf('safari') + 1 ? 1 : 0;
  return isSafari || isOpera;
}

export function getScrollPosition() {
  const obj: any = {};

  if (isServer()) {
    return obj;
  }

  obj.x = document.documentElement.scrollLeft || document.body.scrollLeft;
  obj.y = document.documentElement.scrollTop || document.body.scrollTop;

  return obj;
}

export function getScreenSize() {
  const obj: any = {};

  if (isServer()) {
    return obj;
  }

  if (isSpecificBrowser()) {
    // call to fix viewport size on ios 9.x later
    document.documentElement.clientWidth;
    obj.width = window.innerWidth;
    obj.height = window.innerHeight;
  } else {
    obj.width =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      document.body.scrollWidth;
    obj.height =
      document.documentElement.clientHeight ||
      document.body.clientHeight ||
      document.body.scrollHeight;
  }

  return obj;
}

export function getDocumentSize() {
  const obj: any = {};

  if (isServer()) {
    return obj;
  }

  obj.width = document.documentElement.scrollWidth || document.body.scrollWidth;
  obj.height =
    document.documentElement.scrollHeight || document.body.scrollHeight;

  return obj;
}

export function getBoundingClientRect(el: HTMLElement | Element) {
  let rect: DOMRect = el.getBoundingClientRect();

  if (!rect) {
    return;
  }

  // older IE
  if (!rect.width || !rect.height) {
    rect = {
      ...rect,
      width: rect.right - rect.left,
      height: rect.bottom - rect.top,
    };
  }

  return rect;
}

/**
 * ModuleLoadingError
 * ローディングエラー表現
 */
import React, { FC } from 'react';

type Props = {
  moduleType?: 'page' | 'module' | 'mini' | 'free';
};

export const LoadableFrameError: FC<Props> = ({ moduleType }) => {
  return (
    <div className="LoadableFrame_Error">
      データの取得に失敗しました。
      {moduleType !== 'free' && <br />}
      {moduleType !== 'mini' && (
        <span className="LoadableFrame_Error_Text">
          再読込をお試しください。
        </span>
      )}
    </div>
  );
};

import { produce } from 'immer';
import get from 'lodash/get';
import { getCopyrightSearch } from '../../../fetchr/services/Copyright/Search/SearchShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';

import {
  CurrntSongItem,
  VideoListItem,
} from '../../../services/amebaPlatformApi/response/AmebaPlatformApiService';

const SONG_LIST_LIMIT = 20;

interface State {
  songList: VideoListItem[];
  currentSongItem: CurrntSongItem;
  offset: number;
  isLoading: boolean;
  paging: boolean;
}

interface Payload {
  songList: VideoListItem[];
  currentSongItem: CurrntSongItem;
  offset: number;
  isLoading: boolean;
  paging: boolean;
}

const initialState = {
  songList: [],
  currentSongItem: null,
  offset: null,
  isLoading: true,
  paging: false,
};

// TODO: 更新のACTIONを作成
export const FETCH_COPYRIGHT_SEARCH_REQUEST =
  'contexts/Copyright/FETCH_COPYRIGHT_SEARCH_REQUEST';
export const FETCH_COPYRIGHT_SEARCH_SUCCESS =
  'contexts/Copyright/FETCH_COPYRIGHT_SEARCH_SUCCESS';
export const FETCH_COPYRIGHT_SEARCH_UPDATE =
  'contexts/Copyright/FETCH_COPYRIGHT_SEARCH_UPDATE';
export const FETCH_COPYRIGHT_SEARCH_FAILURE =
  'contexts/Copyright/FETCH_COPYRIGHT_SEARCH_FAILURE';
export const POST_COPYRIGHT_SONG = 'contexts/Copyright/POST_COPYRIGHT_SONG';

export const fetchCopyrightSearch: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    try {
      const { data } = await getCopyrightSearch(null, req);
      dispatch({ type: FETCH_COPYRIGHT_SEARCH_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_COPYRIGHT_SEARCH_FAILURE });
    }
  };
};

export const updateCopyrightSearch: ThunkActionCreator = (value?, offset?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COPYRIGHT_SEARCH_REQUEST });
    try {
      const { data } = await getCopyrightSearch(null, value, offset);
      dispatch({ type: FETCH_COPYRIGHT_SEARCH_UPDATE, payload: data });
    } catch {
      dispatch({ type: FETCH_COPYRIGHT_SEARCH_FAILURE });
    }
  };
};

export const postCopyrightSong = (currentSongItem) => ({
  type: POST_COPYRIGHT_SONG,
  payload: {
    currentSongItem,
  },
});

export const copyrightSearch = handleActions<State, Payload>(
  {
    [FETCH_COPYRIGHT_SEARCH_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
    [FETCH_COPYRIGHT_SEARCH_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_COPYRIGHT_SEARCH_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const songList = get(payload, 'songList');
        draft.songList = songList;

        if (SONG_LIST_LIMIT === songList.length) {
          draft.paging = true;
        } else {
          draft.paging = false;
        }

        draft.offset = songList.length;
        draft.isLoading = false;
      }),
    [FETCH_COPYRIGHT_SEARCH_UPDATE]: (state, { payload }) =>
      produce(state, (draft) => {
        const data = get(payload, 'songList', []);

        if (SONG_LIST_LIMIT === data.length) {
          draft.paging = true;
        } else {
          draft.paging = false;
        }

        draft.songList.push(...data);
        draft.offset = draft.songList.length;
        draft.isLoading = false;
      }),
    [POST_COPYRIGHT_SONG]: (state, { payload }) =>
      produce(state, (draft) => {
        const currentSongItem = get(payload, 'currentSongItem');
        draft.currentSongItem = currentSongItem;
      }),
  },
  initialState,
);

import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  AMEBA_RULES,
  AMEBA_HELP,
  AMEBA_INQUIRY,
  AMEBA_SAFE,
  COMPANY_OVERVIEW,
  AMEBA_ADVERTISE,
  PRIVACY_POLICY,
  INVESTMENT_POLICY,
  AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS,
  ACCESS_DATA,
  AMEBA_PROFILE,
} from '../../../../constants/amebaLinks';
import { ROUTE_NAMES } from '../../../../constants/routes';
import Sprite, { SpriteTypes } from '../../../atoms/Sprite';

import Tap from '../../../atoms/Tap';

const FOOTER_LINKS = [
  INVESTMENT_POLICY,
  PRIVACY_POLICY,
  AMEBA_SAFE,
  COMPANY_OVERVIEW,
  AMEBA_ADVERTISE,
];

const CORPORATE_LINKS = [
  AMEBA_INQUIRY,
  AMEBA_RULES,
  ACCESS_DATA,
  AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS,
  AMEBA_HELP,
];

const mapStateToProps = (props) => {
  const pathname = get(props, 'router.location.pathname') || '';

  return {
    isTop: pathname === ROUTE_NAMES.top,
  };
};

interface Props {
  isTop?: boolean;
}

export const PcFooter: React.FC<Props> = ({ isTop = false }) => (
  <div className="PcFooter">
    <div className="PcFooter_Inner">
      {/* Link集 */}
      <ul className="PcFooter_Links">
        {FOOTER_LINKS.map((link) => (
          <li
            className="PcFooter_Links_Item"
            key={`pc-footer-links-${link.key}`}
          >
            <Tap href={link.linkUrl} target="_blank" transparent>
              {link.title}
            </Tap>
          </li>
        ))}
        {isTop && (
          <li
            className="PcFooter_Links_Item"
            key={`pc-footer-links-${AMEBA_PROFILE.key}`}
          >
            <Tap href={AMEBA_PROFILE.linkUrl} transparent>
              {AMEBA_PROFILE.title}
            </Tap>
          </li>
        )}
      </ul>
    </div>
    <div className="PcFooter_Corporate">
      <div className="PcFooter_Corporate_Inner">
        <p className="PcFooter_Copyright">
          Copyright © CyberAgent, Inc. All Rights Reserved.
          <a
            href="https://www.cyberagent.co.jp/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/organizers/AppShellDesktop/PcFooter/logo_cyber.png"
              alt="CyberAgent, Inc."
            />
          </a>
        </p>
        <ul className="PcFooter_Corporate_Links">
          {CORPORATE_LINKS.map((link) => (
            <li
              className="PcFooter_Corporate_Link"
              key={`pc-footer-corporate-${link.key}`}
            >
              <Tap href={link.linkUrl} target="_blank" transparent>
                <Sprite
                  iconClass={SpriteTypes.FooterExternalLink}
                  className="PcFooter_Corporate_LinkIcon"
                />
                {link.title}
              </Tap>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default connect(mapStateToProps)(PcFooter);

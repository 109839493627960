import { setTitle } from '../../../../contexts/AppShell/SpHeader';
import { fetchAppDetail } from '../../../../contexts/App/Detail';
import { getAsyncComponent } from '../../../../lib/ComponentUtils';
import { composeRoute } from '../../../../lib/ReactRouterUtils';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('アプリ詳細', 'other'));
  },
  async clientLoader({ dispatch }, { params }) {
    dispatch(fetchAppDetail(params));
  },
})(getAsyncComponent(() => import('./AppDetail')));

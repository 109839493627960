// Factory

export interface FetchrBase {
  name: string;
}

export function create(name: string): FetchrBase {
  return {
    name,
  };
}

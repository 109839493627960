import { ButtonType } from '../components/atoms/Button';
import { LOGIN_URL, REGISTER_URL } from './urls/auth';

/**
 * ログインデータのinterface
 * buttonTypeは基本デザイン(使わないこともある)
 */
interface AmebaLoginProps {
  key: string;
  linkUrl: string;
  text: string;
  buttonType?: ButtonType;
}

/**
 * アカウント持っている人のログイン先
 */
export const LOGIN: AmebaLoginProps = {
  key: 'login-link',
  linkUrl: LOGIN_URL,
  text: 'ログイン',
  buttonType: ButtonType.Primary,
};

/**
 * 新規登録
 */
export const REGISTER_LOGIN: AmebaLoginProps = {
  key: 'register-link',
  linkUrl: REGISTER_URL,
  text: '新規登録',
  buttonType: ButtonType.Primary,
};

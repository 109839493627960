import { produce } from 'immer';
import get from 'lodash/get';
import { getCopyrightVideo } from '../../../fetchr/services/Copyright/Video/VideoShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';

import {
  CurrntVideoItem,
  VideoListItem,
} from '../../../services/amebaPlatformApi/response/AmebaPlatformApiService';

const VIDEO_LIST_LIMIT = 20;

interface State {
  videoList: VideoListItem[];
  currentVideoItem: CurrntVideoItem;
  offset: number;
  isLoading: boolean;
  isUpdating: boolean;
  isRedirect: boolean;
  paging: boolean;
}

interface Payload {
  videoList: VideoListItem[];
  currentVideoItem: CurrntVideoItem;
  offset: number;
  isLoading: boolean;
  isUpdating: boolean;
  isRedirect: boolean;
  paging: boolean;
}

const initialState = {
  videoList: [],
  currentVideoItem: null,
  offset: null,
  isLoading: true,
  isUpdating: false,
  isRedirect: true,
  paging: true,
};

// TODO: 更新のACTIONを作成
export const FETCH_COPYRIGHT_VIDEO_REQUEST =
  'contexts/Copyright/FETCH_COPYRIGHT_VIDEO_REQUEST';
export const FETCH_COPYRIGHT_VIDEO_SUCCESS =
  'contexts/Copyright/FETCH_COPYRIGHT_VIDEO_SUCCESS';
export const FETCH_COPYRIGHT_VIDEO_UPDATE_REQUEST =
  'contexts/Copyright/FETCH_COPYRIGHT_VIDEO_UPDATE_REQUEST';
export const FETCH_COPYRIGHT_VIDEO_UPDATE =
  'contexts/Copyright/FETCH_COPYRIGHT_VIDEO_UPDATE';
export const FETCH_COPYRIGHT_VIDEO_FAILURE =
  'contexts/Copyright/FETCH_COPYRIGHT_VIDEO_FAILURE';
export const POST_COPYRIGHT_VIDEO = 'contexts/Copyright/POST_COPYRIGHT_VIDEO';
export const ROUTE_COPYRIGHT = 'contexts/Copyright/ROUTE_COPYRIGHT';

export const fetchCopyrightVideo: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COPYRIGHT_VIDEO_REQUEST });
    try {
      const { data } = await getCopyrightVideo(req);
      dispatch({ type: FETCH_COPYRIGHT_VIDEO_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_COPYRIGHT_VIDEO_FAILURE });
    }
  };
};

export const updateCopyrightVideo: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COPYRIGHT_VIDEO_UPDATE_REQUEST });
    try {
      const { data } = await getCopyrightVideo(null, req);
      dispatch({ type: FETCH_COPYRIGHT_VIDEO_UPDATE, payload: data });
    } catch {
      dispatch({ type: FETCH_COPYRIGHT_VIDEO_FAILURE });
    }
  };
};

export const postCopyrightVideo = (currentVideoItem) => ({
  type: POST_COPYRIGHT_VIDEO,
  payload: {
    currentVideoItem,
  },
});

export const postRouterCopyright: ThunkActionCreator = () => {
  return async (dispatch) => {
    dispatch({ type: ROUTE_COPYRIGHT });
  };
};

export const copyrightVideo = handleActions<State, Payload>(
  {
    [FETCH_COPYRIGHT_VIDEO_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
        draft.isUpdating = false;
      }),
    [FETCH_COPYRIGHT_VIDEO_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_COPYRIGHT_VIDEO_UPDATE_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isUpdating = true;
      }),
    [FETCH_COPYRIGHT_VIDEO_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const videoList = get(payload, 'videoList');
        draft.videoList = videoList;

        if (VIDEO_LIST_LIMIT > videoList.length) {
          draft.paging = false;
        }

        draft.offset = videoList.length;
        draft.isLoading = false;
      }),
    [FETCH_COPYRIGHT_VIDEO_UPDATE]: (state, { payload }) =>
      produce(state, (draft) => {
        const data = get(payload, 'videoList', []);

        if (VIDEO_LIST_LIMIT > data.length) {
          draft.paging = false;
        }

        draft.videoList.push(...data);
        draft.offset = draft.videoList.length;
        draft.isUpdating = false;
      }),
    [POST_COPYRIGHT_VIDEO]: (state, { payload }) =>
      produce(state, (draft) => {
        const currentVideoItem = get(payload, 'currentVideoItem');
        draft.currentVideoItem = currentVideoItem;
      }),
    [ROUTE_COPYRIGHT]: (state) =>
      produce(state, (draft) => {
        draft.isRedirect = false;
      }),
  },
  initialState,
);

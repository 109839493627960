import { produce } from 'immer';
import get from 'lodash/get';
import { getMyApps } from '../../../fetchr/services/MyApps/Top/TopShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';
import { AmebaConnectApiApplicationsPayload } from '../../../services/amebaConnect/response/AmebaConnectApiService';
import { ApplicationServicePayload } from '../../../services/appmng/response/AppmngApiService';

interface State {
  connectApplications: AmebaConnectApiApplicationsPayload[];
  isLoading: boolean;
}

interface Payload {
  applications: ApplicationServicePayload[];
  connectApplications: AmebaConnectApiApplicationsPayload[];
}

const initialState = {
  connectApplications: [],
  isLoading: true,
};

export const FETCH_MYAPPS_REQUEST = 'contexts/MyApps/FETCH_MYAPPS_REQUEST';
export const FETCH_MYAPPS_SUCCESS = 'contexts/MyApps/FETCH_MYAPPS_SUCCESS';
export const FETCH_MYAPPS_FAILURE = 'contexts/MyApps/FETCH_MYAPPS_FAILURE';

export const fetchMyApps: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MYAPPS_REQUEST });
    try {
      const { data } = await getMyApps(req);
      dispatch({ type: FETCH_MYAPPS_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_MYAPPS_FAILURE });
    }
  };
};

export const myApps = handleActions<State, Payload>(
  {
    [FETCH_MYAPPS_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_MYAPPS_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const connectApplications = get(payload, 'connectApplications');
        const applications = get(payload, 'applications');

        // 認可アプリ（connectApplications）からクローズ済アプリを除外するためにアプリ一覧（applications）と比較
        draft.connectApplications = connectApplications.filter((app) => {
          return applications.find(
            (connectApp) => connectApp.client_id === app.client_id,
          );
        });
        draft.isLoading = false;
      }),
    [FETCH_MYAPPS_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
  },
  initialState,
);

import React from 'react';
import { Redirect, RedirectProps } from 'react-router';

/**
 * ReactRouter外のURLにリダイレクトさせるRedirectのWrapperClass
 */
export default class RedirectContainer extends React.Component<RedirectProps> {
  render() {
    if (
      typeof this.props.to === 'string' &&
      /^https?:/.test(this.props.to) &&
      typeof window !== 'undefined'
    ) {
      window.location.replace(this.props.to);
      return null;
    }
    return <Redirect {...this.props} />;
  }
}

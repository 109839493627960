import { Request } from 'express';
import { GenderCode } from '../../../../services/_common/entity/Gender';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  blogFeedConfig,
  blogFeedInitializeConfig,
  BlogFeedInitializeReadPayload,
  BlogFeedReadPayload,
} from './BlogFeedConfig';

/**
 * ジャンルコードを指定してフィードを取得
 * @param {e.Request} req
 * @param {string[]} codes
 * @param {GenderCode} gender
 * @param {number} offset
 * @return {Promise<{data: BlogFeedReadPayload}>}
 */
export function readBlogFeedData(
  req: Request,
  codes: string[],
  gender: GenderCode,
  offset?: number,
) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<BlogFeedReadPayload>(blogFeedConfig.name, {
    codes,
    gender,
    offset,
  });
}

/**
 * フォロー中のジャンルコードをもとにフィードを初期化
 * @param {e.Request} req
 * @param {string[]} codes
 * @return {Promise<{data: BlogFeedInitializeReadPayload}>}
 */
export function readBlogFeedInitializeData(req: Request, codes: string[]) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<BlogFeedInitializeReadPayload>(
    blogFeedInitializeConfig.name,
    {
      codes,
    },
  );
}

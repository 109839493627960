import React from 'react';
import cx from 'classnames';
import Tap, { TapProps } from '../Tap';

const TYPE_PREFIX = 'ButtonType_';
const SIZE_PREFIX = 'ButtonSize_';
const BORDER_PREFIX = 'ButtonBorder_';

export enum ButtonType {
  Primary,
  Secondary,
  Tertiary,
  Notable,
  Warming,
  Negative,
  Disabled,
}

export enum ButtonSize {
  Large,
  Medium,
  Small,
  Short,
  Free,
}

export enum BorderState {
  Included,
  NotIncluded,
}

export interface ButtonProps extends TapProps {
  buttonType?: ButtonType;
  size?: ButtonSize;
  border?: BorderState;
}

/**
 * 共通で使用するボタンです
 * 基本的には独自のstyleを当てたりはせず、このまま使用すること
 */
export const Button: React.FC<ButtonProps> = ({
  className,
  buttonType,
  size,
  border,
  ...props
}) => (
  <Tap
    className={cx(
      'Button',
      className,
      TYPE_PREFIX + ButtonType[buttonType],
      SIZE_PREFIX + ButtonSize[size],
      BORDER_PREFIX + BorderState[border],
    )}
    {...props}
  />
);

Button.defaultProps = {
  buttonType: ButtonType.Secondary,
  size: ButtonSize.Large,
  border: BorderState.Included,
};

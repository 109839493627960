import { ApplicationServicePayload } from '../../../../services/appmng/response/AppmngApiService';
import SharedFetchr from '../../FetchrSharedFactory';
import * as AppService from './DetailConfig';

export function getAppDetail(req?) {
  const fetchr = new SharedFetchr({ req });
  return fetchr.read<ApplicationServicePayload>(AppService.appDetail.name, {
    req,
  });
}

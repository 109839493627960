import React from 'react';
import {
  APP_BANNER_IOS,
  APP_BANNER_ANDROID,
} from '../../../../constants/amebaApplication';
import MineServiceIds from '../../../../constants/serviceIds';
import Icon, { IconTypes } from '../../../atoms/Icon';
import LazyImage from '../../../atoms/LazyImage';
import Tap from '../../../atoms/Tap';
import { FooterSns } from '../FooterSns';

const SECTION_ID = 'pc-footer-ameba';

export const FooterAmeba: React.FC = () => {
  return (
    <div className="FooterAmeba">
      {/* FooterAmeba_AmebaApp */}
      <div className="FooterAmeba_AmebaApp">
        <div className="FooterAmeba_AmebaApp_Content clearfix">
          <div className="FooterAmeba_AmebaApp_Icon">
            <LazyImage
              src="/images/organizers/AppShellDesktop/FooterAmeba/ameba_app.jpg"
              alt="アメーバアプリ"
              width={80}
            />
          </div>
          <div className="FooterAmeba_AmebaApp_Body">
            <h2 className="FooterAmeba_AmebaApp_Body_Title">
              スマホでもAmebaを楽しもう！
            </h2>
            <div className="FooterAmeba_AmebaApp_Links">
              <div className="FooterAmeba_AmebaApp_Search" role="presentation">
                <span className="FooterAmeba_AmebaApp_Search_Text">
                  アメーバ
                </span>
                <Icon
                  iconClass={IconTypes.Search}
                  className="FooterAmeba_AmebaApp_Search_Icon"
                />
              </div>
              <Tap
                href={APP_BANNER_IOS.linkUrl}
                target="_blank"
                className="FooterAmeba_AmebaApp_Badge"
                track={{
                  section_id: SECTION_ID,
                  tap_id: 'app-download',
                  service_id: MineServiceIds.Ameba,
                  content_ids: [APP_BANNER_IOS.code],
                }}
                transparent
              >
                <LazyImage
                  src={APP_BANNER_IOS.downloadImage}
                  alt="AppStoreからダウンロード"
                  width={135}
                  height={40}
                />
              </Tap>
              <Tap
                href={APP_BANNER_ANDROID.linkUrl}
                target="_blank"
                className="FooterAmeba_AmebaApp_Badge"
                track={{
                  section_id: SECTION_ID,
                  tap_id: 'app-download',
                  service_id: MineServiceIds.Ameba,
                  content_ids: [APP_BANNER_ANDROID.code],
                }}
                transparent
              >
                <LazyImage
                  src={APP_BANNER_ANDROID.downloadImage}
                  alt="Google playで手に入れよう"
                  width={135}
                  height={40}
                />
              </Tap>
            </div>
          </div>
          <div className="FooterAmeba_AmebaApp_QrCode">
            <LazyImage
              src="/images/organizers/AppShellDesktop/FooterAmeba/qr.gif"
              alt="qrコードで読み取る"
              width={80}
            />
          </div>
        </div>
        <p className="FooterAmeba_AmebaApp_Text">
          ・Apple、Apple ロゴ、iPad、iPhone、iPod touch
          は米国および他の国々で登録された Apple Inc. の商標です。
        </p>
        <p className="FooterAmeba_AmebaApp_Text">
          ・App Store は Apple Inc. のサービスマークです。{' '}
        </p>
        <p className="FooterAmeba_AmebaApp_Text">
          ・Google Play は Google Inc. の商標または登録商標です。
        </p>
      </div>
      <FooterSns />
    </div>
  );
};

import React from 'react';
import { MenuCategory } from './constants/MENU';
import PcHeaderMenuApp from './PcHeaderMenuApp';

const MAX_COUNT = 7;
function getColumns(apps) {
  const length = apps.length;
  const columns = [];
  for (let i = 0; i < Math.ceil(length / MAX_COUNT); i++) {
    const j = i * MAX_COUNT;
    const column = apps.slice(j, j + MAX_COUNT);
    columns.push(column);
  }
  return columns;
}

interface Props {
  category: MenuCategory;
}

const PcHeaderMenuCategory: React.FC<Props> = ({ category }) => {
  const columns = getColumns(category.apps);
  return (
    <div className="PcHeader_Menu_CategoryItem clearfix">
      {category.title && (
        <h3 className="PcHeader_Menu_CategoryItem_Title">{category.title}</h3>
      )}
      {columns.map((apps, i) => (
        <ul
          className="PcHeader_Menu_Apps"
          key={`${category.title || 'category'}-${i}`}
        >
          {apps.map((app) => (
            <PcHeaderMenuApp key={app.title} app={app} />
          ))}
        </ul>
      ))}
    </div>
  );
};

export default PcHeaderMenuCategory;

export class InstantStorage {
  length = 0;
  state: any = {};
  getItem(key: string) {
    return this.state[key];
  }
  setItem(key: string, value: string) {
    this.state[key] = value;
    this.length++;
  }
  removeItem(key: string) {
    if (key in this.state) {
      delete this.state[key];
      this.length--;
    }
  }
  key(index: number) {
    return Object.keys(this.state)[index];
  }
}

export const localStorage = (() => {
  try {
    window.localStorage.setItem('__test__', 'hoge');
    window.localStorage.removeItem('__test__');
  } catch {
    return new InstantStorage();
  }
  return window.localStorage;
})();

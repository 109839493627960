import { getAsyncComponent } from '../../../lib/ComponentUtils';
import { fetchNotifications } from '../../../contexts/Notifications';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';

export default composeRoute({
  async routerLoader() {
    console.log('Notification Desktop routerLoader');
  },
  async clientLoader({ dispatch }) {
    await dispatch(fetchNotifications());
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./NotificationsDesktop'))),
);

import React from 'react';

interface Props {
  searchValue?: string;
}

interface State {
  searchValue: string;
  edited: boolean;
  isFirstInput?: boolean; // state for mobile code
}

/**
 * 検索ボックスを実装するためのベースクラス
 * extends必須
 */
export class SearchBox<P> extends React.Component<P & Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      edited: false,
      searchValue: props.searchValue || '',
      isFirstInput: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (
      !this.state.edited &&
      prevProps.searchValue !== this.props.searchValue
    ) {
      this.setState({
        searchValue: this.props.searchValue,
      });
    }
  }
  handleChange(e) {
    this.setState({
      edited: true,
      searchValue: e.target.value,
    });
  }
  handleSubmit(event) {
    // 除外文字
    if (/[\u0600-\u06FF]/.test(this.state.searchValue)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
  render() {
    return null;
  }
}

import { produce } from 'immer';
import get from 'lodash/get';
import some from 'lodash/some';
import { getApps } from '../../../fetchr/services/Apps/Top/TopShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';
import {
  ApplicationServicePayload,
  ApplicationsMenuItemPayload,
  GroupServicePayload,
} from '../../../services/appmng/response/AppmngApiService';
import { AmebaConnectApiApplicationsPayload } from '../../../services/amebaConnect/response/AmebaConnectApiService';
import { ROUTE_NAMES } from '../../../constants/routes';

interface State {
  applications: ApplicationServicePayload[];
  connectApplications: AmebaConnectApiApplicationsPayload[];
  groups: GroupServicePayload[];
  menuItems: ApplicationsMenuItemPayload[];
  isLoading: boolean;
}

interface Payload {
  applications: ApplicationServicePayload[];
  connectApplications: AmebaConnectApiApplicationsPayload[];
  groups: GroupServicePayload[];
}

const initialState = {
  applications: [],
  connectApplications: [],
  groups: [],
  menuItems: [],
  isLoading: true,
};

// TODO: 更新のACTIONを作成
export const FETCH_APPS_REQUEST = 'contexts/Apps/FETCH_APPS_REQUEST';
export const FETCH_APPS_SUCCESS = 'contexts/Apps/FETCH_APPS_SUCCESS';
export const FETCH_APPS_FAILURE = 'contexts/Apps/FETCH_APPS_FAILURE';

export const fetchApps: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_APPS_REQUEST });
    try {
      const { data } = await getApps(req);
      dispatch({ type: FETCH_APPS_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_APPS_FAILURE });
    }
  };
};

export const apps = handleActions<State, Payload>(
  {
    [FETCH_APPS_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_APPS_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const applications = get(payload, 'applications');
        const connectApplications = get(payload, 'connectApplications');
        const groups = get(payload, 'groups');
        const id = get(payload, 'id');
        const menuItems = [];

        applications.forEach((item: ApplicationServicePayload, index) => {
          applications[index].installed = some(connectApplications, [
            'client_id',
            item.client_id,
          ]);
        });

        groups.forEach((item: GroupServicePayload) => {
          const { code, name } = item;
          const menuItem = {
            id: code,
            title: name,
            to: `${ROUTE_NAMES.apps}/${code}`,
            selected: id === code,
          } as ApplicationsMenuItemPayload;
          menuItems.push(menuItem);
        });

        // 最後にAppsトップ画面用にすべてのカテゴリーをつめる
        menuItems.unshift({
          id: 'all',
          title: 'すべてのカテゴリー',
          to: ROUTE_NAMES.apps,
          selected: id ? false : true,
        });

        draft.applications = applications;
        draft.menuItems = menuItems;
        draft.isLoading = false;
      }),
    [FETCH_APPS_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
  },
  initialState,
);

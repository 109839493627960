import './polyfill';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { CSSProvider, getCSSMap } from 'react-css-context';
import { Provider } from 'react-redux';
import { ConnectedRouter, replace } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory as createHistory } from 'history';
import { hydrateRoot } from 'react-dom/client';
import { ensureReady } from './lib/ComponentUtils';
import createStore from './stores/Desktop';
import desktopRoutes from './routes/Desktop';
import { manageScroll } from './lib/ScrollUtils';
import * as FrmId from './webApi/FrmId';
import { runFacebook, runTwitter } from './webApi/SocialPlugin';

async function bootstrap() {
  const history = createHistory();

  const initialState = window['__STATE__'];
  if (initialState.router) {
    delete initialState.router;
  }

  const store = createStore(history, initialState);
  const routes = desktopRoutes;

  store.dispatch(replace(location));

  delete window['__STATE__'];

  // frm_idをストレージに保存
  FrmId.save();

  await ensureReady(routes, location.pathname);
  const cssMap = getCSSMap();
  hydrateRoot(
    document.getElementById('app'),
    <HelmetProvider>
      <Provider store={store}>
        {/* FIXME: 依存を外し, CSS importで解決する */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <CSSProvider cssMap={cssMap}>
          <ConnectedRouter history={history}>
            {renderRoutes(routes)}
          </ConnectedRouter>
        </CSSProvider>
      </Provider>
    </HelmetProvider>,
  );
  manageScroll(history);
  runFacebook();
  runTwitter();
}

bootstrap();

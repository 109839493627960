import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { LocationDescriptorObject, Path } from 'history';
import { createLink, trackClick, trackTap } from '../../../webApi/Tracker';
import * as AdcrossApi from '../../../webApi/Adcross';
import {
  ClickContents,
  TapContents,
} from '../../../services/mine/response/Mine';

// タップ時、テキストにアンダーラインが付くようになるクラス(transparentとセットで使うこと)
export const UNDERLINE_TEXT_CLS_NAME = 'TapText';

export interface TapProps {
  to?: Path | LocationDescriptorObject;
  replace?: boolean;
  href?: string;

  onClick?(e): void;

  className?: string;
  id?: string;
  track?: TapContents;
  clickTrack?: ClickContents;
  target?: string;
  rel?: string;
  useFid?: boolean;
  disabled?: boolean;
  transparent?: boolean;
  visitable?: boolean;
  adcross?: AdcrossApi.AdcrossMeasureSchema;
  role?: string;
  type?: string;
  'aria-labelledby'?: string;
  'aria-hidden'?: boolean;
  children?: React.ReactNode;
}

/**
 * 共通で使用するTapの機能
 */
export default class Tap extends React.Component<TapProps> {
  static UNDERLINE_TEXT_CLS_NAME: string = UNDERLINE_TEXT_CLS_NAME;

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e): boolean {
    if (this.props.disabled) {
      e.preventDefault();
      return false;
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    if (this.props.clickTrack) {
      trackClick(this.props.clickTrack);
    }
    if (this.props.track) {
      trackTap(this.props.track);
    }
    if (this.props.adcross) {
      AdcrossApi.trackClick(
        this.props.adcross.hash,
        this.props.adcross.type,
        this.props.adcross.genuuid,
      );
    }
    return true;
  }

  render() {
    const {
      adcross, // inheritPropsから削除
      // react-router-dom#Link
      to,
      replace,
      // system defined
      href,
      disabled,
      children,
      className,
      useFid,
      track,
      target,
      rel,
      transparent,
      visitable,
      ...inheritProps
    } = this.props;
    const element = to ? Link : href ? 'a' : 'button';
    const cls = cx('TapContent', className, {
      Tap_Transparent: transparent,
      Tap_Visitable: visitable,
    });
    const props: any = { className: cls };

    if (to) {
      props.to = to;
      props.replace = replace;
    }

    if (href) {
      props.href = useFid ? createLink(href, track) : href;
      props.target = target;
      props.rel = rel;
      props['aria-disabled'] = disabled;
    } else {
      props.disabled = disabled;
    }

    // const dataTrackAttribute = {};
    // if (track) {
    //   for (const key in track) {
    //     if (track[key]) {
    //       dataTrackAttribute[`data-track-${key.replace('_', '-')}`] = track[key];
    //     }
    //   }
    // }

    return React.createElement(
      element,
      {
        ...props,
        ...inheritProps,
        // ...dataTrackAttribute,
        onClick: this.handleClick,
      },
      children,
    );
  }
}

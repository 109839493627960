import { snackBar } from '../../contexts/AppShellDesktop/SnackBar';
import { loginDialog } from '../../contexts/AppShellDesktop/LoginDialog';
import { abemaCopyrights } from '../../contexts/Common/AbemaCopyrights';
import { homeBlogFeed } from '../../contexts/HomeDesktop/HomeBlogFeed';
import { homeChecklist } from '../../contexts/HomeDesktop/HomeChecklist';
import { homeDesktop } from '../../contexts/HomeDesktop/HomeDesktop';
import { homeGenre } from '../../contexts/HomeDesktop/HomeGenre';
import { settingsConnect } from '../../contexts/Settings/Connect';
import { settingsMangafeed } from '../../contexts/Settings/SettingsMangafeed';
import { settingsNotifications } from '../../contexts/Settings/SettingsNotifications';
import { top } from '../../contexts/TopDesktop/Top';
import configureStore from '../configureStore';
import { notifications } from '../../contexts/Notifications';
import { coinSummary } from '../../contexts/Coin/Summary';
import { tradelaw } from '../../contexts/Tradelaw';
import { copyrightVideo } from '../../contexts/Copyright/Video';
import { copyrightSearch } from '../../contexts/Copyright/Search';
import { copyrightConfirm } from '../../contexts/Copyright/Confirm';
import { gameTop } from '../../contexts/Game/Top';
import { apps } from '../../contexts/Apps/Top';
import { appDetail } from '../../contexts/App/Detail';
import { myApps } from '../../contexts/MyApps/Top';

export default function createStore(history, initialState?) {
  return configureStore(history, initialState, {
    // ページ共通
    loginDialog,
    snackBar,
    abemaCopyrights,
    // ページ固有
    // TOP
    top,
    // HOME
    homeBlogFeed,
    homeChecklist,
    homeDesktop,
    homeGenre,
    // SETTINGS
    settingsConnect,
    settingsMangafeed,
    settingsNotifications,
    // NOTIFICATIONS
    notifications,
    // COIN
    coinSummary,
    // TRADELOW
    tradelaw,
    // COPYRIGHT
    copyrightVideo,
    copyrightSearch,
    copyrightConfirm,
    // Game
    gameTop,
    // Apps
    apps,
    // App
    appDetail,
    // MyApps
    myApps,
  });
}

import React from 'react';
import Icon, { IconTypes } from '../../../atoms/Icon';
import Tap from '../../../atoms/Tap';
import { SECTION_ID, MenuItem } from './constants/MENU';
import PcHeaderMenuCategory from './PcHeaderMenuCategory';

interface Props {
  className?: string;
  open: boolean;
  handleClick(): void;
}

const PcHeaderToggleMenu: React.FC<MenuItem & Props> = ({
  className,
  open,
  handleClick,
  title,
  tapId,
  serviceId,
  categories,
}) => {
  return (
    <li className={`PcHeader_Nav_List ${className}`}>
      <div className="PcHeader_Nav_List_Wrap">
        <Tap
          transparent
          className="-button"
          onClick={handleClick}
          track={{
            section_id: SECTION_ID,
            tap_id: tapId,
            service_id: serviceId,
          }}
        >
          <span className="-align">{title}</span>
          <Icon
            className="-arrow"
            iconClass={
              open ? IconTypes.ArrowPagingUp : IconTypes.ArrowPagingDown
            }
          />
        </Tap>
        <div className="PcHeader_Menu_Categories" aria-hidden={!open}>
          <div className="PcHeader_Menu_Categories_Inner clearfix">
            {categories.map((item, idx) => {
              return (
                <div key={`category-${idx}`} className="PcHeader_Menu_Category">
                  {item.map((category, index) => {
                    return (
                      <PcHeaderMenuCategory
                        key={`${SECTION_ID}-category-${index}`}
                        category={category}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
};

export default PcHeaderToggleMenu;

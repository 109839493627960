import get from 'lodash/get';

/**
 * 3次元配列を2次元配列に
 * @param {any[][]} source
 * @returns {any[]}
 */
export function flatten(source: any[][]) {
  return Array.prototype.concat(...source);
}

/**
 * シャッフル
 * @param {any[]} source
 * @returns {any[]}
 */
export function shuffle(source: any[]) {
  const clone = source.slice();
  for (let i = clone.length - 1; i > 0; i--) {
    const r = Math.floor(Math.random() * (i + 1));
    const tmp = clone[i];
    clone[i] = clone[r];
    clone[r] = tmp;
  }
  return clone;
}

/**
 * Arrayの重複を防ぐ
 */
export function unique(source: any[]) {
  return source.filter((item, index, self) => self.indexOf(item) === index);
}

/**
 * 特定のプロパティがユニークな配列を取得する
 */
export function uniqueByKey<T>(source: T[], key: string): T[] {
  const values = source.map((n) => n[key]);
  return source.filter((item, index) => {
    return values.indexOf(item[key]) === index;
  });
}

/**
 * ランダムに1件
 * @param {any[]} source
 * @return {any}
 */
export function pickRandom(source: any[]) {
  if (source && source.length) {
    const randomIndex = Math.floor(Math.random() * source.length);
    return source[randomIndex];
  }
  return;
}

/**
 * 対象の配列を除外
 * @param {T[]} source
 * @param {T[]} excludes
 * @return {T[]}
 */
export function remove<T>(source: T[], excludes: T[]) {
  return source.filter((item) => {
    return excludes.indexOf(item) === -1;
  });
}

/**
 * 数字のソートに使う比較関数(昇順)
 * 例) [1876,862,65,876,12345] -> [65, 862, 876, 1876, 12345]
 * @param {number[]} targetArray
 */
export function sortAscendingNumber(targetArray: number[]) {
  targetArray.sort((val1, val2) => {
    // sort対象の値がnullだった時一番後ろに来るようにsort
    if (val1 === null) {
      return 1;
    }
    if (val2 === null) {
      return -1;
    }
    return val1 - val2;
  });
}

/**
 * オブジェクト内のプロパティの数値を対象にソートする比較関数(昇順)
 * 例）[{sortNum: 1},{sortNum: 12},{sortNum: 2}] -> [{sortNum: 1},{sortNum: 2},{sortNum: 12}]
 * @param {any[]} targetArray
 * @param {string} targetProperty
 */
export function sortAscendingByKey(targetArray: any[], targetProperty: string) {
  targetArray.sort((val1, val2) => {
    // sort対象の値がnullだった時一番後ろ来るようにsort
    if (get(val1, targetProperty) === null) {
      return 1;
    }
    if (get(val2, targetProperty) === null) {
      return -1;
    }

    return get(val1, targetProperty) - get(val2, targetProperty);
  });
}

/**
 * 数字のソートに使う比較関数(降順)
 * 例) [1876,862,65,876,12345] -> [12345, 1876, 876, 862, 65]
 * @param {number[]} targetArray
 */
export function sortDescendingNumber(targetArray: number[]) {
  targetArray.sort((val1, val2) => {
    // sort対象の値がnullだった時一番後ろに来るようにsort
    if (val1 === null) {
      return 1;
    }
    if (val2 === null) {
      return -1;
    }
    return val2 - val1;
  });
}

/**
 * オブジェクト内のプロパティの数値を対象にソートする比較関数(降順)
 * 例）[{sortNum: 1},{sortNum: 12},{sortNum: 2}] -> [{sortNum: 12},{sortNum: 2},{sortNum: 1}]
 * @param {any[]} targetArray
 * @param {string} targetProperty
 */
export function sortDescendingByKey(
  targetArray: any[],
  targetProperty: string,
) {
  targetArray.sort((val1, val2) => {
    // sort対象の値がnullだった時一番後ろに来るようにsort
    if (get(val1, targetProperty) === null) {
      return 1;
    }
    if (get(val2, targetProperty) === null) {
      return -1;
    }

    return get(val2, targetProperty) - get(val1, targetProperty);
  });
}

/**
 * limitに指定された件数ごとに配列を分割
 * @param {T[]} source
 * @param {number} limit
 * @returns {T[]}
 */
export function splitArray<T>(source: T[], limit: number) {
  return source.reduce(
    (accumulator, currentValue) => {
      const lastList = accumulator[accumulator.length - 1];
      if (lastList.length === limit) {
        accumulator.push([currentValue]);
        return accumulator;
      }
      lastList.push(currentValue);
      return accumulator;
    },
    [[]],
  );
}

import { Request } from 'express';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  notificationsConfig,
  notificationsHomeConfig,
  notificationsUpdateConfig,
  notificationUpdateConfig,
  NotifiationsReadPayload,
  NotifiationsReadHomePayload,
  NotifiationsReadUpdatePayload,
  NotifiationReadUpdatePayload,
} from './NotificationsConfig';

export function readNotifications(
  req?: Request,
  limit = 100,
  summaryOnly = false,
) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<NotifiationsReadPayload>(notificationsConfig.name, {
    limit,
    summaryOnly,
  });
}

export function readHomeNotifications(req?: Request, limit = 100) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<NotifiationsReadHomePayload>(
    notificationsHomeConfig.name,
    {
      limit,
    },
  );
}

export function readNotificationUpdate(req?: Request, notificationId = '') {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<NotifiationReadUpdatePayload>(
    notificationUpdateConfig.name,
    {
      notificationId,
    },
  );
}

export function readNotificationsUpdate(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<NotifiationsReadUpdatePayload>(
    notificationsUpdateConfig.name,
  );
}

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

/**
 * ActionCreator用のインターフェース
 */
export interface FluxStandardAction<T> extends Action {
  payload: T | Promise<T>;
  error?: boolean;
  meta?: any;
}

/**
 * Reducer時に返却されるActionのインターフェース
 * このときPromiseは返却されない
 */
export interface PureFluxStandardAction<T> extends Action {
  payload: T;
  error?: boolean;
  meta?: any;
}

/**
 * ReduxThunk用のInterface
 */
type AsyncableVoid = void | Promise<void>;
export type InternalThunk = ThunkAction<
  AsyncableVoid,
  any,
  Record<string, unknown>,
  any
>;
export type ThunkActionCreator = (...args: any[]) => InternalThunk;

/**
 * WIP: Interfaces for ReduxThunk
 */
// export type PureThunkAction<Payload, State = any, Extra = {}> = ThunkAction<
//   void,
//   State,
//   Extra,
//   PureFluxStandardAction<Payload>
// >;
export type AsyncThunkAction<
  Payload,
  State = any,
  Extra = Record<string, unknown>,
> = ThunkAction<Promise<void>, State, Extra, PureFluxStandardAction<Payload>>;

/**
 * Reducerに登録するActionMapのインターフェース
 */
export type ActionHandler<State, Payload> = (
  state: State,
  action: PureFluxStandardAction<Payload>,
) => State;
export interface ActionMap<State, Payload> {
  [x: string]: ActionHandler<State, Payload>;
}

/*
 * 非同期処理の状態を管理するStateのインタフェース
 */
export type AsyncStatus = 'none' | 'loading' | 'success' | 'failure';
export const ASYNC_STATUS: { [x in AsyncStatus]: x } = {
  none: 'none',
  loading: 'loading',
  success: 'success',
  failure: 'failure',
};
export interface AsyncState {
  status: AsyncStatus;
}

// import React from 'react';
import { RouteConfig } from 'react-router-config';
import Ameba20thInvitationEventsDetail from '../../components/pages/Ameba20thInvitation/Ameba20thInvitationEventsDetail';
import Ameba20thInvitationTicket from '../../components/pages/Ameba20thInvitation/Ameba20thInvitationTicket';
import Ameba20thInvitationTop from '../../components/pages/Ameba20thInvitation/Ameba20thInvitationTop';
import AppDetail from '../../components/pages/App/AppDetail';
import AppsTopDesktop from '../../components/pages/Apps/AppsTopDesktop';
import AppShell from '../../components/pages/AppShellDesktop';
import CampaignDesktop from '../../components/pages/CampaignDesktop';
import CoinSummaryDesktop from '../../components/pages/Coin/CoinSummaryDesktop';
import CopyrightCompleteDesktop from '../../components/pages/Copyright/CopyrightCompleteDesktop';
import CopyrightConfirmDesktop from '../../components/pages/Copyright/CopyrightConfirmDesktop';
import CopyrightSearchDesktop from '../../components/pages/Copyright/CopyrightSearchDesktop';
import CopyrightTopDesktop from '../../components/pages/Copyright/CopyrightTopDesktop';
import CopyrightVideosDesktop from '../../components/pages/Copyright/CopyrightVideosDesktop';
import GameTopDesktop from '../../components/pages/Game/GameTopDesktop';
import HomeDesktop from '../../components/pages/HomeDesktop';
import Logout from '../../components/pages/Logout';
import MyAppsTopDesktop from '../../components/pages/MyApps/MyAppsTopDesktop';
import NonamebaidDesktop from '../../components/pages/NonamebaidDesktop';
import NotFound from '../../components/pages/NotFound';
import NotificationsDesktop from '../../components/pages/NotificationsDesktop';
import SettingsNotificationsDesktop from '../../components/pages/Settings/SettingsNotificationsDesktop';
import SettingsTopDesktop from '../../components/pages/Settings/SettingsTopDesktop';
import SettingsMangafeedDesktop from '../../components/pages/SettingsMangafeedDesktop';
import Top from '../../components/pages/TopDesktop';
import TradelawDesktop from '../../components/pages/TradelawDesktop';
import { PAGE_IDS, ROUTE_NAMES } from '../../constants/routes';

const desktopRoutes: RouteConfig[] = [
  {
    path: ROUTE_NAMES.logout,
    exact: true,
    component: Logout,
  },
  {
    path: ROUTE_NAMES.logoutHome,
    exact: true,
    component: Logout,
  },
  // TODO: 20th終わったら削除
  {
    path: ROUTE_NAMES.ameba20thInvitation,
    exact: true,
    component: Ameba20thInvitationTop,
    page_id: PAGE_IDS.ameba20thInvitation,
  },
  {
    path: ROUTE_NAMES.ameba20thInvitationEventsDetail,
    exact: true,
    component: Ameba20thInvitationEventsDetail,
    page_id: PAGE_IDS.ameba20thInvitationEventsDetail,
  },
  {
    path: ROUTE_NAMES.ameba20thInvitationTicket,
    exact: true,
    component: Ameba20thInvitationTicket,
    page_id: PAGE_IDS.ameba20thInvitationTicket,
  },
  {
    path: ROUTE_NAMES.top,
    component: AppShell,
    routes: [
      {
        path: ROUTE_NAMES.top,
        exact: true,
        component: Top,
        page_id: PAGE_IDS.top,
      },
      // Redirectの実装例
      {
        path: ROUTE_NAMES.home,
        component: HomeDesktop,
        page_id: PAGE_IDS.home,
      },
      {
        path: ROUTE_NAMES.nonamebaid,
        component: NonamebaidDesktop,
        page_id: PAGE_IDS.nonamebaid,
      },
      {
        path: ROUTE_NAMES.campaign,
        exact: true,
        component: CampaignDesktop,
      },
      {
        path: ROUTE_NAMES.settings_mangafeed,
        exact: true,
        component: SettingsMangafeedDesktop,
        page_id: PAGE_IDS.settings_mangafeed,
      },
      {
        path: ROUTE_NAMES.game,
        exact: true,
        component: GameTopDesktop,
        page_id: PAGE_IDS.game,
      },
      {
        path: ROUTE_NAMES.notifications,
        exact: true,
        component: NotificationsDesktop,
        page_id: PAGE_IDS.notifications,
      },
      {
        path: ROUTE_NAMES.settings,
        exact: true,
        component: SettingsTopDesktop,
        page_id: PAGE_IDS.settings,
      },
      {
        path: ROUTE_NAMES.settings_notifications,
        exact: true,
        component: SettingsNotificationsDesktop,
        page_id: PAGE_IDS.settings_notifications,
      },
      {
        path: ROUTE_NAMES.coinSummary,
        exact: true,
        component: CoinSummaryDesktop,
        page_id: PAGE_IDS.coin_summary,
      },
      // {
      //   path: ROUTE_NAMES.setting,
      //   exact: true,
      //   component: spComponentToDesktop(Setting)
      // },
      {
        path: ROUTE_NAMES.tradelaw,
        exact: true,
        component: TradelawDesktop,
        page_id: PAGE_IDS.tradelaw,
      },
      {
        path: ROUTE_NAMES.copyright,
        exact: true,
        component: CopyrightTopDesktop,
        page_id: PAGE_IDS.copyright,
      },
      {
        path: ROUTE_NAMES.copyrightVideos,
        exact: true,
        component: CopyrightVideosDesktop,
        page_id: PAGE_IDS.copyright_videos,
      },
      {
        path: ROUTE_NAMES.copyrightSearch,
        exact: true,
        component: CopyrightSearchDesktop,
        page_id: PAGE_IDS.copyright_search,
      },
      {
        path: ROUTE_NAMES.copyrightConfirm,
        exact: true,
        component: CopyrightConfirmDesktop,
        page_id: PAGE_IDS.copyright_confirm,
      },
      {
        path: ROUTE_NAMES.copyrightComplete,
        exact: true,
        component: CopyrightCompleteDesktop,
        page_id: PAGE_IDS.copyright_complete,
      },
      {
        path: ROUTE_NAMES.apps,
        exact: true,
        component: AppsTopDesktop,
        page_id: PAGE_IDS.apps,
      },
      {
        path: ROUTE_NAMES.apps_category,
        exact: true,
        component: AppsTopDesktop,
        page_id: PAGE_IDS.apps_category,
      },
      {
        path: ROUTE_NAMES.appDetail,
        exact: true,
        component: AppDetail,
        page_id: PAGE_IDS.app_detail,
      },
      {
        path: ROUTE_NAMES.myapps,
        exact: true,
        component: MyAppsTopDesktop,
        page_id: PAGE_IDS.myapps,
      },
      {
        component: NotFound,
      },
    ],
  },
];

export default desktopRoutes;

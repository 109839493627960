import get from 'lodash/get';
import { produce } from 'immer';
import { getAppDetail } from '../../../fetchr/services/App/Detail/DetailShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';
import { ApplicationServicePayload } from '../../../services/appmng/response/AppmngApiService';

interface State {
  isLoading: boolean;
  application: ApplicationServicePayload;
}

interface Payload {
  application: ApplicationServicePayload;
}

const initialState = {
  isLoading: true,
  application: {},
};

export const FETCH_APP_DETAIL_REQUEST = 'contexts/App/FETCH_APP_DETAIL_REQUEST';
export const FETCH_APP_DETAIL_SUCCESS = 'contexts/App/FETCH_APP_DETAIL_SUCCESS';
export const FETCH_APP_DETAIL_FAILURE = 'contexts/App/FETCH_APP_DETAIL_FAILURE';

export const fetchAppDetail: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_APP_DETAIL_REQUEST });
    try {
      const { data } = await getAppDetail(req);
      dispatch({ type: FETCH_APP_DETAIL_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_APP_DETAIL_FAILURE });
    }
  };
};

export const appDetail = handleActions<State, Payload>(
  {
    [FETCH_APP_DETAIL_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = true;
      }),
    [FETCH_APP_DETAIL_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.application = get(payload, ['application']);
        draft.isLoading = true;
      }),
    [FETCH_APP_DETAIL_FAILURE]: (state) =>
      produce(state, (draft) => {
        draft.isLoading = false;
      }),
  },
  initialState,
);

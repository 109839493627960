import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { logout as actionLogout } from '../../../contexts/Common/LoginInfo';
import RedirectContainer from '../../../lib/ReactRouterUtils/RedirectContainer';
import { LoadableFrame } from '../../atoms/LoadableFrame';
const { useEffect } = React;

interface Props {
  logout(): void;
}

const Logout: React.FC<Props> = ({ logout }) => {
  const location = useLocation();
  useEffect(() => {
    logout();
  }, []);
  return (
    <>
      <LoadableFrame moduleType="page" />
      <RedirectContainer
        to={`${process.env.DOMAIN_WWW_URL}${location.pathname}`}
      />
    </>
  );
};

export default connect(null, {
  logout: actionLogout,
})(Logout);
